import Box from "@material-ui/core/Box";
import { useEffect, useState, Fragment } from "react";
import {
  getCourseDescription,
  updateCourseDescription,
} from "../../../../models/course";
import Editor from "./Editor";
import Viewer from "./Viewer";

type CourseDesc = import("../../../../models/course").Description;
const initState: CourseDesc = {
  lead: "",
  subtitle: "",
  body: "",
};

export default function Description({
  courseCode,
  courseVer,
}: {
  courseCode: string;
  courseVer: number;
}) {
  const [desc, setDesc] = useState<CourseDesc>({ ...initState });
  useEffect(() => {
    setDesc({ ...initState });
    getCourseDescription(courseCode, courseVer).then(setDesc, console.error);
  }, [courseCode, courseVer]);

  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = (desc: CourseDesc) => {
    updateCourseDescription(courseCode, courseVer, desc).then(() => {
      toggleEdit();
      setDesc(desc);
    }, console.error);
  };

  return (
    <Fragment>
      <Box display={edit ? "block" : "none"}>
        <Editor desc={desc} save={handleSave} onClickCancel={toggleEdit} />
      </Box>
      <Box display={edit ? "none" : "block"}>
        <Viewer desc={desc} onClick={toggleEdit} />
      </Box>
    </Fragment>
  );
}
