import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import firebase from "firebase/app";
import "firebase/auth";
import { auth } from "firebaseui";
import { StyledFirebaseAuth } from "react-firebaseui";
import { useLocation } from "react-router-dom";
import Auth from "../../layouts/Auth";
import gradients from "../../utils/gradients";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.md,
    maxWidth: "100%",
    overflow: "unset",
    display: "flex",
    position: "relative",
    "& > *": {
      flexGrow: 1,
      flexBasis: "50%",
      width: "50%",
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  icon: {
    backgroundImage: gradients.green,
    color: "white",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: "absolute",
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  loginForm: {
    marginTop: theme.spacing(3),
  },
  person: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const location = useLocation();
  const uiConfig: auth.Config = {
    signInFlow: "popup",
    signInSuccessUrl: location.pathname,
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };

  return (
    <Auth>
      <div className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <LockIcon className={classes.icon} />
            <Typography gutterBottom variant="h3">
              Sign in
            </Typography>
            <Typography variant="subtitle2">
              Sign in on the internal platform
            </Typography>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.app().auth()}
            />
          </CardContent>
          <CardMedia
            className={classes.media}
            image="/images/violin.jpg"
            title="Cover"
          />
        </Card>
      </div>
    </Auth>
  );
}
