import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import Curriculum from "./Curriculum";
import Feature from "./Feature";
import General from "./General";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function ProductView() {
  const classes = useStyles();

  const { id } = useParams<{
    id?: string;
  }>();
  const productID = useMemo(() => {
    if (!id) return;
    const productID = parseInt(id, 10);
    if (isNaN(productID)) return;
    return productID;
  }, [id]);

  if (!productID) return null;

  return (
    <Container>
      <Paper>
        <Box p={3} mt={3} className={classes.container}>
          <General productID={productID} />
          <Feature productID={productID} />
          <Curriculum productID={productID} />
        </Box>
      </Paper>
    </Container>
  );
}
