import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";
import {
  numberPattern,
} from "../../../../utils/form";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginTop: theme.spacing(3),
      textAlign: "right",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
    },
    listForm: {
      width: "100%",
    },
  })
);

export type LessonInput = {
  lessons: string[];
};

type Props = {
  defaultValues: LessonInput;
  onSave: (data: LessonInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function Form({
  defaultValues,
  onSave,
  onCancel,
  isSaving,
  saveError,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<LessonInput>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {saveError && <Typography color="error">{saveError}</Typography>}
      <div className={classes.container}>
        <Controller
          name="lessons"
          control={control}
          rules={{
            validate: data => {
              const regex = new RegExp(numberPattern.value);
              return data.every(x => regex.test(x)) ? undefined : numberPattern.message;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              multiple
              options={[]}
              freeSolo
              {...field}
              renderInput={params => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error && (error as any).message} // 入力値のエラーが配列で返ってこないためanyで型を無視する
                  variant="standard"
                  label="レッスンID"
                  placeholder="レッスンID"
                />
              )}
              onChange={(_, data) => field.onChange(data)}
            />
          )}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
