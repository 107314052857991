import { client } from "../utils/axios";
import { isLessonSummaryArray } from "./lesson";
import { isCloudinaryImage } from "./media";
import { isTeacherArray } from "./teacher";

export type CourseBase = {
  courseID: number;
  courseCode: string;
  categoryCode: string;
};

function isCourseBase(x: any): x is CourseBase {
  return (
    x &&
    typeof x.courseID === "number" &&
    typeof x.courseCode === "string" &&
    typeof x.categoryCode === "string"
  );
}

export type Course = {
  courseID: number;
  courseCode: string;
  courseVer: number;
  courseName: string;
};

function isCourse(x: any): x is Course {
  return (
    x &&
    typeof x.courseID === "number" &&
    typeof x.courseCode === "string" &&
    typeof x.courseVer === "number" &&
    typeof x.courseName === "string"
  );
}

function isCourseArray(x: any): x is Course[] {
  return Array.isArray(x) && x.every(isCourse);
}

export type AdminCourse = Course & {
  categoryCode: string;
  published: boolean;
};

function isAdminCourse(x: any): x is AdminCourse {
  return (
    x &&
    typeof x.categoryCode === "string" &&
    typeof x.published === "boolean" &&
    isCourse(x)
  );
}

export type Description = {
  lead: string;
  subtitle: string;
  body: string;
};

function isDescription(x: any): x is Description {
  return (
    x &&
    typeof x.lead === "string" &&
    typeof x.subtitle === "string" &&
    typeof x.body === "string"
  );
}

export const LEAD_MAX_LEN = 100;
export const SUBTITLE_MAX_LEN = 30;
export const BODY_MAX_LEN = 400;

export function checkDescription(
  x: Description
): { [K in keyof Description]?: string } {
  const ret: { [K in keyof Description]?: string } = {};
  const msg = "必須項目です";
  if (x.lead === "") {
    ret.lead = msg;
  }
  if (Array.from(x.lead).length > LEAD_MAX_LEN) {
    ret.lead = `上限は${LEAD_MAX_LEN}文字です`;
  }
  if (x.subtitle === "") {
    ret.subtitle = msg;
  }
  if (Array.from(x.subtitle).length > SUBTITLE_MAX_LEN) {
    ret.subtitle = `上限は${SUBTITLE_MAX_LEN}文字です`;
  }
  if (x.body === "") {
    ret.body = msg;
  }
  if (Array.from(x.body).length > BODY_MAX_LEN) {
    ret.body = `上限は${BODY_MAX_LEN}文字です`;
  }
  return ret;
}

const NAME_MAX_LEN = 20;

export function validateName(courseName: string): string {
  if (courseName === "") {
    return "必須項目です";
  }
  if (Array.from(courseName).length > NAME_MAX_LEN) {
    return `上限は${NAME_MAX_LEN}文字です`;
  }
  return "";
}

const CODE_MAX_LEN = 30;
const CODE_REGEXP = /^[a-z][a-z0-9-]+$/;

export function validateCode(code: string) {
  if (code === "") {
    return "必須項目です";
  }
  if (Array.from(code).length > CODE_MAX_LEN) {
    return `上限は${CODE_MAX_LEN}文字です`;
  }
  if (!CODE_REGEXP.test(code)) {
    return "英小文字, 数字, ハイフンのみ使えます";
  }
  return "";
}

//
// course key
//
export function getCourseKey(x: Course) {
  return `${x.courseCode}/${x.courseVer}`;
}

export function parseCourseKey(key: string) {
  const arr = key.split("/");
  if (arr.length !== 2) {
    return;
  }
  const code = arr[0];
  const ver = parseInt(arr[1], 10);
  if (!isNaN(ver)) {
    return {
      courseCode: code,
      courseVer: ver,
    };
  }
}

//
// course
//
export async function getCourseVersions(courseCode: string) {
  const c = await client();
  return c.get(`/courses/${courseCode}/versions`).then(({ data }) => {
    const { courses } = data;
    if (courses === null) {
      return [];
    }
    if (Array.isArray(courses) && courses.every(isAdminCourse)) {
      return courses as AdminCourse[];
    }
    throw Error("invalid data");
  });
}

export async function createNewVersion(courseCode: string, courseName: string) {
  const c = await client();
  return c
    .post(`/courses/${courseCode}/versions`, { courseName })
    .then(({ data }) => {
      if (isCourse(data)) {
        return data;
      }
      throw Error("invalida data");
    });
}

export async function getCourse(courseCode: string, courseVer: number) {
  const c = await client();
  const { data } = await c.get(`/courses/${courseCode}/versions/${courseVer}`);
  if (isAdminCourse(data)) {
    return data;
  }
  throw Error("invalid data: " + JSON.stringify(data));
}

export async function getCourses(categoryCode?: string) {
  const c = await client();
  const { data } = await c.get(
    `/courses` + (categoryCode ? `?categoryCode=${categoryCode}` : "")
  );
  const { courses } = data;
  if (isCourseArray(courses)) {
    return courses;
  }
  throw Error("invalid data: " + JSON.stringify(data));
}

export async function updateName(
  courseCode: string,
  courseVer: number,
  courseName: string
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/name`, {
    courseName,
  });
}

export async function publishCourse(courseCode: string, courseVer: number) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/publish`);
}

//
// course lessons
//
export async function getCourseLessons(courseCode: string, courseVer: number) {
  const c = await client();
  const res = await c.get(
    `/courses/${courseCode}/versions/${courseVer}/lessons`
  );
  const { lessons } = res.data;
  if (lessons === null) {
    return [];
  }
  if (isLessonSummaryArray(lessons)) {
    return lessons;
  }
  throw Error("invalid data: " + JSON.stringify(res.data));
}

export async function createCourseLesson(
  courseCode: string,
  courseVer: number,
  lessonName: string
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/lessons`, {
    lessonName,
  });
}

export async function appendLessons(
  courseCode: string,
  courseVer: number,
  lessonIDs: number[]
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/lessons/append`, {
    lessonIDs,
  });
}

export async function deleteLessons(
  courseCode: string,
  courseVer: number,
  lessonIDs: number[]
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/lessons/delete`, {
    lessonIDs,
  });
}

export async function sortLessons(
  courseCode: string,
  courseVer: number,
  lessonIDs: number[]
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/lessons/sort`, {
    lessonIDs,
  });
}

//
// curriculum courses
//
export async function getCurrCourses(currCode: string) {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/courses`);
  const { courses } = res.data;
  if (courses === null) {
    return [];
  }
  if (Array.isArray(courses) && courses.every((x) => typeof x === "string")) {
    return courses as string[];
  }
  throw Error(`invalid data: ${JSON.stringify(res.data)}`);
}

export async function createCurrCourses(currCode: string, courseCode: string) {
  const c = await client();
  await c.post(`/currs/${currCode}/courses`, { courseCode });
}

//
// description
//
export async function getCourseDescription(
  courseCode: string,
  courseVer: number
) {
  const c = await client();
  const { data } = await c.get(
    `/courses/${courseCode}/versions/${courseVer}/descriptions`
  );
  if (isDescription(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function updateCourseDescription(
  courseCode: string,
  courseVer: number,
  description: Description
) {
  const c = await client();
  await c.post(
    `/courses/${courseCode}/versions/${courseVer}/descriptions`,
    description
  );
}

//
// teacher
//
export async function getCourseTeachers(courseCode: string, courseVer: number) {
  const c = await client();
  const { data } = await c.get(
    `/courses/${courseCode}/versions/${courseVer}/teachers`
  );
  const { teachers } = data;
  if (teachers === null) {
    return [];
  }
  if (isTeacherArray(teachers)) {
    return teachers;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function updateCourseTeacher(
  courseCode: string,
  courseVer: number,
  teacherIDs: number[]
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/teachers`, {
    teacherIDs,
  });
}

//
// image
//
export async function getCourseImage(courseCode: string, courseVer: number) {
  const c = await client();
  const { data } = await c.get(
    `/courses/${courseCode}/versions/${courseVer}/images`
  );
  if (isCloudinaryImage(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function updateCourseImage(
  courseCode: string,
  courseVer: number,
  cldnImageID: number
) {
  const c = await client();
  await c.post(`/courses/${courseCode}/versions/${courseVer}/images`, {
    cldnImageID,
  });
}

//
// courseBases
//
export async function getCourseBases() {
  const c = await client();
  return c.get("/courseBases").then(({ data }) => {
    const { courseBases } = data;
    if (Array.isArray(courseBases) && courseBases.every(isCourseBase)) {
      return courseBases as CourseBase[];
    }
    throw Error("failed to get courseBases");
  });
}

//
// trial
//
export async function getTrial(code: string) {
  const c = await client();
  return c.get(`/courses/${code}/trial`).then(({ data }) => {
    const { isTrial } = data;
    if (typeof isTrial === "boolean") {
      return isTrial;
    }
    throw Error("failed to get trial");
  });
}

export async function postTrial(code: string) {
  const c = await client();
  return c.post(`/courses/${code}/trial`);
}
