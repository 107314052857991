import React, { useState, useEffect } from "react";
import { getPublic, postPublic } from "../../../models/play";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    greenButton: {
      color: "white",
      backgroundColor: green[600],
      "&:hover": {
        backgroundColor: green[900],
      },
    },
  })
);

export default function Public({ id }: { id: number }) {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const loadPublic = (id: number) =>
    getPublic(id).then(setIsPublic, console.error);

  useEffect(() => {
    loadPublic(id).finally(() => setLoading(false));
  }, [id]);

  const handlePublic = () => {
    if (!window.confirm("演奏・伴奏を外部公開しますか？")) return;
    postPublic(id, true).then(
      () => {
        setIsPublic(true);
      },
      (e) => console.error
    );
  };

  const handlePrivate = () => {
    if (!window.confirm("演奏・伴奏を外部に非公開にしますか？")) return;
    postPublic(id, false).then(
      () => {
        setIsPublic(false);
      },
      (e) => console.error
    );
  };

  if (loading) return null;

  return (
    <div>
      <Typography variant="subtitle1">外部公開状態</Typography>
      <Box mt={1}>
        <Button
          className={classes.greenButton}
          variant="contained"
          onClick={handlePublic}
          disabled={isPublic}
        >
          公開する
        </Button>
      </Box>
      <Box mt={1}>
        <Button
          className={classes.greenButton}
          variant="contained"
          onClick={handlePrivate}
          disabled={!isPublic}
        >
          非公開にする
        </Button>
      </Box>
    </div>
  );
}
