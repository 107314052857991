import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import useMyRole from "../../../hooks/useMyRole";
import { getCategoryPlans } from "../../../models/category";
import permissions from "../../../models/permission";
import type { Plan } from "../../../models/plan";
import Delete from "./Delete";
import Editor from "./Editor";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  table: {
    minWidth: 300,
  },
}));

export default function PlanList({ categoryCode }: { categoryCode: string }) {
  const classes = useStyles();
  const { role } = useMyRole();

  const [plans, setPlans] = useState<Plan[]>([]);
  useEffect(() => {
    getCategoryPlans(categoryCode).then(setPlans).catch(console.error);
  }, [categoryCode]);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleUpdate = (plans: Plan[]) => {
    setOpen(false);
    if (plans && plans.length > 0) {
      setPlans((p) => [...p, ...plans].sort((a, b) => a.planID - b.planID));
    }
  };

  const handleDelete = (planID: number) => () => {
    setPlans((p) => p.filter((p) => p.planID !== planID));
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="flex-end">
        {permissions.categoriesPlans.has(role) && (
          <Button variant="contained" color="primary" onClick={handleOpen}>
            プランの追加
          </Button>
        )}
      </Box>
      <Editor
        cateogryCode={categoryCode}
        open={open}
        onClose={handleClose}
        onUpdate={handleUpdate}
        defaultPlans={plans}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>プラン名</TableCell>
              <TableCell>料金</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.length > 0 ? (
              plans.map((plan) => (
                <TableRow key={plan.planID} hover>
                  <TableCell>{plan.planID}</TableCell>
                  <TableCell>{plan.planName}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("ja-JP", {
                      style: "currency",
                      currency: "JPY",
                    }).format(plan.planPrice)}
                  </TableCell>
                  <TableCell align="right">
                    {permissions.categoriesPlans.has(role) && (
                      <Delete
                        categoryCode={categoryCode}
                        plan={plan}
                        onDelete={handleDelete(plan.planID)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography component="span" color="textSecondary">
                    プランがありません
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
