import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Header as CurrHeader,
  HEADER_DESCRIPTION_MAX_LEN,
} from "../../../../models/curriculum";
import {
  maxLengthRule,
  numberPattern,
  requiredMessage,
} from "../../../../utils/form";

const admissionTextLength = 3;

const defaultValues = {
  title: "",
  image: {
    cldnPublicID: "",
    cldnVersion: 0,
  },
  description: "",
  admissionTexts: Array.from({ length: admissionTextLength }).map((x) => ""),
  thumbnail: {
    cldnPublicID: "",
    cldnVersion: 0,
  },
  purchaseTopImage: {
    cldnPublicID: "",
    cldnVersion: 0,
  },
};

// react-hook-form は `valueAs` を `useController` でサポートしていない
// 数値のフィールドを文字列から数値に変換する
function format(header: CurrHeader): CurrHeader {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  return {
    ...header,
    image: { ...header.image, cldnVersion: toInt(header.image.cldnVersion) },
    thumbnail: {
      ...header.thumbnail,
      cldnVersion: toInt(header.thumbnail.cldnVersion),
    },
    purchaseTopImage: {
      ...header.purchaseTopImage,
      cldnVersion: toInt(header.purchaseTopImage.cldnVersion),
    },
  };
}

export default function Editor({
  header,
  save,
  onClickCancel,
}: {
  header?: CurrHeader;
  save: (desc: CurrHeader) => void;
  onClickCancel: () => void;
}) {
  const { handleSubmit, control, reset } = useForm<CurrHeader>({
    defaultValues,
  });

  useEffect(() => {
    if (header) {
      reset(header);
    }
  }, [reset, header]);

  const onSubmit = (data: CurrHeader) => {
    save(format(data));
  };

  const handleClickCancel = () => {
    reset();
    onClickCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={2}>
        <Controller
          name="title"
          control={control}
          rules={{ required: requiredMessage }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="タイトル"
              {...field}
            />
          )}
        />
      </Box>

      <Box my={2}>
        <Typography gutterBottom>画像</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              name="image.cldnPublicID"
              control={control}
              rules={{ required: requiredMessage }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Public ID"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="image.cldnVersion"
              control={control}
              rules={{ required: requiredMessage, pattern: numberPattern }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Version"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={2} mt={5}>
        <Controller
          name="description"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(HEADER_DESCRIPTION_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                fullWidth
                multiline
                rows={2}
                error={!!error}
                helperText={error?.message}
                variant="outlined"
                label="概要"
                {...field}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="body2">{`${field.value.length}/${HEADER_DESCRIPTION_MAX_LEN}`}</Typography>
              </Box>
            </>
          )}
        />
      </Box>

      <Box my={2}>
        <Typography gutterBottom>入会情報</Typography>

        {Array.from({ length: admissionTextLength }).map((x, index) => (
          <Controller
            key={index}
            name={`admissionTexts.${index}`}
            control={control}
            rules={{
              required: requiredMessage,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="outlined"
                error={!!error}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        ))}
      </Box>

      <Box my={2}>
        <Typography gutterBottom>サムネイル画像</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              name="thumbnail.cldnPublicID"
              control={control}
              rules={{ required: requiredMessage }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Public ID"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="thumbnail.cldnVersion"
              control={control}
              rules={{ required: requiredMessage, pattern: numberPattern }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Version"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={2}>
        <Typography gutterBottom>購入画面トップ画像</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              name="purchaseTopImage.cldnPublicID"
              control={control}
              rules={{ required: requiredMessage }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Public ID"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="purchaseTopImage.cldnVersion"
              control={control}
              rules={{ required: requiredMessage, pattern: numberPattern }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Version"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="submit"
          >
            保存
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="default"
            variant="contained"
            size="small"
            onClick={handleClickCancel}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
