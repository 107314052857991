import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { ADMIN, AdminUser } from "../../../models/admin";
import { getUser as getAuthUser } from "../../../models/auth";
import RoleView from "../Role";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  users,
}: {
  className?: string;
  users: AdminUser[];
}) {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>UID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell width="25%">ロール</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(({ uid, email, role }) => (
                <TableRow hover={true} key={uid}>
                  <TableCell>
                    <Typography noWrap>{uid}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{email}</Typography>
                  </TableCell>
                  <TableCell>
                    <RoleView
                      uid={uid}
                      defaultRole={role}
                      disabledLabels={
                        getAuthUser().uid === uid && role === ADMIN
                          ? ["member"]
                          : []
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
