import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import * as colors from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { createRef, useEffect, useState } from "react";
import Progress from "../../../../components/Progress";
import { deleteText, getTextURL, updateText } from "../../../../models/lesson";

const aspectRatio = Math.sqrt(2);

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    box: {
      paddingTop: `${aspectRatio * 100}%`,
      position: "relative",
    },
    content: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: colors.blueGrey[50],
    },
    empty: {
      backgroundColor: colors.blueGrey[50],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    action: {
      padding: theme.spacing(2),
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  })
);

export default function TextUploader({ lessonID }: { lessonID: number }) {
  const classes = useStyles();
  const [url, setURL] = useState("");
  useEffect(() => {
    getTextURL(lessonID).then(setURL, console.error);
  }, [lessonID]);
  const [uploading, setUploading] = useState(false);
  const ref = createRef<HTMLInputElement>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      setUploading(true);
      updateText(lessonID, files[0])
        .then(() => getTextURL(lessonID).then(setURL))
        .catch(console.error)
        .finally(() => {
          setUploading(false);
        });
    }
  };
  const handleSave = () => {
    if (ref?.current) {
      const { current } = ref;
      current.value = ""; // 削除後など再アップロードできるよう（changeイベントが発火されるよう）一度空にする
      current.click();
    }
  };
  const handleDelete = () => {
    deleteText(lessonID).then(() => setURL(""), console.error);
  };

  return (
    <Card>
      <CardHeader title={"PDFテキスト"} />
      <div className={classes.box}>
        {url ? (
          // srcだけ更新しても中身は変わらないので更新の度にDOMを再生成する
          <embed src={url} key={url} className={classes.content} />
        ) : (
          <div className={clsx(classes.content, classes.empty)}>
            {uploading && <Progress />}
          </div>
        )}
      </div>
      <Box
        className={classes.action}
        p={2}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          color="primary"
          onClick={handleSave}
          size="small"
          variant="contained"
        >
          アップロード
        </Button>
        <Box display="none">
          <input
            ref={ref}
            type="file"
            accept="application/pdf"
            onChange={handleChange}
          />
        </Box>
        <Button
          className={classes.deleteButton}
          onClick={handleDelete}
          size="small"
          disabled={!url}
        >
          削除
        </Button>
      </Box>
    </Card>
  );
}
