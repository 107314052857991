import { client } from "../utils/axios";
import { isCloudinaryImage } from "./media";

export type Teacher = {
  teacherID: number;
  familyName: string;
  firstName: string;
  familyNameEn: string;
  firstNameEn: string;
  avatar?: import("./media").CloudinaryImage;
};

export function isTeacher(x: any): x is Teacher {
  return (
    x &&
    typeof x.teacherID === "number" &&
    typeof x.familyName === "string" &&
    typeof x.firstName === "string" &&
    typeof x.familyNameEn === "string" &&
    typeof x.firstNameEn === "string" &&
    (x.avatar === undefined || isCloudinaryImage(x.avatar))
  );
}

export function isTeacherArray(x: any): x is Teacher[] {
  return Array.isArray(x) && x.every(isTeacher);
}

export type ValidationError = {
  familyName?: string;
  firstName?: string;
  familyNameEn?: string;
  firstNameEn?: string;
};

const NAME_MAX_LEN = 20;

export function validate(teacher: Teacher): ValidationError | null {
  const keys: Array<keyof ValidationError> = [
    "familyName",
    "firstName",
    "familyNameEn",
    "firstNameEn",
  ];
  const error: ValidationError = {};
  let valid = true;
  keys.forEach((k) => {
    const val = teacher[k];
    if (!val) {
      error[k] = "必須項目です";
      valid = false;
      return;
    }
    if (Array.from(val).length > NAME_MAX_LEN) {
      error[k] = `上限は${NAME_MAX_LEN}文字です`;
      valid = false;
      return;
    }
    if (k === "familyNameEn" || k === "firstNameEn") {
      if (!/^[a-zA-Z]+$/.test(val)) {
        error[k] = "英字で入力してください";
        valid = false;
      }
    }
  });
  return valid ? null : error;
}

export type ZoomSetting = {
  categoryCode: string;
  available: boolean;
};

function isZoomSetting(x: any): x is ZoomSetting {
  return (
    x &&
    typeof x?.categoryCode === "string" &&
    typeof x?.available === "boolean"
  );
}

export async function getTeachers() {
  const c = await client();
  const res = await c.get("/teachers");
  const { teachers } = res.data;
  if (isTeacherArray(teachers)) {
    return teachers;
  }
  throw Error("failed to get teachers");
}

export async function createTeacher(t: Teacher) {
  const c = await client();
  await c.post("/teachers", t);
}

export async function getTeacher(id: number) {
  return getTeachers().then((xs) => xs.find((x) => x.teacherID === id));
}

export async function createTeacherImage(
  teacherID: number,
  cldnImageID: number
) {
  const c = await client();
  await c.post(`/teachers/${teacherID}/images`, { cldnImageID });
}

//
// HW担当コース
//
export async function getTeacherAssignment(id: number) {
  const c = await client();
  return c.get(`/teachers/${id}/assignment`).then(({ data }) => {
    const { courseIDs } = data;
    if (
      Array.isArray(courseIDs) &&
      courseIDs.every((x) => typeof x === "number")
    ) {
      return courseIDs as number[];
    } else if (courseIDs === null) {
      return [];
    }
    throw Error("failed to get teacher assignment");
  });
}

export async function updateTeacherAssignment(
  teacherID: number,
  courseIDs: number[]
) {
  const c = await client();
  return c.post(`/teachers/${teacherID}/assignment`, { courseIDs });
}

//
// 講師アプリアカウント
//
export async function getTeacherAppEmail(id: number) {
  const c = await client();
  return c.get(`/teachers/${id}/app/emails`).then(({ data }) => {
    const { email } = data;
    if (typeof email === "string") {
      return email;
    }
    throw Error("failed to get teacher app email");
  });
}

export async function createTeacherAppEmail(id: number, email: string) {
  const c = await client();
  return c.post(`/teachers/${id}/app/emails`, { email });
}

//
// zoom設定
//
export async function getZoomEmail(id: number) {
  const c = await client();
  return c.get(`/teachers/${id}/videoLessons/emails`).then(({ data }) => {
    const { email } = data;
    if (typeof email === "string") {
      return email;
    }
    throw Error("failed to get teacher zoom email");
  });
}

export async function createZoomEmail(id: number, email: string) {
  const c = await client();
  return c.post(`/teachers/${id}/videoLessons/emails`, { email });
}

export async function getZoomSetting(id: number) {
  const c = await client();
  return c.get(`/teachers/${id}/videoLessons/settings`).then(({ data }) => {
    const { setting } = data;
    if (!setting) {
      return null;
    }
    if (isZoomSetting(setting)) {
      return setting;
    }
    throw Error("failed to get teacher zoom setting");
  });
}

export async function updateZoomSetting(id: number, setting: ZoomSetting) {
  const c = await client();
  return c.post(`/teachers/${id}/videoLessons/settings`, setting);
}
