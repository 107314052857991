import Button from "@material-ui/core/Button";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Page } from "../../../models/page";
import { Subscription } from "../../../models/subscriptions";
import { getUserSubscriptions } from "../../../models/user";
import Table from "../../SubscriptionList/Table";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: theme.spacing(3),
  },
  hasPrevNext: {
    justifyContent: "space-between",
  },
  hasNext: {
    justifyContent: "flex-end",
  },
}));

export default function SubscriptionList({ uid }: { uid: string }) {
  const classes = useStyles();

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [page, setPage] = useState<Page>({});
  const [nowPage, setNowPage] = useState<number>(1);

  useEffect(() => {
    getUserSubscriptions(uid, nowPage).then(({ subscriptions, page }) => {
      setSubscriptions(subscriptions);
      setPage(page);
    }, console.error);
  }, [uid, nowPage]);

  const handleNext = () => {
    if (page?.next) {
      setNowPage(page.next);
    }
  };
  const handlePrev = () => {
    if (page?.prev) {
      setNowPage(page.prev);
    }
  };

  return (
    <div>
      <Table subscriptions={subscriptions} />
      {page && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: page.prev && page.next,
            [classes.hasNext]: !page.prev && page.next,
          })}
        >
          {page.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {page.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
