import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import type { Coupon } from "../../models/coupon";
import { getCoupons } from "../../models/coupon";
import Form from "./Form";
import Header from "./Header";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function CouponList() {
  const classes = useStyles();

  const [coupons, setCoupons] = useState<Coupon[]>([]);
  useEffect(() => {
    getCoupons().then(setCoupons).catch(console.error);
  }, []);

  const [openEditor, setOpenEditor] = useState(false);
  const handleOpenEditor = () => {
    setOpenEditor(true);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
  };

  return (
    <div className={classes.root}>
      <Header onClickEditor={handleOpenEditor} />
      <Table className={classes.results} coupons={coupons} />
      <Form open={openEditor} onClose={handleCloseEditor} />
    </div>
  );
}
