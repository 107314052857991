import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";
import { requiredMessage, maxLengthRule } from "../../../utils/form";
import { MusicTypeInput, MUSIC_TYPE_NAME_MAX_LEN } from "../../../models/play";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginTop: theme.spacing(3),
      textAlign: "right",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

type Props = {
  defaultValues: MusicTypeInput;
  onSave: (data: MusicTypeInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function Editor({
  defaultValues,
  onSave,
  onCancel,
  isSaving,
  saveError,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<MusicTypeInput>({
    defaultValues: defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {saveError && <Typography color="error">{saveError}</Typography>}
      <div className={classes.container}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(MUSIC_TYPE_NAME_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="名前"
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
