import Button from "@material-ui/core/Button";
import { useState } from "react";
import type { RedeemableUserCouponInput } from "../../../../models/coupon";
import { putRedeemableUserCoupon } from "../../../../models/coupon";
import Form from "./Form";

export default function Editor({
  couponID,
  onSave,
}: {
  couponID: number;
  onSave: (url: string) => void;
}) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSave = (input: RedeemableUserCouponInput) => {
    setIsSaving(true);
    setError("");
    putRedeemableUserCoupon(couponID, input)
      .then(
        ({ url }) => {
          onSave(url);
          handleClose();
        },
        (e) => {
          switch (e?.response?.status) {
            case 409:
              setError(
                "ユーザークーポンがすでに設定されているため、ユーザークーポンを設定できません。"
              );
              return;
            case 422:
              setError(
                "このクーポンはクーポンコードが設定されているため、ユーザークーポンとして設定できません。"
              );
              return;
            default:
              setError("エラーが発生し、設定に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        設定する
      </Button>
      <Form
        open={open}
        onSave={handleSave}
        onCancel={handleClose}
        isSaving={isSaving}
        saveError={error}
      />
    </>
  );
}
