import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
  })
);

export default function CourseList({
  className,
  courses,
}: {
  className: string;
  courses: string[];
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>配信順</TableCell>
                  <TableCell>コード</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.map((x, i) => (
                  <TableRow hover={true} key={x}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{x}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={`/courses/${x}`}
                        variant="contained"
                      >
                        編集
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
