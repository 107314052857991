import { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { onAuthStateChanged } from "./models/auth";
import theme from "./theme";
import Routes from "./routes";
import "./assets/scss/index.scss";
import firebase from "firebase/app";
import "firebase/auth";
import { firebaseOptions } from "./config";

firebase.initializeApp(firebaseOptions);

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    onAuthStateChanged(setAuthenticated);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Routes authenticated={authenticated} />
    </ThemeProvider>
  );
};

export default App;
