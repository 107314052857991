import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Controller, useForm } from "react-hook-form";
import { Category } from "../../../../models/category";
import { PlayInput, PLAY_NAME_MAX_LEN } from "../../../../models/play";
import { requiredMessage, maxLengthRule } from "../../../../utils/form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginTop: theme.spacing(3),
      textAlign: "right",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
    },
    listForm: {
      width: "100%",
    },
  })
);

type Props = {
  defaultValues: PlayInput;
  selectList: {
    categories: Category[];
  };
  onSave: (data: PlayInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function Form({
  defaultValues,
  selectList,
  onSave,
  onCancel,
  isSaving,
  saveError,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<PlayInput>({
    defaultValues: defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSave)}>
      {saveError && <Typography color="error">{saveError}</Typography>}
      <div className={classes.container}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(PLAY_NAME_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="曲名"
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.container}>
        <Controller
          name="categoryCode"
          control={control}
          rules={{
            required: requiredMessage,
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl className={classes.listForm} error={!!error}>
              <InputLabel id="category-label">カテゴリ</InputLabel>
              <Select
                labelId="category-label"
                {...field}
                value={selectList.categories.length === 0 ? "" : field.value}
              >
                {selectList.categories.map((c) => (
                  <MenuItem key={c.categoryCode} value={c.categoryCode}>
                    {c.categoryName}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
