import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toJaName } from "../../../models/plan";
import type { Subscription } from "../../../models/subscriptions";
import { getUser, User } from "../../../models/user";
import { subscriptionPage } from "../../../utils/stripe";

export default function DescriptionTable({
  subscription,
}: {
  subscription: Subscription;
}) {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    getUser(subscription.uid).then(setUser).catch(console.error);
  }, [subscription.uid]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" width="25%">
            ID
          </TableCell>
          <TableCell align="right">{subscription.subscriptionID}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            UID
          </TableCell>
          <TableCell align="right">{subscription.uid}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            メールアドレス
          </TableCell>
          <TableCell align="right">{user?.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            プラン
          </TableCell>
          <TableCell align="right">{toJaName(subscription.plan)} </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            カテゴリ
          </TableCell>
          <TableCell align="right">
            <Link
              component={RouterLink}
              to={`/categories/${subscription.category.categoryCode}`}
            >
              {subscription.category.categoryName}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            カリキュラム
          </TableCell>
          <TableCell align="right">
            <Link
              component={RouterLink}
              to={`/currs/${subscription.curriculum.currCode}`}
            >
              {subscription.curriculum.currName}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            開始日時
          </TableCell>
          <TableCell align="right">
            {format(subscription.startTime, "yyyy-MM-dd HH:mm:ss", {
              locale: ja,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            キャンセル日時
          </TableCell>
          <TableCell align="right">
            {subscription.cancel
              ? format(subscription.cancel.cancelTime, "yyyy-MM-dd HH:mm:ss", {
                  locale: ja,
                })
              : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            失効日時
          </TableCell>
          <TableCell align="right">
            {subscription.cancel
              ? format(subscription.cancel.expireTime, "yyyy-MM-dd HH:mm:ss", {
                  locale: ja,
                })
              : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            キャンセル理由
          </TableCell>
          <TableCell align="right">
            {subscription.cancelReason
              ? JSON.stringify(subscription.cancelReason)
              : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Stripe ID
          </TableCell>
          <TableCell align="right">
            <Link
              href={subscriptionPage(subscription.stripeSubscriptionID)}
              target="_blank"
            >
              {subscription.stripeSubscriptionID}
            </Link>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
