import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import type { Submission } from "../../../models/homework";
import { getUser, User } from "../../../models/user";

export default function DescriptionTable({
  submission,
}: {
  submission: Submission;
}) {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    getUser(submission.uid).then(setUser).catch(console.error);
  }, [submission.uid]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            レッスンID
          </TableCell>
          <TableCell align="right">
            <Link
              href={`/lessons/${submission.lesson.lessonID}`}
              target="_blank"
            >
              {submission.lesson.lessonID}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            レッスン名
          </TableCell>
          <TableCell align="right">
            <Link
              href={`/lessons/${submission.lesson.lessonID}`}
              target="_blank"
            >
              {submission.lesson.lessonName}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            UID
          </TableCell>
          <TableCell align="right">{submission.uid}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            メールアドレス
          </TableCell>
          <TableCell align="right">{user?.email}</TableCell>
        </TableRow>
        {submission.profile && (
          <TableRow>
            <TableCell component="th" scope="row">
              ユーザー名
            </TableCell>
            <TableCell align="right">{submission.profile.nickname}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell component="th" scope="row">
            提出日時
          </TableCell>
          <TableCell align="right">
            {format(submission.createTime, "yyyy-MM-dd HH:mm:ss", {
              locale: ja,
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
