import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import type { ProductInput } from "../../../../models/product";
import { PRODUCT_NAME_MAX_LEN } from "../../../../models/product";
import {
  maxLengthRule,
  numberPattern,
  requiredMessage,
} from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  content: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  buttons: {
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export type FromProps = {
  defaultValues: ProductInput;
  onSave: (data: ProductInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
  open: boolean;
};

function formatInput(input: ProductInput): ProductInput {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  return {
    ...input,
    price: toInt(input.price),
  };
}

export default function Form({
  defaultValues,
  onSave,
  onCancel,
  isSaving,
  saveError,
  open,
}: FromProps) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<ProductInput>({
    defaultValues,
  });

  const handleSave = (data: ProductInput) => {
    onSave(formatInput(data));
  };

  return (
    <Modal onClose={onCancel} open={open}>
      <Card className={classes.root}>
        <CardHeader title="商品の更新" />
        <form onSubmit={handleSubmit(handleSave)}>
          <CardContent className={classes.content}>
            {saveError && <Typography color="error">{saveError}</Typography>}
            <Controller
              name="name"
              control={control}
              rules={{
                required: requiredMessage,
                maxLength: maxLengthRule(PRODUCT_NAME_MAX_LEN),
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="商品名"
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="price"
              control={control}
              rules={{
                required: requiredMessage,
                pattern: numberPattern,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="価格"
                  inputMode="numeric"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">¥</InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="stripeProductID"
              control={control}
              rules={{
                required: requiredMessage,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Stripe商品ID"
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <Controller
              name="stripePriceID"
              control={control}
              rules={{
                required: requiredMessage,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  label="Stripe価格ID"
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  {...field}
                />
              )}
            />
            <div className={classes.buttons}>
              <Button
                size="small"
                variant="contained"
                disabled={isSaving}
                onClick={onCancel}
              >
                キャンセル
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSaving}
              >
                保存
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
