import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";
import type { CouponCodeInput } from "../../../../models/coupon";
import { postCouponCodes } from "../../../../models/coupon";
import Form from "./Form";

export default function Editor({
  couponID,
  onSave,
}: {
  couponID: number;
  onSave: (code: string) => void;
}) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSave = (input: CouponCodeInput) => {
    setIsSaving(true);
    setError("");
    postCouponCodes(couponID, input)
      .then(
        () => {
          onSave(input.code);
          handleClose();
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setError(
                "コードの追加に失敗しました。このコードはすでに使用されている可能性があります。"
              );
              return;
            case 422:
              setError(
                "このクーポンはユーザークーポンのため、コードを追加できません。"
              );
              return;
            default:
              setError("エラーが発生し、追加に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleOpen}
        startIcon={<AddIcon />}
      >
        追加
      </Button>
      <Form
        open={open}
        onSave={handleSave}
        onCancel={handleClose}
        isSaving={isSaving}
        saveError={error}
      />
    </>
  );
}
