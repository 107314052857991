import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Progress from "../../components/Progress/Progress";
import TrialCourseLabel from "../../components/TrialCourseLabel";
import { getCourseVersions, getTrial } from "../../models/course";
import CourseHeader from "./CourseHeader";
import CourseNameForm from "./CourseNameForm";
import CourseVersionList from "./CourseVersionList";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function Course() {
  const classes = useStyles();

  const { code } = useParams<{ code: string }>();

  const [courses, setCourses] = useState<
    import("../../models/course").AdminCourse[]
  >([]);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCourseVersions(code)
      .then(setCourses, console.error)
      .finally(() => {
        setLoading(false);
      });
    getTrial(code).then(setIsTrial, console.error);
  }, [code]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <Progress />;
  }

  return (
    <div className={classes.root}>
      <CourseHeader
        courseCode={code}
        onClick={handleOpen}
        labels={<TrialCourseLabel isTrial={isTrial} />}
      />
      <CourseVersionList className={classes.results} courses={courses} />
      <CourseNameForm courseCode={code} open={open} onClose={handleClose} />
    </div>
  );
}
