import Card from "@material-ui/core/Card";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { postCategoryPlans } from "../../../../models/category";
import type { Plan } from "../../../../models/plan";
import { getPlans } from "../../../../models/plan";
import Form from "./Form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 400,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
  })
);

export default function Editor({
  cateogryCode,
  defaultPlans,
  open,
  onClose,
  onUpdate,
}: {
  cateogryCode: string;
  defaultPlans: Plan[];
  open: boolean;
  onClose: () => void;
  onUpdate: (plans: Plan[]) => void;
}) {
  const classes = useStyles();

  const [plans, setPlans] = useState<Plan[]>([]);
  useEffect(() => {
    getPlans().then(setPlans).catch(console.error);
  }, []);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>("");
  const handleSubmit = (ids: number[]) => {
    setUploading(true);
    setUploadError("");
    postCategoryPlans(cateogryCode, ids)
      .then(
        () => {
          onUpdate(plans.filter(({ planID }) => ids.includes(planID)));
        },
        (e) => {
          setUploadError("プランの追加に失敗しました");
        }
      )
      .finally(() => setUploading(false));
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <Form
          plans={plans.filter(
            ({ planID }) => !defaultPlans.find((p) => p.planID === planID)
          )}
          onSubmit={handleSubmit}
          onClose={onClose}
          uploading={uploading}
          uploadError={uploadError}
        />
      </Card>
    </Modal>
  );
}
