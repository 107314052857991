import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export default function CreateButton({
  currCode,
  onClick,
  disabled,
}: {
  currCode: string;
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Grid item>
      <Button
        color="primary"
        variant="contained"
        onClick={onClick}
        disabled={disabled}
      >
        コース作成
      </Button>
    </Grid>
  );
}
