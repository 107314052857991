import Box from "@material-ui/core/Box";
import { useState } from "react";
import LessonCreateForm from "../../../../components/LessonCreateForm";
import { createCourseLesson, deleteLessons } from "../../../../models/course";
import Header from "./Header";
import Table from "./Table";

export default function List({
  course,
  lessons,
  onClickSort,
  onClickSelect,
  editable,
  reload,
}: {
  course: import("../../../../models/course").AdminCourse;
  lessons: import("../../../../models/lesson").LessonSummary[];
  editable: boolean;
  onClickSort: () => void;
  onClickSelect: () => void;
  reload: () => Promise<void>;
}) {
  const [checks, setChecks] = useState(new Set<number>());
  const handleCheck = (id: number) => {
    setChecks((checks) => {
      const c = new Set(checks);
      if (c.has(id)) {
        c.delete(id);
      } else {
        c.add(id);
      }
      return c;
    });
  };
  const handleDelete = () => {
    deleteLessons(course.courseCode, course.courseVer, Array.from(checks))
      .then(reload)
      .then(() => setChecks(new Set()))
      .catch(console.error);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (lessonName: string) => {
    return createCourseLesson(course.courseCode, course.courseVer, lessonName);
  };

  return (
    <div>
      <Header
        onClickCreate={handleClickOpen}
        onClickSelect={onClickSelect}
        onClickSort={onClickSort}
        onClickDelete={handleDelete}
        editable={editable}
        canDelete={editable && checks.size > 0}
      />
      <Box mt={3}>
        <Table
          course={course}
          lessons={lessons}
          checks={checks}
          onCheck={handleCheck}
        />
      </Box>
      <LessonCreateForm
        open={open}
        onClose={handleClose}
        save={handleSave}
        reload={reload}
      />
    </div>
  );
}
