export type Page = {
  prev?: number;
  next?: number;
};

export function isPage(x: unknown): x is Page {
  return (
    typeof x === "object" &&
    (typeof (x as Page).prev === "undefined" ||
      typeof (x as Page).prev === "number") &&
    (typeof (x as Page).next === "undefined" ||
      typeof (x as Page).next === "number")
  );
}

const PAGE = "page";

export type QueryParams = {
  page?: number;
};

export function parseQueryParams(search: string) {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};
  const page = params.get(PAGE);
  if (page) {
    result[PAGE] = parseInt(page, 10);
  }
  return result;
}

export function buildQueryParams(params: QueryParams): URLSearchParams {
  const p = new URLSearchParams();
  const { page } = params;
  if (page) {
    p.set(PAGE, page.toString());
  }
  return p;
}
