import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import { Category, isCategory } from "./category";
import { Curriculum, isCurriculum } from "./curriculum";

export type Deadline = {
  category: Category;
  curriculum: Curriculum;
  completed: boolean;
  deadline?: Date;
};

function isDeadline(x: any): x is Deadline {
  return (
    x &&
    isCategory(x.category) &&
    isCurriculum(x.curriculum) &&
    typeof x.completed == "boolean" &&
    (x.avatar === undefined || x.deadline instanceof Date)
  );
}

function isDeadlineArray(xs: any): xs is Deadline[] {
  return xs && Array.isArray(xs) && xs.every(isDeadline);
}

function transformDeadline(x: any) {
  if (!x) {
    return null;
  }
  const ret = { ...x };
  if (x.deadline) {
    ret.deadline = parseISO(ret.deadline);
  }
  return ret;
}

function transformDeadlines(xs: any) {
  if (xs === null) {
    return [];
  }
  if (!Array.isArray(xs)) {
    return null;
  }
  const ret: Deadline[] = [];
  for (let i = 0; i < xs.length; i++) {
    const x = transformDeadline(xs[i]);
    if (!x) {
      return null;
    }
    ret.push(x);
  }
  return ret;
}

export async function getDeadlines() {
  const c = await client();
  const { data } = await c.get("/deadlines");
  const { deadlines } = data;
  const xs = transformDeadlines(deadlines);
  if (!xs) {
    throw Error("failed to get deadlines");
  }
  if (isDeadlineArray(xs)) {
    return xs;
  }
  throw Error("invalid response: " + JSON.stringify(data));
}
