import { useEffect, useState } from "react";
import { Curriculum, getCurrs } from "../../../models/curriculum";
import type { ProductCurriculumInput } from "../../../models/product";
import { getProductCurrs, postProductCurr } from "../../../models/product";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function CurriculumView({ productID }: { productID: number }) {
  const [curriculums, setCurriculums] = useState<Curriculum[]>([]);
  useEffect(() => {
    getProductCurrs(productID).then(setCurriculums, console.error);
  }, [productID]);

  const [allCurrs, setAllCurrs] = useState<Curriculum[]>([]);
  useEffect(() => {
    getCurrs().then(setAllCurrs, console.error);
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [saveError, setSaveError] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = (input: ProductCurriculumInput) => {
    setIsSaving(true);
    setSaveError("");
    postProductCurr(productID, input)
      .then(
        () => {
          handleClose();
          setCurriculums((currs) => [
            ...currs,
            ...allCurrs.filter(({ currCode }) =>
              input.currCodes.includes(currCode)
            ),
          ]);
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です");
              return;
            default:
              setSaveError("エラーが発生し、保存に失敗しました");
              return;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    setSaveError("");
    handleClose();
  };

  return (
    <div>
      <Viewer curriculums={curriculums} onClick={handleOpen} />
      <Editor
        curriculums={allCurrs.filter(
          (c) => !curriculums.find(({ currName }) => currName === c.currName)
        )}
        isSaving={isSaving}
        saveError={saveError}
        onSave={handleSave}
        onCancel={handleCancel}
        open={open}
      />
    </div>
  );
}
