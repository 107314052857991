import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { PlayInput } from "../../../models/play";
import PlayEditor from "../../Play/General/Editor";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 400,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
  })
);

const defaultValues = {
  name: "",
  categoryCode: "",
};

type Props = {
  open: boolean;
  onSave: (data: PlayInput) => void;
  onClose: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function Form({
  open,
  onSave,
  onClose,
  isSaving,
  saveError,
}: Props) {
  const classes = useStyles();

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            演奏の追加
          </Typography>
          <PlayEditor
            defaultValues={defaultValues}
            onSave={onSave}
            onCancel={onClose}
            isSaving={isSaving}
            saveError={saveError}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
