import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getCategories } from "../../../models/category";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  createZoomEmail,
  getZoomEmail,
  getZoomSetting,
  updateZoomSetting,
} from "../../../models/teacher";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(4),
  },
  select: {
    width: 150,
  },
  formControl: {
    marginRight: theme.spacing(4),
  },
  input: {
    width: 400,
    marginRight: theme.spacing(4),
  },
}));

export default function VideoLesson({ teacherID }: { teacherID: number }) {
  const classes = useStyles();

  //
  // Email
  //
  const [registered, setRegistered] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(() => {
    setRegistered(false);
    getZoomEmail(teacherID).then((x) => {
      if (x) {
        setRegistered(true);
      }
      setEmail(x);
    });
  }, [teacherID]);
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmitEmail = (e: React.FormEvent) => {
    e.preventDefault();
    createZoomEmail(teacherID, email).then(() => {
      setRegistered(true);
    }, console.error);
  };

  //
  // Category, Availability
  //
  const [categories, setCategories] = useState<
    import("../../../models/category").Category[]
  >([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);

  const [setting, setSetting] = useState<
    import("../../../models/teacher").ZoomSetting & { changed: boolean }
  >({
    categoryCode: "",
    available: false,
    changed: false,
  });
  const handleChangeCategory = (e: React.ChangeEvent<{ value: unknown }>) => {
    const categoryCode = e.target.value;
    if (typeof categoryCode === "string") {
      setSetting((x) => ({ ...x, categoryCode, changed: true }));
    }
  };
  const handleChangeAvailable = () => {
    setSetting((x) => ({ ...x, available: !x.available, changed: true }));
  };
  useEffect(() => {
    setSetting({
      categoryCode: "",
      available: false,
      changed: false,
    });
    getZoomSetting(teacherID).then((x) => {
      if (x) {
        setSetting((s) => ({ ...s, ...x }));
      }
    }, console.error);
  }, [teacherID]);
  const handleClickUpdate = () => {
    updateZoomSetting(teacherID, setting).then(() => {
      setSetting((x) => ({ ...x, changed: false }));
    }, console.error);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Zoom レッスン設定</Typography>
        <form onSubmit={handleSubmitEmail}>
          <div className={classes.row}>
            <TextField
              value={email}
              onChange={handleChangeEmail}
              label="Zoom アカウント メールアドレス"
              required
              type="email"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.input}
              disabled={registered}
            />
            <Button
              disabled={registered || !email}
              type="submit"
              variant="contained"
              color="primary"
            >
              登録
            </Button>
          </div>
        </form>
        <div className={classes.row}>
          <FormControl className={clsx(classes.select, classes.formControl)}>
            <InputLabel id="teacher-category-select" shrink={true}>
              担当カテゴリ
            </InputLabel>
            <Select
              labelId="teacher-category-select"
              value={setting.categoryCode}
              onChange={handleChangeCategory}
              disabled={!registered}
            >
              {categories.map((x) => (
                <MenuItem key={x.categoryCode} value={x.categoryCode}>
                  {x.categoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={setting.available}
                onChange={handleChangeAvailable}
                color="primary"
              />
            }
            disabled={!registered || !setting.categoryCode}
            label={"カスタマーに公開"}
            className={classes.formControl}
          />
          <Button
            onClick={handleClickUpdate}
            disabled={!registered || !setting.categoryCode || !setting.changed}
            variant="contained"
            color="primary"
          >
            更新
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
