import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  label: {
    margin: 0,
  },
}));

export default function ToggleSwitch({
  checked,
  label,
  className,
  onChange,
}: {
  checked: boolean;
  label: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const classes = useStyles();
  return (
    <FormControl className={className}>
      <FormGroup>
        <FormControlLabel
          className={classes.label}
          control={
            <Switch color="primary" checked={checked} onChange={onChange} />
          }
          label={label}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
}
