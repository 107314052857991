import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
import { useEffect, useState } from "react";
import {
  getCurrCompleted,
  postCurrCompleted,
} from "../../../../models/curriculum";
import Dialog from "./CompleteDialog";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    redButton: {
      color: grey[50],
      backgroundColor: pink[500],
      "&:hover": {
        backgroundColor: pink[600],
      },
    },
  })
);

export default function Publish({ currCode }: { currCode: string }) {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>();

  useEffect(() => {
    getCurrCompleted(currCode).then(
      (res) => setCompleted(res.completed),
      console.error
    );
  }, [currCode]);

  const handleClick = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleComplete = () => {
    postCurrCompleted(currCode).then((res) => {
      setCompleted(true);
      setDialogOpen(false);
    }, console.error);
  };

  return (
    <>
      <Button
        className={classes.redButton}
        color="inherit"
        variant="contained"
        disabled={completed}
        onClick={handleClick}
      >
        {completed ? "完了済み" : "完了にする"}
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onComplete={handleComplete}
      />
    </>
  );
}
