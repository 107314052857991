import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Fragment, useEffect, useState } from "react";
import {
  DESCRIPTION_MAX_LEN,
  validateDescription,
} from "../../../../models/lesson";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  text: {
    border: `0.5px solid ${colors.grey[300]}`,
    borderRadius: 8,
    height: "8rem",
    overflow: "auto",
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
}));

export default function Description({
  desc,
  changeDesc,
}: {
  desc: string;
  changeDesc: (desc: string) => Promise<void>;
}) {
  const classes = useStyles();

  const [tmpDesc, setTmpDesc] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setTmpDesc(desc);
  }, [desc]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError(validateDescription(value));
    setTmpDesc(value);
  };
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = () => {
    if (error) {
      return;
    }
    changeDesc(tmpDesc).then(toggleEdit).catch(console.error);
  };

  if (edit) {
    return (
      <Fragment>
        <TextField
          fullWidth
          multiline
          rows={4}
          error={!!error}
          helperText={error}
          onChange={handleChange}
          value={tmpDesc}
          variant="outlined"
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">{`${
            Array.from(tmpDesc).length
          }/${DESCRIPTION_MAX_LEN}`}</Typography>
        </Box>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSave}
              disabled={desc === tmpDesc || !!error}
            >
              保存
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="default"
              variant="contained"
              size="small"
              onClick={toggleEdit}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>{" "}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Typography className={classes.text} variant="subtitle1" component="pre">
        {desc}
      </Typography>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={toggleEdit}
          >
            編集
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
