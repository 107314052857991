import { CloudinaryAudio, getAudioURL, videoSources } from "../../models/media";
import AudioPlayer from "../AudioPlayer";
import VideoPlayer from "../VideoPlayer";

export default function MediaPlayer({
  className,
  audio,
  contentType,
}: {
  className?: string;
  audio: CloudinaryAudio;
  contentType: string;
}) {
  if (contentType.includes("video/")) {
    return <VideoPlayer className={className} options={videoSources(audio)} />;
  }
  return (
    <AudioPlayer
      className={className}
      audios={["mp4", "webm", "acc"].map((ext) => ({
        src: getAudioURL(audio, ext),
        type: `audio/${ext}`,
      }))}
    />
  );
}
