import { useEffect, useState } from "react";
import { getMyRole, MEMBER, Role } from "../models/admin";

export default function useMyRole(): {
  role: Role;
  isLoading: boolean;
  error: string;
} {
  const [role, setRole] = useState<Role>(MEMBER);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    getMyRole()
      .then(setRole)
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, []);

  return { role, isLoading, error };
}
