import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useCallback, useEffect, useState } from "react";
import VimeoPlayer from "../../../../components/VimeoPlayer";

const aspectRatio = 9 / 16;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    emptyBox: {
      paddingTop: `${aspectRatio * 100}%`,
      position: "relative",
    },
    empty: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: colors.blueGrey[50],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    action: {
      padding: theme.spacing(2),
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
  })
);

export default function VimeoEditor({
  lessonID,
  title,
  getMovie,
  updateMovie,
  deleteMovie,
}: {
  lessonID: number;
  title: string;
  getMovie: (lessonID: number) => Promise<number>;
  updateMovie: (lessonID: number, vimeoID: number) => Promise<void>;
  deleteMovie: (lessonID: number) => Promise<void>;
}) {
  const classes = useStyles();
  const [vimeoID, setVimeoID] = useState(0);
  const [tmpID, setTmpID] = useState(0);
  const [input, setInput] = useState(0);
  const initID = (id: number) => {
    setVimeoID(id);
    setTmpID(id);
    setInput(id);
  };
  useEffect(() => {
    getMovie(lessonID).then(initID);
  }, [getMovie, lessonID]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value, 10);
    setInput(isNaN(val) ? 0 : val);
  };
  const handleConfirm = () => {
    setTmpID(input);
  };
  const [edit, setEdit] = useState(false);
  const handleToggle = () => {
    setEdit((edit) => !edit);
    setInput(tmpID);
  };
  const handleSave = () => {
    updateMovie(lessonID, tmpID)
      .then(() => {
        setVimeoID(tmpID);
        handleToggle();
      })
      .catch(console.error);
  };
  const handleDelete = () => {
    deleteMovie(lessonID)
      .then(() => {
        initID(0);
      })
      .catch(console.error);
  };

  const Empty = () => (
    <div className={classes.emptyBox}>
      <div className={classes.empty} />
    </div>
  );

  const [valid, setValid] = useState(false);
  const handleSuccess = useCallback(() => {
    setValid(true);
  }, []);
  const handleError = useCallback(() => {
    setValid(false);
  }, []);

  if (edit) {
    return (
      <Card>
        <CardHeader title={title} />
        {tmpID ? (
          <VimeoPlayer
            vimeoID={tmpID}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        ) : (
          <Empty />
        )}
        <Box p={2}>
          <Box display="flex" alignItems={"flex-end"}>
            <TextField
              fullWidth
              label="Vimeo ID"
              type="number"
              value={input || ""}
              onChange={handleChange}
            />
            <Button
              color="primary"
              disabled={!input}
              onClick={handleConfirm}
              size="small"
            >
              確認
            </Button>
          </Box>
          <Box mt={1}>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  color="primary"
                  disabled={!valid || tmpID === vimeoID}
                  onClick={handleSave}
                  size="small"
                  variant="contained"
                >
                  保存
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="default"
                  variant="contained"
                  onClick={handleToggle}
                  size="small"
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title={title} />
      {vimeoID ? <VimeoPlayer vimeoID={vimeoID} /> : <Empty />}
      <Box
        className={classes.action}
        p={2}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleToggle}
          size="small"
        >
          編集
        </Button>
        <Button
          className={classes.deleteButton}
          onClick={handleDelete}
          size="small"
          disabled={!vimeoID}
        >
          削除
        </Button>
      </Box>
    </Card>
  );
}
