import { useState, useEffect } from "react";
import { getPublish, postPublish } from "../../../models/play";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    greenButton: {
      color: "white",
      backgroundColor: green[600],
      "&:hover": {
        backgroundColor: green[900],
      },
    },
  })
);

export default function Publish({ id }: { id: number }) {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const loadPublish = (id: number) =>
    getPublish(id).then(setIsPublished, console.error);

  useEffect(() => {
    loadPublish(id).finally(() => setLoading(false));
  }, [id]);

  const handlePublish = () => {
    if (!window.confirm("演奏・伴奏を公開しますか？")) return;
    postPublish(id).then(
      () => {
        setIsPublished(true);
      },
      (e) => console.error
    );
  };

  if (loading) return null;

  return (
    <div>
      <Typography variant="subtitle1">公開状態</Typography>
      <Box mt={1}>
        <Button
          className={classes.greenButton}
          variant="contained"
          onClick={handlePublish}
          disabled={isPublished}
        >
          {isPublished ? "公開済み" : "公開する"}
        </Button>
      </Box>
    </div>
  );
}
