import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import type { Page, QueryParams } from "../../models/page";
import { buildQueryParams, parseQueryParams } from "../../models/page";
import { getPosts, Post } from "../../models/post";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(3),
    },
    hasPrevNext: {
      justifyContent: "space-between",
    },
    hasNext: {
      justifyContent: "flex-end",
    },
  })
);

export default function Posts() {
  const classes = useStyles();

  const { pathname, search } = useLocation();
  const params = parseQueryParams(search);

  const [posts, setPosts] = useState<Post[]>([]);
  const [page, setPage] = useState<Page>({});
  useEffect(() => {
    getPosts(params.page).then(({ posts, page }) => {
      setPosts(posts);
      setPage(page);
    }, console.error);
  }, [params.page]);

  const history = useHistory();
  const handleFilter = (params: QueryParams) => {
    const p = buildQueryParams(params);
    history.push(`${pathname}?${p.toString()}`);
  };

  const handleNext = () => {
    if (page?.next) {
      handleFilter({ page: page.next });
    }
  };
  const handlePrev = () => {
    if (page?.prev) {
      handleFilter({ page: page.prev });
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            自由投稿
          </Typography>
        </Grid>
      </Grid>
      <Table className={classes.results} posts={posts} />
      {page && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: page.prev && page.next,
            [classes.hasNext]: !page.prev && page.next,
          })}
        >
          {page.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {page.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
