import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  deleteAudio,
  getMusics,
  getMusicTypes,
  MusicType,
  uploadAudio,
} from "../../../models/play";
import SongView from "./SongView";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

export default function Music({ id }: { id: number }) {
  const classes = useStyles();

  const [musics, setMusics] = useState<import("../../../models/play").Music[]>(
    []
  );

  const load = (id: number) => getMusics(id).then(setMusics, console.error);

  useEffect(() => {
    load(id);
  }, [id]);

  const [musicTypes, setMusicTypes] = useState<MusicType[]>([]);
  useEffect(() => {
    getMusicTypes().then(setMusicTypes, console.error);
  }, []);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleUpload =
    (musicID: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files.length > 0) {
        setIsUploading(true);
        uploadAudio(id, musicID, files[0], (p) =>
          console.debug(`progress: ${p}%`)
        )
          .then((audio) => {
            load(id);
          }, console.error)
          .finally(() => {
            setIsUploading(false);
          });
      }
    };

  const handleDelete = (musicID: number) => () => {
    deleteAudio(id, musicID).then(() => {
      load(id);
    }, console.error);
  };

  return (
    <div>
      {musicTypes.map(({ musicTypeID, musicType }) => (
        <Box className={classes.container} my={3} key={musicTypeID}>
          <Typography variant="subtitle1">{musicType}</Typography>
          <SongView
            playID={id}
            musicID={musicTypeID}
            music={musics.find((m) => m.musicTypeID === musicTypeID)}
            isUploading={isUploading}
            onUpload={handleUpload(musicTypeID)}
            onDelete={handleDelete(musicTypeID)}
          />
        </Box>
      ))}
      <Link component={RouterLink} to="/musicTypes">
        演奏形態を追加する
      </Link>
    </div>
  );
}
