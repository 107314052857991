import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import {
  Description as CurrDesc,
  ImageAndDescription as CurrImageAndDescription,
  LEAD_MAX_LEN,
  SUBTITLE_MAX_LEN,
  BODY_MAX_LEN,
} from "../../../../models/curriculum";
import ImageAndDescription from "./ImageAndDescription";
import { useForm, Controller } from "react-hook-form";
import {
  numberPattern,
  requiredMessage,
  maxLengthRule,
} from "../../../../utils/form";

const defaultValues = {
  title: "",
  headerImage: {
    cldnPublicID: "",
    cldnVersion: 0,
  },
  lead: "",
  subtitle: "",
  body: "",
  whatToGet: [],
  whatToLearn: [],
};

// react-hook-form は `valueAs` を `useController` でサポートしていない
// 数値のフィールドを文字列から数値に変換する
function format(desc: CurrDesc): CurrDesc {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  const formatImageAndDescription = (x: CurrImageAndDescription) => ({
    ...x,
    image: { ...x.image, cldnVersion: toInt(x.image.cldnVersion) },
  });
  const cldnVersion = toInt(desc.headerImage.cldnVersion);
  let vimeoID;
  if (desc.vimeoID && desc.vimeoID > 0) {
    vimeoID = toInt(desc.vimeoID);
  }
  const whatToGet = desc.whatToGet.map(formatImageAndDescription);
  const whatToLearn = desc.whatToLearn.map(formatImageAndDescription);
  return {
    ...desc,
    headerImage: { ...desc.headerImage, cldnVersion },
    whatToGet,
    whatToLearn,
    vimeoID,
  };
}

export default function Editor({
  desc,
  save,
  onClickCancel,
}: {
  desc?: CurrDesc;
  save: (desc: CurrDesc) => void;
  onClickCancel: () => void;
}) {
  const { handleSubmit, control, reset } = useForm<CurrDesc>({
    defaultValues,
  });

  useEffect(() => {
    if (desc) {
      reset(desc);
    }
  }, [reset, desc]);

  const onSubmit = (data: CurrDesc) => {
    save(format(data));
  };

  const handleClickCancel = () => {
    reset();
    onClickCancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={2}>
        <Controller
          name="title"
          control={control}
          rules={{ required: requiredMessage }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="タイトル"
              {...field}
            />
          )}
        />
      </Box>

      <Box my={2}>
        <Typography gutterBottom>ヘッダー画像</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              name="headerImage.cldnPublicID"
              control={control}
              rules={{ required: requiredMessage }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary Public ID"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="headerImage.cldnVersion"
              control={control}
              rules={{ required: requiredMessage, pattern: numberPattern }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                  variant="outlined"
                  label="Cloudinary version"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={2}>
        <Controller
          name="lead"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(LEAD_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                fullWidth
                multiline
                rows={6}
                error={!!error}
                helperText={error?.message}
                variant="outlined"
                label="リード文"
                {...field}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="body2">{`${field.value.length}/${LEAD_MAX_LEN}`}</Typography>
              </Box>
            </>
          )}
        />
      </Box>

      <Box>
        <Controller
          name="subtitle"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(SUBTITLE_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                variant="outlined"
                label="サブタイトル"
                {...field}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="body2">{`${field.value.length}/${SUBTITLE_MAX_LEN}`}</Typography>
              </Box>
            </>
          )}
        />
      </Box>

      <Box my={2}>
        <Controller
          name="body"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(BODY_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                fullWidth
                multiline
                rows={12}
                error={!!error}
                helperText={error?.message}
                variant="outlined"
                label="ボディ"
                {...field}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="body2">{`${field.value.length}/${BODY_MAX_LEN}`}</Typography>
              </Box>
            </>
          )}
        />
      </Box>

      <Box>
        <Controller
          name="vimeoID"
          control={control}
          rules={{
            pattern: numberPattern,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="Vimeo ID"
              {...field}
            />
          )}
        />
      </Box>

      <Box my={2}>
        <Typography gutterBottom>学べること</Typography>
        <Box mt={1}>
          <Typography gutterBottom>1.</Typography>
          <ImageAndDescription control={control} name="whatToLearn" index={0} />
        </Box>
        <Box mt={1}>
          <Typography gutterBottom>2.</Typography>
          <ImageAndDescription control={control} name="whatToLearn" index={1} />
        </Box>
      </Box>

      <Box my={2}>
        <Typography gutterBottom>このコースに含まれるもの</Typography>
        <Box mt={1}>
          <Typography gutterBottom>1.</Typography>
          <ImageAndDescription control={control} name="whatToGet" index={0} />
        </Box>
        <Box mt={1}>
          <Typography gutterBottom>2.</Typography>
          <ImageAndDescription control={control} name="whatToGet" index={1} />
        </Box>
      </Box>

      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="submit"
          >
            保存
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="default"
            variant="contained"
            size="small"
            onClick={handleClickCancel}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
