import { useEffect, useState } from "react";
import Player from "@vimeo/player";

export default function VimeoPlayer({
  vimeoID,
  onSuccess,
  onError,
}: {
  vimeoID: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const [player, setPlayer] = useState<Player>();
  const [el, setEl] = useState<HTMLDivElement>();
  useEffect(() => {
    if (player) {
      player
        .getVideoId()
        .then((id) => {
          if (id !== vimeoID) {
            return player.loadVideo(vimeoID).then(
              () => {
                onSuccess && onSuccess();
              },
              () => {
                onError && onError();
              }
            );
          }
        })
        .catch(console.error);
    } else if (el) {
      const p = new Player(el, {
        id: vimeoID,
        responsive: true,
      });
      p.ready()
        .then(
          () => {
            setPlayer(p);
            onSuccess && onSuccess();
          },
          () => p.destroy().finally(() => onError && onError()) // IDが不正な場合など。unloadする必要あり
        )
        .catch(console.error);
    }
  }, [vimeoID, onSuccess, onError, player, el]);

  const setRef = (el: HTMLDivElement) => {
    el && setEl(el);
  };

  return <div ref={setRef} />;
}
