import Box from "@material-ui/core/Box";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getUser } from "../../../models/auth";
import { AdminComment } from "../../../models/comment";
import Content from "./Content";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export default function Thread({ comments }: { comments: AdminComment[] }) {
  const classes = useStyles();
  const authUser = getUser();

  return (
    <Box className={classes.root} display="flex" flexDirection="column-reverse">
      {comments.length > 0 ? (
        comments.map((comment) => (
          <Content
            key={comment.adminCommentID}
            comment={comment}
            showEdit={authUser.uid === comment.admin.uid}
          />
        ))
      ) : (
        <Box p={2}>
          <Typography color="textSecondary">コメントはありません</Typography>
        </Box>
      )}
    </Box>
  );
}
