import { forwardRef } from "react";

type Props = {
  className?: string;
  audios: { src: string; type: string }[];
};

const AudioPlayer = forwardRef<HTMLAudioElement, Props>((props: Props, ref) => {
  const { className, audios } = props;
  return (
    <audio
      className={className}
      ref={ref}
      controls
      controlsList="nodownload noremoteplayback"
    >
      {audios.map(({ src, type }) => (
        <source key={src} src={src} type={type} />
      ))}
    </audio>
  );
});

export default AudioPlayer;
