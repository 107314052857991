import { makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import type { Comment } from "../../../models/post";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({ comments }: { comments: Comment[] }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <MuiTable className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width="15%">ユーザー名</TableCell>
            <TableCell width="25%">UID</TableCell>
            <TableCell width="10%">作成時刻</TableCell>
            <TableCell>コメント</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.length > 0 ? (
            comments.map(({ id, profile, comment, createTime }) => (
              <TableRow hover={true} key={id}>
                <TableCell>
                  <Typography noWrap>{profile.nickname}</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>{profile.uid}</Typography>
                </TableCell>
                <TableCell>
                  {format(createTime, "yyyy-MM-dd HH:mm:ss", {
                    locale: ja,
                  })}
                </TableCell>
                <TableCell>
                  <Typography>{comment}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography component="span" color="textSecondary">
                  コメントはありません
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
