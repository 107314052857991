import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import * as colors from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
  forwardRef,
  JSXElementConstructor,
  ReactNode,
  Ref,
  useState,
} from "react";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";

const CustomRouterLink = forwardRef(
  (props: NavLinkProps, ref: Ref<HTMLDivElement>) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  )
);

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  icon: {
    color: colors.blueGrey[600],
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function NavigationListItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  open: openProp,
  label: Label,
  disabled,
}: {
  title: string;
  href: string;
  depth: number;
  open: boolean;
  icon?: JSXElementConstructor<any>;
  label?: JSXElementConstructor<any>;
  disabled?: boolean;
  children?: ReactNode;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  const style = {
    paddingLeft: depth > 0 ? 32 + 8 * depth : 8,
  };

  if (children) {
    return (
      <ListItem
        className={classes.item}
        disableGutters={true}
        disabled={disabled}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem className={classes.itemLeaf} disableGutters disabled={disabled}>
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={CustomRouterLink}
          exact={true}
          style={style}
          to={href}
          disabled={disabled}
        >
          {Icon && <Icon className={classes.icon} />}
          {title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
}
