import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import type { Product } from "../../../../models/product";
import { pricePage, productPage } from "../../../../utils/stripe";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Viewer({
  product,
  onClick,
}: {
  product: Product;
  onClick: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">商品</Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onClick}
            startIcon={<EditIcon />}
          >
            編集
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" width="20%">
              <Typography component="span" color="textSecondary">
                ID
              </Typography>
            </TableCell>
            <TableCell>{product.productID}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                商品名
              </Typography>
            </TableCell>
            <TableCell>{product.productName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                価格
              </Typography>
            </TableCell>
            <TableCell>
              {new Intl.NumberFormat("ja-JP", {
                style: "currency",
                currency: "JPY",
              }).format(product.productPrice)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                Stripe Product ID
              </Typography>
            </TableCell>
            <TableCell>
              <Link href={productPage(product.stripeProductID)} target="_blank">
                {product.stripeProductID}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                Stripe Price ID
              </Typography>
            </TableCell>
            <TableCell>
              <Link href={pricePage(product.stripePriceID)} target="_blank">
                {product.stripePriceID}
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
