import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import {
  appendLessons,
  getCourseKey,
  getCourses,
} from "../../../../models/course";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectLessonTable from "../SelectLessonTable";

export default function SelectLessons({
  course,
  lessons,
  onCancel,
  reload,
}: {
  course: import("../../../../models/course").AdminCourse;
  lessons: import("../../../../models/lesson").Lesson[];
  onCancel: () => void;
  reload: () => Promise<void>;
}) {
  const [courses, setCourses] = useState<
    import("../../../../models/course").Course[]
  >([]);
  useEffect(() => {
    getCourses(course.categoryCode).then((xs) => {
      setCourses(
        xs.filter(
          (x) =>
            !(
              x.courseCode === course.courseCode &&
              x.courseVer === course.courseVer
            )
        )
      );
    }, console.error);
  }, [course]);

  const [checks, setChecks] = useState(new Set<number>());
  const handleCheck = (id: number) => {
    setChecks((checks) => {
      const c = new Set(checks);
      if (c.has(id)) {
        c.delete(id);
      } else {
        c.add(id);
      }
      return c;
    });
  };
  const lessonSet = new Set(lessons.map((x) => x.lessonID));
  const isChecked = (id: number) => checks.has(id) || lessonSet.has(id);
  const isDisabled = (id: number) => lessonSet.has(id);

  const handleSave = () => {
    appendLessons(course.courseCode, course.courseVer, Array.from(checks))
      .then(reload)
      .then(onCancel)
      .catch(console.error);
  };

  return (
    <div>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="flex-end"
        spacing={3}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={checks.size === 0}
          >
            追加
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={onCancel}>
            キャンセル
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        {courses.map((x) => (
          <ExpansionPanel key={getCourseKey(x)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`${x.courseName} (${x.courseCode} / ver. ${x.courseVer})`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <SelectLessonTable
                courseCode={x.courseCode}
                courseVer={x.courseVer}
                onCheck={handleCheck}
                isChecked={isChecked}
                isDisabled={isDisabled}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Box>
    </div>
  );
}
