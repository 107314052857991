import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Feature, toJaName } from "../../../../models/feature";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Viewer({
  feature,
  onAdd,
  onDelete,
}: {
  feature?: Feature;
  onAdd: () => void;
  onDelete: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">機能</Typography>
        </Grid>
        {!feature && (
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={onAdd}
              startIcon={<AddIcon />}
            >
              追加
            </Button>
          </Grid>
        )}
      </Grid>
      {feature ? (
        <Box display="flex" alignItems="center">
          <Typography>{toJaName(feature)}</Typography>
          <Box ml={1}>
            <Tooltip title="削除">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : (
        <Typography color="textSecondary">機能がありません</Typography>
      )}
    </div>
  );
}
