import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CourseStatusLabel from "../../../components/CourseStatusLabel";

type Overview = import("../../../models/play").Overview;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    lesson: {
      "& > * + *": {
        "&::before": {
          content: `","`,
          paddingRight: theme.spacing(1),
        },
      },
    },
  })
);

export default function Table({
  className,
  plays,
}: {
  className?: string;
  plays: Overview[];
}) {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell width="20%">曲名</TableCell>
                <TableCell>カテゴリ</TableCell>
                <TableCell>譜面</TableCell>
                <TableCell>形態</TableCell>
                <TableCell>レッスンID</TableCell>
                <TableCell>公開状態</TableCell>
                <TableCell>外部公開</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {plays.map((x) => (
                <TableRow hover={true} key={x.playID}>
                  <TableCell style={{ maxWidth: "10px" }}>
                    <Typography className={classes.name}>
                      {x.playName}
                    </Typography>
                  </TableCell>
                  <TableCell>{x.category.categoryName}</TableCell>
                  <TableCell>{x.musicScores ? "✅" : "❌"}</TableCell>
                  <TableCell>
                    {x.musics.map(({ musicType }) => musicType).join(", ")}
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.lesson}>
                      {x.lessons.map((l) => (
                        <Link
                          key={l}
                          component={RouterLink}
                          to={`/lessons/${l}`}
                        >
                          {l}
                        </Link>
                      ))}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CourseStatusLabel published={x.isPublished} />
                  </TableCell>
                  <TableCell>
                    <CourseStatusLabel published={x.isPublic} />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/plays/${x.playID}`}
                      variant="contained"
                      color="primary"
                    >
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
