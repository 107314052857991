import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import type { Theme } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { contentType, Post } from "../../../models/post";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import ContentTypeIcon from "../../../components/ContentTypeIcon";
import Visibility from "../../../components/Visibility";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  posts,
}: {
  className?: string;
  posts: Post[];
}) {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>投稿ID</TableCell>
                <TableCell width="10%">ユーザー名</TableCell>
                <TableCell width="15%">UID</TableCell>
                <TableCell width="10%">作成時刻</TableCell>
                <TableCell>投稿内容</TableCell>
                <TableCell width="5%">投稿タイプ</TableCell>
                <TableCell width="5%">コミュニティ表示</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.map((post) => (
                <TableRow hover={true} key={post.postID}>
                  <TableCell>
                    <Typography noWrap>{post.postID}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{post.profile.nickname}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{post.profile.uid}</Typography>
                  </TableCell>
                  <TableCell>
                    {format(post.createTime, "yyyy-MM-dd HH:mm:ss", {
                      locale: ja,
                    })}
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{post.text}</Typography>
                  </TableCell>
                  <TableCell>
                    <ContentTypeIcon
                      contentType={contentType(post)}
                      svgIconProps={{
                        color: "action",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Visibility invisible={post.hidden} />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      component={RouterLink}
                      to={`/posts/${post.postID}`}
                      variant="contained"
                      color="primary"
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
