import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import type { CouponPatchInput } from "../../../../models/coupon";
import { NAME_MAX_LEN } from "../../../../models/coupon";
import { maxLengthRule, requiredMessage } from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  buttons: {
    marginTop: theme.spacing(3),
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export type FromProps = {
  defaultValues: CouponPatchInput;
  onSave: (data: CouponPatchInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
  open: boolean;
};

export default function Form({
  defaultValues,
  onSave,
  onCancel,
  isSaving,
  saveError,
  open,
}: FromProps) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<CouponPatchInput>({
    defaultValues,
  });

  return (
    <Modal onClose={onCancel} open={open}>
      <Card className={classes.root}>
        <CardHeader title="クーポンの更新" />
        <form onSubmit={handleSubmit(onSave)}>
          <CardContent>
            {saveError && <Typography color="error">{saveError}</Typography>}
            <div>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(NAME_MAX_LEN),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="クーポン名"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                size="small"
                variant="contained"
                disabled={isSaving}
                onClick={onCancel}
              >
                キャンセル
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSaving}
              >
                保存
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
