import { useEffect, useState } from "react";
import { Feature, getFeatures } from "../../../models/feature";
import {
  deleteProductFeature,
  getProductFeature,
  postProductFeature,
  ProductFeatureInput,
} from "../../../models/product";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function FeatureView({ productID }: { productID: number }) {
  const [feature, setFeature] = useState<Feature>();
  useEffect(() => {
    getProductFeature(productID).then(setFeature, console.error);
  }, [productID]);

  const [features, setFeatures] = useState<Feature[]>([]);
  useEffect(() => {
    getFeatures().then(setFeatures, console.error);
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [saveError, setSaveError] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSave = (data: ProductFeatureInput) => {
    setIsSaving(true);
    setSaveError("");
    postProductFeature(productID, data)
      .then(
        () => {
          handleClose();
          setFeature(features.find((f) => f.featureID === data.featureID));
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です");
              return;
            default:
              setSaveError("エラーが発生し、保存に失敗しました");
              return;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    handleClose();
    setSaveError("");
  };

  const handleDelete = () => {
    const ok = window.confirm("機能を削除してもよろしいですか？");
    if (!ok) return;
    deleteProductFeature(productID)
      .then(() => {
        setFeature(undefined);
      })
      .catch(console.error);
  };

  return (
    <div>
      <Viewer feature={feature} onAdd={handleOpen} onDelete={handleDelete} />
      <Editor
        features={features}
        onSave={handleSave}
        onCancel={handleCancel}
        isSaving={isSaving}
        saveError={saveError}
        open={open}
      />
    </div>
  );
}
