import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import useMyRole from "../../../hooks/useMyRole";
import permissions from "../../../models/permission";
import { Subscription } from "../../../models/subscriptions";
import Cancel from "./Cancel";

export default function AdminOperation({
  subscription,
  onCancel,
}: {
  subscription: Subscription;
  onCancel?: () => void;
}) {
  const { role } = useMyRole();
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" width="25%">
            サブスクリプション
          </TableCell>
          <TableCell align="right">
            <Cancel
              subscription={subscription}
              disabled={!permissions.subscriptions.has(role)}
              onCancel={onCancel}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
