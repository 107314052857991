import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeleteDialog({
  open,
  onClose,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="error">投稿の削除の確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          投稿を削除してもよろしいですか？
          投稿を削除しても、提出状態は変更されず、ユーザーは再度投稿を提出することはできません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onDelete} color="default">
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
