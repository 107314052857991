import { makeStyles } from "@material-ui/core/styles";
import { ReactNode } from "react";
import { Fragment } from "react";
import Topbar from "./Topbar";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100%",
    paddingTop: 56,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
}));

export default function Auth({ children }: { children: ReactNode }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </Fragment>
  );
}
