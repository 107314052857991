import Grid from "@material-ui/core/Grid";
import {
  deleteHomework,
  deleteMovie,
  getHomework,
  getMovie,
  updateHomework,
  updateMovie,
} from "../../../models/lesson";
import TextUploader from "./TextUploader";
import VimeoEditor from "./VimeoEditor";

export default function Materials({ lessonID }: { lessonID: number }) {
  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xs={12}>
        <VimeoEditor
          lessonID={lessonID}
          title="本編動画"
          getMovie={getMovie}
          updateMovie={updateMovie}
          deleteMovie={deleteMovie}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <VimeoEditor
          lessonID={lessonID}
          title="課題動画"
          getMovie={getHomework}
          updateMovie={updateHomework}
          deleteMovie={deleteHomework}
        />
      </Grid>
      <Grid item lg={4} md={6} xs={12}>
        <TextUploader lessonID={lessonID} />
      </Grid>
    </Grid>
  );
}
