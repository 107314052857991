import { useEffect, useState } from "react";
import type { Coupon, CouponPatchInput } from "../../../models/coupon";
import { getCoupon, patchCoupon } from "../../../models/coupon";
import Expire from "../Expire";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function General({ id }: { id: number }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [coupon, setCoupon] = useState<Coupon>();

  const load = (id: number) => getCoupon(id).then(setCoupon, console.error);

  const reload = () => {
    load(id);
  };

  useEffect(() => {
    load(id).finally(() => setLoading(false));
  }, [id]);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleOnSave = (data: CouponPatchInput) => {
    setIsSaving(true);
    patchCoupon(id, data)
      .then(
        () => {
          handleClose();
          reload();
        },
        (e) => setSaveError(`${e}`)
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (loading) return null;

  return (
    <div>
      <Viewer
        coupon={coupon}
        onClick={handleOpen}
        expireActionElment={
          coupon && <Expire coupon={coupon} onExpire={reload} />
        }
      />
      <Editor
        defaultValues={{
          name: coupon?.couponName || "",
        }}
        onSave={handleOnSave}
        onCancel={handleClose}
        isSaving={isSaving}
        saveError={saveError}
        open={open}
      />
    </div>
  );
}
