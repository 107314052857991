import { client } from "../utils/axios";
import { getCurrentUserOrThrow } from "./auth";
import { isPage, Page } from "./page";

export const ADMIN = "admin";
export const MEMBER = "member";

export type AdminRole = typeof ADMIN;
export type MemberRole = typeof MEMBER;
export type Role = AdminRole | MemberRole;

export type AdminUser = {
  email: string;
  uid: string;
  role: Role;
};

function isAdminUser(x: unknown): x is AdminUser {
  return (
    typeof x === "object" &&
    typeof (x as AdminUser).uid === "string" &&
    typeof (x as AdminUser).email === "string" &&
    typeof (x as AdminUser).role === "string"
  );
}

export function isAdminUserArray(xs: unknown): xs is AdminUser[] {
  return Array.isArray(xs) && xs.every(isAdminUser);
}

export async function getAdminUsers(params: {
  page?: number;
}): Promise<{ users: AdminUser[]; page: Page }> {
  const c = await client();
  const { data } = await c.get("/admin/users", { params });
  const { users, page } = data;
  if (isAdminUserArray(users) && isPage(page)) {
    return { users, page };
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

function isRole(x: unknown): x is Role {
  return typeof x === "string" && (x === ADMIN || x === MEMBER);
}

export async function getMyRole(): Promise<Role> {
  const user = getCurrentUserOrThrow();
  const res = await user.getIdTokenResult(true);
  const role = res.claims["role"];
  if (isRole(role)) {
    return role;
  }
  throw Error(`invalid role: ${role}`);
}

export async function postRole(uid: string, role: Role): Promise<void> {
  const c = await client();
  await c.post(`/admin/users/${uid}/role`, { role });
}
