import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Fragment } from "react";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    labels: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

export default function Table({
  lessons,
  onChangeOrder,
}: {
  lessons: import("../../../../../models/lesson").LessonSummary[];
  onChangeOrder: (i: number, move: number) => void;
}) {
  const classes = useStyles();

  return (
    <Fragment>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <MuiTable>
              <TableHead>
                <TableRow>
                  <TableCell>配信順</TableCell>
                  <TableCell>タイトル</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {lessons.map((x, i) => (
                  <TableRow hover={true} key={x.lessonID}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{x.lessonName}</TableCell>
                    <TableCell align="right">
                      <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                          <IconButton
                            color="default"
                            aria-label="上へ"
                            onClick={() => onChangeOrder(i, -1)}
                            disabled={i === 0}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton
                            color="default"
                            aria-label="下へ"
                            onClick={() => onChangeOrder(i, 1)}
                            disabled={i === lessons.length - 1}
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
}
