import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getProducts,
  postProduct,
  Product,
  ProductInput,
} from "../../models/product";
import Editor from "./Editor";
import Header from "./Header";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(2),
    },
  })
);

export default function ProductList() {
  const classes = useStyles();

  const history = useHistory();

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getProducts().then(setProducts).catch(console.error);
  }, []);

  const [openEditor, setOpenEditor] = useState(false);
  const handleEditorOpen = () => {
    setOpenEditor(true);
  };
  const handleEditorClose = () => {
    setOpenEditor(false);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleAdd = (data: ProductInput) => {
    setIsSaving(true);
    setSaveError("");
    postProduct(data)
      .then(
        ({ productID }) => {
          history.push(`/products/${productID}`);
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です。");
              break;
            default:
              setSaveError("エラーが発生し、追加に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className={classes.root}>
      <Header onClickAdd={handleEditorOpen} />
      <Table className={classes.results} products={products} />
      <Editor
        open={openEditor}
        onSave={handleAdd}
        onClose={handleEditorClose}
        isSaving={isSaving}
        saveError={saveError}
      />
    </div>
  );
}
