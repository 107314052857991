import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TagLabels from "../../../../../components/TagLabels";
import { buildQuery } from "../../../../../models/tag";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
    labels: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

const ok = "✔️";
const no = "❌";

export default function ListTable({
  course,
  lessons,
  checks,
  onCheck,
  className,
}: {
  course: import("../../../../../models/course").AdminCourse;
  lessons: import("../../../../../models/lesson").LessonSummary[];
  checks: Set<number>;
  onCheck: (id: number) => void;
  className?: string;
}) {
  const classes = useStyles();

  const tags: import("../../../../../models/tag").Tag[] = lessons.map(
    (x, i) => {
      return {
        categoryCode: course.categoryCode,
        lessonOrder: i,
      };
    }
  );

  return (
    <div className={className}>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>配信順</TableCell>
                  <TableCell>管理コード</TableCell>
                  <TableCell>タイトル</TableCell>
                  <TableCell>導入文</TableCell>
                  <TableCell>画像</TableCell>
                  <TableCell>講師</TableCell>
                  <TableCell>動画</TableCell>
                  <TableCell>課題</TableCell>
                  <TableCell>テキスト</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {lessons.map((x, i) => (
                  <TableRow hover={true} key={x.lessonID}>
                    <TableCell>
                      <Checkbox
                        checked={checks.has(x.lessonID)}
                        onChange={() => onCheck(x.lessonID)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{x.lessonOrder + 1}</TableCell>
                    <TableCell>
                      <div className={classes.labels}>
                        <TagLabels tag={tags[i]} />
                      </div>
                    </TableCell>
                    <TableCell>{x.lessonName}</TableCell>
                    <TableCell>{x.description ? ok : no}</TableCell>
                    <TableCell>{x.image ? ok : no}</TableCell>
                    <TableCell>{x.teacher ? ok : no}</TableCell>
                    <TableCell>{x.mainMovie ? ok : no}</TableCell>
                    <TableCell>{x.homework ? ok : no}</TableCell>
                    <TableCell>{x.text ? ok : no}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={`/lessons/${x.lessonID}?${buildQuery(tags[i])}`}
                        variant="contained"
                      >
                        編集
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
