import Box from "@material-ui/core/Box";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useState } from "react";
import { AdminComment, patchAdminComment } from "../../../models/comment";
import ActionButton from "./ActionButton";
import Editor, { FormValues } from "./Editor";

const useStyles = makeStyles((theme: Theme) => ({
  auther: {
    fontWeight: "bold",
  },
  outline: {
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  edited: {
    fontSize: theme.typography.caption.fontSize,
  },
  comment: {
    whiteSpace: "pre-wrap",
  },
}));

export default function Content(props: {
  comment: AdminComment;
  showEdit: boolean;
}) {
  const classes = useStyles();
  const { showEdit } = props;
  const [adminComment, setAdminComment] = useState<AdminComment>(props.comment);
  const { adminCommentID, admin, createTime, updateTime, comment } =
    adminComment;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const handleClick = () => {
    setIsEditing(true);
  };

  const handleSubmit = (data: FormValues) => {
    patchAdminComment(adminCommentID, data.comment).then(() => {
      setAdminComment((c) => ({
        ...c,
        comment: data.comment,
        updateTime: new Date(),
      }));
      setIsEditing(false);
    }, console.error);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Editor
        defaultValues={{ comment }}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <Box component="article" p={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Box className={classes.outline} display="flex" alignItems="center">
          <Typography className={classes.auther} component="span">
            {admin.email}
          </Typography>
          <Typography component="span" color="textSecondary">
            {format(createTime, "yyyy-MM-dd HH:mm", {
              locale: ja,
            })}
          </Typography>
          {updateTime.getTime() > createTime.getTime() && (
            <Typography
              variant="caption"
              className={classes.edited}
              color="textSecondary"
            >
              編集済み
            </Typography>
          )}
        </Box>
        {showEdit && <ActionButton onClick={handleClick} />}
      </Box>
      <Typography className={classes.comment}>{comment}</Typography>
    </Box>
  );
}
