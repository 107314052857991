import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import {
  CloudinaryAudio,
  CloudinaryImage,
  isCloudinaryAudio,
  isCloudinaryImageArray,
} from "./media";
import { isPage, Page } from "./page";
import { isProfile, Profile } from "./profile";
import { typeFormatFromArray } from "./utils";

export type Statistics = {
  likeCount: number;
  commentCount: number;
};

function isStatistics(x: unknown): x is Statistics {
  return (
    typeof x === "object" &&
    typeof (x as Statistics).likeCount === "number" &&
    typeof (x as Statistics).commentCount === "number"
  );
}

export type Post = {
  postID: string;
  audio?: CloudinaryAudio;
  contentType?: string;
  images: CloudinaryImage[];
  text: string;
  createTime: Date;
  profile: Profile;
  statistics: Statistics;
  hidden: boolean;
};

function isPost(x: unknown): x is Post {
  return (
    typeof x === "object" &&
    typeof (x as Post).postID === "string" &&
    (typeof (x as Post).audio === "undefined" ||
      isCloudinaryAudio((x as Post).audio)) &&
    (typeof (x as Post).contentType === "undefined" ||
      typeof (x as Post).contentType === "string") &&
    isCloudinaryImageArray((x as Post).images) &&
    typeof (x as Post).text === "string" &&
    (x as Post).createTime instanceof Date &&
    isProfile((x as Post).profile) &&
    isStatistics((x as Post).statistics) &&
    typeof (x as Post).hidden === "boolean"
  );
}

export function formatPost(x: unknown): Post | null {
  if (typeof x !== "object") {
    return null;
  }
  const { createTime } = x as Post;
  if (typeof createTime !== "string") {
    return null;
  }
  const ret = {
    ...x,
    createTime: parseISO(createTime),
  };
  if (!isPost(ret)) {
    return null;
  }
  return ret;
}

export async function getPosts(
  page?: number
): Promise<{ posts: Post[]; page: Page }> {
  const c = await client();
  const { data } = await c.get("/posts", { params: { page } });
  const { posts: rawPosts, page: resPage } = data;
  const posts = typeFormatFromArray<Post>(rawPosts, formatPost);
  if (posts && isPage(resPage)) {
    return { posts, page: resPage };
  }
  throw new Error("Invalid response");
}

export async function getPost(id: string): Promise<Post> {
  const c = await client();
  const { data } = await c.get(`/posts/${id}`);
  const post = formatPost(data);
  if (post) {
    return post;
  }
  throw new Error("Invalid response");
}

export async function deletePost(id: string): Promise<void> {
  const c = await client();
  await c.delete(`/posts/${id}`);
}

export type Comment = {
  id: number;
  profile: Profile;
  comment: string;
  updateTime: Date;
  createTime: Date;
};

export function isComment(x: unknown): x is Comment {
  return (
    typeof x === "object" &&
    typeof (x as Comment).id === "number" &&
    isProfile((x as Comment).profile) &&
    typeof (x as Comment).comment === "string" &&
    (x as Comment).updateTime instanceof Date &&
    (x as Comment).createTime instanceof Date
  );
}

function formatComment(x: unknown): Comment | null {
  if (typeof x !== "object") {
    return null;
  }
  const { createTime, updateTime } = x as Comment;
  if (typeof createTime !== "string") {
    return null;
  }
  if (typeof updateTime !== "string") {
    return null;
  }
  const ret = {
    ...x,
    createTime: parseISO(createTime),
    updateTime: parseISO(updateTime),
  };
  if (!isComment(ret)) {
    return null;
  }
  return ret;
}

export async function getPostComments(
  id: string,
  page?: number
): Promise<{ comments: Comment[]; page: Page }> {
  const c = await client();
  const { data } = await c.get(`/posts/${id}/comments`, { params: { page } });
  const { comments: rawComments, page: resPage } = data;
  const comments = typeFormatFromArray<Comment>(rawComments, formatComment);
  if (comments && isPage(resPage)) {
    return { comments, page: resPage };
  }
  throw new Error("Invalid response");
}

export const VIDEO = "video";
export const AUDIO = "audio";
export const IMAGE = "image";
export const IMAGES = "images";
export const UNKNOWN = "unknown";
export type ContentType =
  | typeof AUDIO
  | typeof VIDEO
  | typeof IMAGE
  | typeof IMAGES
  | typeof UNKNOWN;

export function contentType(post: Post): ContentType {
  if (post.audio) {
    if (post.contentType?.includes("video/")) {
      return VIDEO;
    }
    return AUDIO;
  }
  if (post.images.length === 1) {
    return IMAGE;
  }
  if (post.images.length > 1) {
    return IMAGES;
  }
  return UNKNOWN;
}

export async function putHidden(postID: string): Promise<void> {
  const c = await client();
  await c.put(`/posts/${postID}/hidden`);
}

export async function deleteHidden(postID: string): Promise<void> {
  const c = await client();
  await c.delete(`/posts/${postID}/hidden`);
}
