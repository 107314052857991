import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link as RouterLink } from "react-router-dom";
import { ReactNode } from "react";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    space: {
      flexGrow: 1,
    },
  })
);

export default function CourseHeader({
  courseCode,
  labels,
  onClick,
  className,
}: {
  courseCode: string;
  labels: ReactNode;
  onClick: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            コース
          </Typography>
          <Typography component="h1" variant="h3">
            {courseCode}
          </Typography>
        </Grid>
        <Grid item>{labels}</Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Tooltip title="設定">
            <IconButton
              to={`/courses/${courseCode}/settings`}
              component={RouterLink}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={onClick}>
            バージョン追加
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
