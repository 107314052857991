import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  buildQueryParams,
  createPlay,
  getPlays,
  parseQueryParams,
  PlayInput,
  QueryParams,
} from "../../models/play";
import Header from "./Header";
import Table from "./Table";
import Form from "./Form";
import Filter from "./Filter";

type Overview = import("../../models/play").Overview;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(3),
    },
    hasPrevNext: {
      justifyContent: "space-between",
    },
    hasNext: {
      justifyContent: "flex-end",
    },
  })
);

export default function PlayList() {
  const classes = useStyles();

  const { pathname, search } = useLocation();
  const params = parseQueryParams(search);
  const { sortID, categoryCode } = params;

  const [plays, setPlays] = useState<Overview[]>([]);
  const [cursor, setCursor] =
    useState<import("../../models/homework").Cursor>();

  const load = (params: QueryParams) => {
    getPlays(params)
      .then((res) => {
        setPlays(res.plays);
        setCursor(res.cursor);
      })
      .catch(console.error);
  };

  useEffect(() => {
    load({ sortID, categoryCode });
  }, [sortID, categoryCode]);

  const [openEditor, setOpenEditor] = useState(false);
  const handleClickAdd = () => {
    setOpenEditor(true);
  };
  const handleEditorClose = () => {
    setOpenEditor(false);
  };

  const [openFilter, setOpenFilter] = useState(false);
  const handleClickFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const history = useHistory();
  const handleQueryParams = (params: QueryParams) => {
    const p = buildQueryParams(params);
    history.push(`${pathname}?${p.toString()}`);
  };

  const handleNext = () => {
    if (cursor?.next) {
      handleQueryParams({ ...params, sortID: cursor.next });
    }
  };
  const handlePrev = () => {
    if (cursor?.prev) {
      handleQueryParams({ ...params, sortID: cursor.prev });
    }
  };

  const handleFilter = (params: QueryParams) => {
    handleQueryParams(params);
    setOpenFilter(false);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleAdd = (data: PlayInput) => {
    setIsSaving(true);
    createPlay(data)
      .then(
        ({ playID }) => {
          history.push(`/plays/${playID}`);
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です。");
              break;
            default:
              setSaveError("エラーが発生し、追加に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className={classes.root}>
      <Header onClickAdd={handleClickAdd} onClickFilter={handleClickFilter} />
      <Table className={classes.results} plays={plays} />
      {cursor && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: cursor.prev && cursor.next,
            [classes.hasNext]: !cursor.prev && cursor.next,
          })}
        >
          {cursor.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {cursor.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
      <Form
        open={openEditor}
        onSave={handleAdd}
        onClose={handleEditorClose}
        isSaving={isSaving}
        saveError={saveError}
      />
      <Filter
        open={openFilter}
        onClose={handleCloseFilter}
        params={params}
        onFilter={handleFilter}
      />
    </div>
  );
}
