import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CancelDialog({
  open,
  onClose,
  onClick,
}: {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="error">
        サブスクリプションのキャンセルの確認
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          サブスクリプションをキャンセルしてもよろしいですか？
          この操作を取り消すことはできません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          いいえ
        </Button>
        <Button onClick={onClick} color="default">
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}
