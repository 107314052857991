import { makeStyles } from "@material-ui/core/styles";
import { ReactNode } from "react";
import { useEffect, useState } from "react";
import { getCategories } from "../../models/category";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
  },
}));

export default function Dashboard({ children }: { children?: ReactNode }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };
  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const [categories, setCategories] = useState<
    import("../../models/category").Category[]
  >([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
          categories={categories}
        />
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
}
