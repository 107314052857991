import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import {
  Header as CurrHeader,
  getHeader,
  updateHeader,
} from "../../../models/curriculum";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function Header({ currCode }: { currCode: string }) {
  const [header, setHeader] = useState<CurrHeader>();
  useEffect(() => {
    getHeader(currCode).then(setHeader, console.error);
  }, [currCode]);

  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = (header: CurrHeader) => {
    updateHeader(currCode, header)
      .then(() => {
        toggleEdit();
        setHeader(header);
      })
      .catch(console.error);
  };

  return (
    <Paper>
      <Box p={3}>
        <Box display={edit ? "block" : "none"}>
          <Editor
            header={header}
            save={handleSave}
            onClickCancel={toggleEdit}
          />
        </Box>
        <Box display={edit ? "none" : "block"}>
          <Viewer header={header} onClick={toggleEdit} />
        </Box>
      </Box>
    </Paper>
  );
}
