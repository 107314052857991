import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import type { Order } from "../../../models/order";
import DescriptionTable from "./DescriptionTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
  },
}));

export default function Preview({
  order,
  open,
  onClose,
  className,
}: {
  order: Order;
  open: boolean;
  onClose: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon color="action" />
        </IconButton>
        <CardContent>
          <DescriptionTable order={order} />
        </CardContent>
      </Card>
    </Modal>
  );
}
