import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import { Curriculum } from "../../../../models/curriculum";
import type { ProductCurriculumInput } from "../../../../models/product";
import { requiredMessage } from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  chip: {
    margin: theme.spacing(1),
  },
  buttons: {
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type FormProps = {
  curriculums: Curriculum[];
  onSave: (data: ProductCurriculumInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

const defaultValues: ProductCurriculumInput = {
  currCodes: [],
};

export default function Form({
  curriculums,
  onSave,
  onCancel,
  isSaving,
  saveError,
}: FormProps) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<ProductCurriculumInput>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSave)} className={classes.root}>
      {saveError && <Typography color="error">{saveError}</Typography>}
      <Controller
        name="currCodes"
        control={control}
        rules={{
          required: requiredMessage,
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error}>
            <InputLabel id="currCodes-label">カリキュラム</InputLabel>
            <Select
              labelId="currCodes-label"
              multiple
              input={<Input id="currCodes" />}
              renderValue={(selected) => (
                <div>
                  {(selected as string[]).map((value) => (
                    <Chip
                      className={classes.chip}
                      key={value}
                      label={
                        curriculums.find((c) => c.currCode === value)?.currName
                      }
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              error={!!error}
              {...field}
            >
              {curriculums.map(({ currCode, currName }) => (
                <MenuItem key={currCode} value={currCode}>
                  {currName}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{requiredMessage}</FormHelperText>}
          </FormControl>
        )}
      />
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
