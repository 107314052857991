import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Modal from "@material-ui/core/Modal";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import type { RedeemableUserCouponInput } from "../../../../models/coupon";
import { COUPON_TYPE_MAX_LEN } from "../../../../models/coupon";
import {
  alphaNumPattern,
  maxLengthRule,
  requiredMessage,
} from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  buttons: {
    marginTop: theme.spacing(3),
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export type FormProps = {
  onSave: (data: RedeemableUserCouponInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
  open: boolean;
};

const defaultValues: RedeemableUserCouponInput = {
  couponType: "",
};

export default function Form({
  onSave,
  onCancel,
  isSaving,
  saveError,
  open,
}: FormProps) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<RedeemableUserCouponInput>({
    defaultValues,
  });

  return (
    <Modal onClose={onCancel} open={open}>
      <Card className={classes.root}>
        <CardHeader title="ユーザークーポンの設定" />
        <form onSubmit={handleSubmit(onSave)}>
          <CardContent>
            {saveError && (
              <Box mb={2}>
                <Typography component="span" color="error" gutterBottom>
                  {saveError}
                </Typography>
              </Box>
            )}
            <div>
              <Controller
                name="couponType"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(COUPON_TYPE_MAX_LEN),
                  pattern: alphaNumPattern,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="クーポンタイプ"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                size="small"
                variant="contained"
                disabled={isSaving}
                onClick={onCancel}
              >
                キャンセル
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSaving}
              >
                設定する
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
