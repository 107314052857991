import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import type { Category } from "./category";
import { isCategory } from "./category";
import type { Curriculum } from "./curriculum";
import { isCurriculum } from "./curriculum";
import type { Page, QueryParams as PageQueryParams } from "./page";
import {
  buildQueryParams as pageBuildQueryParams,
  isPage,
  parseQueryParams as pageParseQueryParams,
} from "./page";
import type { Plan } from "./plan";
import { isPlan } from "./plan";
import { typeFormatFromArray } from "./utils";

export type Cancel = {
  cancelTime: Date;
  expireTime: Date;
};

function isCancel(x: unknown): x is Cancel {
  return (
    typeof x === "object" &&
    (x as Cancel).cancelTime instanceof Date &&
    (x as Cancel).expireTime instanceof Date
  );
}

function formatCancel(x: unknown): Cancel | null {
  if (typeof x !== "object") {
    return null;
  }
  const { cancelTime, expireTime } = x as Cancel;
  if (typeof cancelTime !== "string") {
    return null;
  }
  if (typeof expireTime !== "string") {
    return null;
  }
  const ret = {
    ...x,
    cancelTime: parseISO(cancelTime),
    expireTime: parseISO(expireTime),
  };
  if (!isCancel(ret)) {
    return null;
  }
  return ret;
}

export type CancelReason = {
  reasons: string[];
  details: string;
};

function isCancelReason(x: unknown): x is CancelReason {
  return (
    typeof x === "object" &&
    typeof (x as CancelReason).details === "string" &&
    Array.isArray((x as CancelReason).reasons) &&
    (x as CancelReason).reasons.every((r) => typeof r === "string")
  );
}

export type Subscription = {
  subscriptionID: number;
  uid: string;
  plan: Plan;
  category: Category;
  curriculum: Curriculum;
  startTime: Date;
  stripeSubscriptionID: string;
  cancel?: Cancel;
  cancelReason?: CancelReason;
  updated: boolean;
  completed: boolean;
};

function isSubscription(x: unknown): x is Subscription {
  return (
    typeof x === "object" &&
    typeof (x as Subscription).uid === "string" &&
    isPlan((x as Subscription).plan) &&
    isCategory((x as Subscription).category) &&
    isCurriculum((x as Subscription).curriculum) &&
    (x as Subscription).startTime instanceof Date &&
    typeof (x as Subscription).stripeSubscriptionID === "string" &&
    (typeof (x as Subscription).cancel === "undefined" ||
      isCancel((x as Subscription).cancel)) &&
    (typeof (x as Subscription).cancelReason === "undefined" ||
      isCancelReason((x as Subscription).cancelReason)) &&
    typeof (x as Subscription).updated === "boolean" &&
    typeof (x as Subscription).completed === "boolean"
  );
}

export function formatSubscription(x: unknown): Subscription | null {
  if (typeof x !== "object") {
    return null;
  }
  const { startTime, cancel: rawCancel } = x as Subscription;
  if (typeof startTime !== "string") {
    return null;
  }
  const cancel = rawCancel && formatCancel(rawCancel);
  if (cancel === null) {
    return null;
  }
  const ret = {
    ...x,
    startTime: parseISO(startTime),
    cancel,
  };
  if (!isSubscription(ret)) {
    return null;
  }
  return ret;
}

export const STATUS_ACTIVE = "active";
export const STATUS_CANCELED = "canceled";
export const STATUS_EXPIRED = "expired";
export const STATUS_UPDATED = "updated";
export const STATUS_COMPLETED = "completed";

const statusList: Status[] = [
  STATUS_ACTIVE,
  STATUS_CANCELED,
  STATUS_EXPIRED,
  STATUS_UPDATED,
  STATUS_COMPLETED,
];

export type Status =
  | typeof STATUS_ACTIVE
  | typeof STATUS_CANCELED
  | typeof STATUS_EXPIRED
  | typeof STATUS_UPDATED
  | typeof STATUS_COMPLETED;

export type QueryParams = {
  planID?: number;
  currCode?: string;
  categoryCode?: string;
  status?: Status[];
  uid?: string;
} & PageQueryParams;

const PLAN_ID = "planID";
const CURR_CODE = "currCode";
const CATEGORY_CODE = "categoryCode";
const STATUS = "status[]";
const UID = "uid";

export function parseQueryParams(search: string): QueryParams {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};

  const planID = params.get(PLAN_ID);
  if (planID) {
    result[PLAN_ID] = parseInt(planID, 10);
  }
  const currCode = params.get(CURR_CODE);
  if (currCode) {
    result[CURR_CODE] = currCode;
  }
  const categoryCode = params.get(CATEGORY_CODE);
  if (categoryCode) {
    result[CATEGORY_CODE] = categoryCode;
  }
  const status = params.getAll(STATUS);
  if (status.length > 0) {
    result.status = statusList.filter((s) => status.includes(s));
  }
  const uid = params.get(UID);
  if (uid) {
    result[UID] = uid;
  }

  const page = pageParseQueryParams(search);

  return { ...result, ...page };
}

export function buildQueryParams(params: QueryParams): URLSearchParams {
  const init: string[][] = [];

  const p = new URLSearchParams();
  const { planID, currCode, categoryCode, status, uid } = params;
  if (planID) {
    p.set(PLAN_ID, planID.toString());
  }
  if (currCode) {
    p.set(CURR_CODE, currCode);
  }
  if (categoryCode) {
    p.set(CATEGORY_CODE, categoryCode);
  }
  if (uid) {
    p.set(UID, uid);
  }
  init.push(...Array.from(p));

  if (status) {
    init.push(...status.map((s) => [STATUS, s]));
  }

  const pp = pageBuildQueryParams(params);
  init.push(...Array.from(pp));

  return new URLSearchParams(init);
}

export async function getSubscriptions(params: QueryParams): Promise<{
  subscriptions: Subscription[];
  page: Page;
}> {
  const c = await client();
  const { data } = await c.get("/subscriptions", { params });
  const subscriptions = typeFormatFromArray<Subscription>(
    data.subscriptions,
    formatSubscription
  );
  if (subscriptions && isPage(data.page)) {
    return { subscriptions, page: data.page };
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export async function cancelSubscription(
  subscriptionID: number
): Promise<void> {
  const c = await client();
  await c.post(`/subscriptions/${subscriptionID}/cancel`);
}
