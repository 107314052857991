function baseURL(): string {
  return window.location.hostname === "admin.phonim.com"
    ? "https://dashboard.stripe.com"
    : "https://dashboard.stripe.com/test";
}

export function subscriptionPage(id: string): string {
  return `${baseURL()}/subscriptions/${id}`;
}

export function invoicePage(id: string): string {
  return `${baseURL()}/invoices/${id}`;
}

export function couponPage(id: string): string {
  return `${baseURL()}/coupons/${id}`;
}

export function productPage(id: string): string {
  return `${baseURL()}/products/${id}`;
}

export function pricePage(id: string): string {
  return `${baseURL()}/prices/${id}`;
}
