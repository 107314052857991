import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles((theme: Theme) => ({
  space: {
    flexGrow: 1,
  },
}));

export default function Header({
  onClickFilter,
  className,
}: {
  onClickFilter: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            顧客
          </Typography>
        </Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Tooltip title="絞り込み">
            <IconButton onClick={onClickFilter}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}
