import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { Order } from "../../../models/order";
import Preview from "../Preview";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  orders,
}: {
  className?: string;
  orders: Order[];
}) {
  const classes = useStyles();

  const [id, setId] = useState<number>();
  const handleClickOpen = (id: number) => () => {
    setId(id);
  };
  const handleClose = () => {
    setId(undefined);
  };

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="5%">ID</TableCell>
                <TableCell width="25%">UID</TableCell>
                <TableCell width="10%">購入商品</TableCell>
                <TableCell width="10%">カテゴリ</TableCell>
                <TableCell width="10%">カリキュラム</TableCell>
                <TableCell width="10%">注文日時</TableCell>
                <TableCell width="10%">支払い日時</TableCell>
                <TableCell width="10%">キャンセル日時</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <Fragment key={order.orderID}>
                  <TableRow hover={true}>
                    <TableCell>
                      <Typography noWrap>{order.orderID}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{order.uid}</Typography>
                    </TableCell>
                    <TableCell>
                      {order.items.length > 0 ? (
                        <Link
                          component={RouterLink}
                          to={`/products/${order.items[0].productID}`}
                        >
                          {order.items[0].productName}
                        </Link>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/categories/${order.category.categoryCode}`}
                      >
                        {order.category.categoryName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/currs/${order.curriculum.currCode}`}
                      >
                        {order.curriculum.currName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {format(order.orderTime, "yyyy-MM-dd HH:mm:ss", {
                        locale: ja,
                      })}
                    </TableCell>
                    <TableCell>
                      {order.paidTime
                        ? format(order.paidTime, "yyyy-MM-dd HH:mm:ss", {
                            locale: ja,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {order.cancelTime
                        ? format(order.cancelTime, "yyyy-MM-dd HH:mm:ss", {
                            locale: ja,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={handleClickOpen(order.orderID)}
                        color="primary"
                        size="small"
                        variant="contained"
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                  {id === order.orderID && (
                    <Preview order={order} open onClose={handleClose} />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
