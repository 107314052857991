import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { useState } from "react";
import type { Category, CategoryPatchInput } from "../../../models/category";
import { patchCategory } from "../../../models/category";
import From from "./Form";

export default function Edit({
  category,
  onChange,
}: {
  category: Category;
  onChange: (category: Category) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>("");

  const handleSubmit = (input: CategoryPatchInput) => {
    setIsSaving(true);
    setSaveError("");
    patchCategory(category.categoryCode, input)
      .then(
        () => {
          handleClose();
          let newCategory = category;
          if (input.code) {
            newCategory.categoryCode = input.code;
          }
          if (input.name) {
            newCategory.categoryName = input.name;
          }
          onChange(newCategory);
        },
        (e) => {
          setSaveError(e.message);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Tooltip title="編集">
        <IconButton onClick={handleOpen}>
          <EditIcon color="action" fontSize="small" />
        </IconButton>
      </Tooltip>
      <From
        defaultValues={{
          code: category.categoryCode,
          name: category.categoryName,
        }}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        isSaving={isSaving}
        saveError={saveError}
      />
    </>
  );
}
