import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { Curriculum } from "../../../../models/curriculum";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

export default function Viewer({
  curriculums,
  onClick,
}: {
  curriculums: Curriculum[];
  onClick: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">カリキュラム</Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onClick}
            startIcon={<AddIcon />}
          >
            追加
          </Button>
        </Grid>
      </Grid>
      {curriculums.length > 0 ? (
        curriculums.map(({ currCode, currName }) => (
          <Link to={`/currs/${currCode}`} key={currCode}>
            <Chip className={classes.chip} label={currName} clickable />
          </Link>
        ))
      ) : (
        <Typography color="textSecondary">カリキュラムはありません</Typography>
      )}
    </div>
  );
}
