import { useCallback, useEffect, useState } from "react";
import { getCourseLessons } from "../../../models/course";
import List from "./List";
import SelectLessons from "./SelectLessons";
import Sort from "./Sort";

type LessonSummary = import("../../../models/lesson").LessonSummary;
type mode = "list" | "sort" | "select";

export default function LessonList({
  course,
  editable,
}: {
  course: import("../../../models/course").AdminCourse;
  editable: boolean;
}) {
  const [lessons, setLessons] = useState<LessonSummary[]>([]);
  const load = useCallback(
    async () =>
      getCourseLessons(course.courseCode, course.courseVer).then(setLessons),
    [course]
  );
  useEffect(() => {
    load().catch(console.error).finally(setList);
  }, [load]);

  const [mode, setMode] = useState<mode>("list");
  const setList = () => {
    setMode("list");
  };
  const setSort = () => {
    setMode("sort");
  };
  const setSelect = () => {
    setMode("select");
  };

  if (mode === "select") {
    return (
      <SelectLessons
        course={course}
        lessons={lessons}
        onCancel={setList}
        reload={load}
      />
    );
  }
  if (mode === "sort") {
    return (
      <Sort
        course={course}
        lessons={lessons}
        onCancel={setList}
        reload={load}
      />
    );
  }
  return (
    <List
      course={course}
      lessons={lessons}
      editable={editable}
      onClickSort={setSort}
      onClickSelect={setSelect}
      reload={load}
    />
  );
}
