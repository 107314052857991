import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Fragment, useEffect, useState } from "react";
import {
  BODY_MAX_LEN,
  checkDescription,
  LEAD_MAX_LEN,
  SUBTITLE_MAX_LEN,
} from "../../../../../models/course";

type CourseDesc = import("../../../../../models/course").Description;

export default function Editor({
  desc,
  save,
  onClickCancel,
}: {
  desc: CourseDesc;
  save: (desc: CourseDesc) => void;
  onClickCancel: () => void;
}) {
  const [tmpDesc, setTmpDesc] = useState<CourseDesc>({
    lead: "",
    subtitle: "",
    body: "",
  });
  useEffect(() => {
    setTmpDesc(desc);
  }, [desc]);
  const [error, setError] = useState<{ [K in keyof CourseDesc]?: string }>({});
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name !== "lead" && name !== "subtitle" && name !== "body") {
      return;
    }
    const newVal: CourseDesc = { ...tmpDesc, [name]: value };
    setTmpDesc(newVal);
    setError(checkDescription(newVal));
  };
  const hasError = !!(error.lead || error.subtitle || error.body);
  const handleClickSave = () => {
    if (hasError) {
      return;
    }
    save(tmpDesc);
  };

  return (
    <Fragment>
      <Box my={2}>
        <TextField
          fullWidth
          multiline
          rows={4}
          name="lead"
          onChange={handleChange}
          value={tmpDesc.lead}
          error={!!error.lead}
          helperText={error.lead}
          variant="outlined"
          label="リード文"
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">{`${
            Array.from(tmpDesc.lead).length
          }/${LEAD_MAX_LEN}`}</Typography>
        </Box>
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          name="subtitle"
          onChange={handleChange}
          value={tmpDesc.subtitle}
          error={!!error.subtitle}
          helperText={error.subtitle}
          variant="outlined"
          label="サブタイトル"
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">{`${
            Array.from(tmpDesc.subtitle).length
          }/${SUBTITLE_MAX_LEN}`}</Typography>
        </Box>
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          multiline
          name="body"
          rows={4}
          onChange={handleChange}
          value={tmpDesc.body}
          error={!!error.body}
          helperText={error.body}
          variant="outlined"
          label="本文"
        />
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="body2">{`${
            Array.from(tmpDesc.body).length
          }/${BODY_MAX_LEN}`}</Typography>
        </Box>
      </Box>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClickSave}
            disabled={
              hasError ||
              (desc.lead === tmpDesc.lead &&
                desc.subtitle === tmpDesc.subtitle &&
                desc.body === tmpDesc.body)
            }
          >
            保存
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="default"
            variant="contained"
            size="small"
            onClick={onClickCancel}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
