import { useEffect, useState } from "react";
import { Category, getCategories } from "../../../../models/category";
import { PlayInput } from "../../../../models/play";
import Form from "./Form";

type Props = {
  defaultValues: PlayInput;
  onSave: (data: PlayInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function View(props: Props) {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);

  return <Form selectList={{ categories }} {...props} />;
}
