import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { Feedback, getLessonFeedback } from "../../../../models/lesson";

export default function FeedbackView({ lessonID }: { lessonID: number }) {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [average, setAverage] = useState<number>(0);

  useEffect(() => {
    getLessonFeedback(lessonID).then(({ feedbacks, average }) => {
      setFeedbacks(feedbacks);
      setAverage(average);
    }, console.error);
  }, [lessonID]);

  return (
    <div>
      <Box my={1}>
        <Typography component="span">
          ユーザー評価平均: {Math.round(average * 100) / 100}
        </Typography>
      </Box>
      <div>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="15%">評価</TableCell>
                <TableCell>フィードバック内容</TableCell>
                <TableCell width="20%">作成日時</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbacks.map((row) => (
                <TableRow key={row.feedbackID}>
                  <TableCell>{row.rating}</TableCell>
                  <TableCell>{row.text}</TableCell>
                  <TableCell>
                    {parseISO(row.createTime).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
