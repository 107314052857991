import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import {
  createTeacherAppEmail,
  getTeacherAppEmail,
} from "../../../models/teacher";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(4),
    display: "flex",
    alignItems: "flex-end",
  },
  input: {
    width: 400,
    marginRight: theme.spacing(4),
  },
}));

export default function AccountEmail({ teacherID }: { teacherID: number }) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    setRegistered(false);
    getTeacherAppEmail(teacherID).then((email) => {
      if (email) {
        setRegistered(true);
      }
      setEmail(email);
    }, console.error);
  }, [teacherID]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createTeacherAppEmail(teacherID, email).then(() => {
      setRegistered(true);
    }, console.error);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">講師アプリ アカウント設定</Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.content}>
            <TextField
              value={email}
              onChange={handleChange}
              label="メールアドレス"
              required
              type="email"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.input}
              disabled={registered}
            />
            <Button
              disabled={registered || !email}
              type="submit"
              variant="contained"
              color="primary"
            >
              登録
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}
