import axios from "axios";
import { client } from "../utils/axios";
import { Category, isCategory } from "./category";
import { CloudinaryAudio, isCloudinaryAudio } from "./media";

export type MusicType = {
  musicTypeID: number;
  musicType: string;
};

function isMusicType(x: unknown): x is MusicType {
  return (
    typeof x === "object" &&
    typeof (x as Music).musicTypeID === "number" &&
    typeof (x as Music).musicType === "string"
  );
}

function isMusicTypeArray(xs: unknown): xs is MusicType[] {
  return Array.isArray(xs) && xs.every(isMusicType);
}

export type Music = MusicType & {
  audio: CloudinaryAudio;
};

function isMusic(x: unknown): x is Music {
  return (
    typeof x === "object" &&
    isMusicType(x) &&
    isCloudinaryAudio((x as Music).audio)
  );
}

function isMusicArray(xs: unknown): xs is Music[] {
  return Array.isArray(xs) && xs.every(isMusic);
}

export type MusicScore = {
  url: string;
};

function isMusicScore(x: unknown): x is MusicScore {
  return typeof x === "object" && typeof (x as MusicScore).url === "string";
}

export type Play = {
  playID: number;
  playSlug: string;
  playName: string;
  category: Category;
};

function isPlay(x: unknown): x is Play {
  return (
    typeof x === "object" &&
    typeof (x as Overview).playID === "number" &&
    typeof (x as Overview).playSlug === "string" &&
    typeof (x as Overview).playName === "string" &&
    isCategory((x as Overview).category)
  );
}

export type Overview = Play & {
  musicScores?: MusicScore;
  musics: Music[];
  lessons: number[];
  isPublished: boolean;
  isPublic: boolean;
};

function isLessonArray(xs: unknown): xs is number[] {
  return Array.isArray(xs) && xs.every((x) => typeof x === "number");
}

function isOverview(x: unknown): x is Overview {
  return (
    typeof x === "object" &&
    isPlay(x) &&
    (typeof (x as Overview).musicScores === "undefined" ||
      isMusicScore((x as Overview).musicScores)) &&
    isMusicArray((x as Overview).musics) &&
    isLessonArray((x as Overview).lessons) &&
    typeof (x as Overview).isPublished === "boolean" &&
    typeof (x as Overview).isPublic === "boolean"
  );
}

function isOverviewArray(xs: unknown): xs is Overview[] {
  return Array.isArray(xs) && xs.every(isOverview);
}

export type Cursor = {
  prev?: string;
  next?: string;
};

function isCursor(x: any): x is Cursor {
  return (
    x &&
    (x.prev === undefined || typeof x.prev === "string") &&
    (x.next === undefined || typeof x.next === "string")
  );
}

//
// params
//
export type QueryParams = {
  sortID?: string;
  categoryCode?: string;
};

const SORT_ID = "sortID";
const CATEGORY_CODE = "categoryCode";

export function parseQueryParams(search: string) {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};
  const sortID = params.get(SORT_ID);
  if (sortID) {
    result[SORT_ID] = sortID;
  }
  const code = params.get(CATEGORY_CODE);
  if (code) {
    result[CATEGORY_CODE] = code;
  }
  return result;
}

export function buildQueryParams(params: QueryParams) {
  const p = new URLSearchParams();
  const { sortID, categoryCode } = params;
  if (sortID) {
    p.set(SORT_ID, sortID);
  }
  if (categoryCode) {
    p.set(CATEGORY_CODE, categoryCode);
  }
  return p;
}

//
// plays
//
export async function getPlays(
  params: QueryParams
): Promise<{ plays: Overview[]; cursor: Cursor }> {
  const c = await client();
  const res = await c.get("/plays", { params });
  const { plays, cursor } = res.data;
  if (isOverviewArray(plays) && isCursor(cursor)) {
    return { plays, cursor };
  }
  throw Error("failed to get plays");
}

export type PlayInput = {
  name: string;
  categoryCode: string;
};

export const PLAY_NAME_MAX_LEN = 64;

export async function createPlay(input: PlayInput): Promise<Play> {
  const c = await client();
  const res = await c.post("/plays", input);
  const { data } = res;
  if (isPlay(data)) {
    return data;
  }
  throw Error("failed to create play");
}

export async function getPlay(id: number): Promise<Play> {
  const c = await client();
  const res = await c.get(`/plays/${id}`);
  const { data } = res;
  if (isPlay(data)) {
    return data;
  }
  throw Error("failed to get play");
}

export async function updatePlay(id: number, input: PlayInput): Promise<void> {
  const c = await client();
  await c.patch(`/plays/${id}`, input);
}

export async function deletePlay(id: number): Promise<void> {
  const c = await client();
  await c.delete(`/plays/${id}`);
}

//
// description
//
export async function getDescription(id: number): Promise<string> {
  const c = await client();
  const res = await c.get(`/plays/${id}/descriptions`);
  const { description } = res.data;
  if (typeof description === "string") {
    return description;
  }
  throw Error("failed to get description");
}

export type PlayDescriptionInput = {
  description: string;
};

export const PLAY_DESCRIPTION_MAX_LEN = 1000;

export async function postDescription(
  id: number,
  description: string
): Promise<void> {
  const c = await client();
  await c.post(`/plays/${id}/descriptions`, { description });
}

//
// lessons
//
export async function getLessons(id: number): Promise<number[]> {
  const c = await client();
  const res = await c.get(`/plays/${id}/lessons`);
  const { lessons } = res.data;
  if (isLessonArray(lessons)) {
    return lessons;
  }
  throw Error("failed to get lessons");
}

export async function postLessons(
  id: number,
  lessons: number[]
): Promise<void> {
  const c = await client();
  await c.post(`/plays/${id}/lessons`, { lessons });
}

//
// publish
//
export async function getPublish(id: number): Promise<boolean> {
  const c = await client();
  const res = await c.get(`/plays/${id}/publish`);
  const { isPublished } = res.data;
  if (typeof isPublished === "boolean") {
    return isPublished;
  }
  throw Error("failed to get publish");
}

export async function postPublish(id: number): Promise<void> {
  const c = await client();
  await c.post(`/plays/${id}/publish`);
}

//
// public
//
export async function getPublic(id: number): Promise<boolean> {
  const c = await client();
  const res = await c.get(`/plays/${id}/public`);
  const { isPublic } = res.data;
  if (typeof isPublic === "boolean") {
    return isPublic;
  }
  throw Error("failed to get public");
}

export async function postPublic(id: number, isPublic: boolean): Promise<void> {
  const c = await client();
  await c.post(`/plays/${id}/public`, { isPublic });
}

//
// music score
//
export async function getMusicScore(id: number): Promise<MusicScore> {
  const c = await client();
  const res = await c.get(`/plays/${id}/musicScores`);
  const { data } = res;
  if (isMusicScore(data)) {
    return data;
  }
  throw Error("failed to get publish");
}

export async function postMusicScore(id: number, file: File): Promise<void> {
  const c = await client();
  const form = new FormData();
  form.append("file", file);
  await c.post(`/plays/${id}/musicScores`, form);
}

//
// musics
//
export async function getMusics(id: number): Promise<Music[]> {
  const c = await client();
  const res = await c.get(`/plays/${id}/musics`);
  const { musics } = res.data;
  if (isMusicArray(musics)) {
    return musics;
  }
  throw Error("failed to get musics");
}

export async function deleteAudio(id: number, musicID: number): Promise<void> {
  const c = await client();
  await c.delete(`/plays/${id}/musics/${musicID}/audios`);
}

export async function uploadAudio(
  id: number,
  musicID: number,
  file: Blob,
  cb: (progress: number) => void
): Promise<void> {
  const url = await postAudioURL(id, musicID);

  await axios
    .create({
      timeout: 30_000,
      onUploadProgress: (e) => {
        const progress = Math.round((e.loaded / file.size) * 100);
        cb(progress);
      },
    })
    .put(url, file, { headers: { "Content-Type": file.type } });

  await postAudioUpload(id, musicID);
}

async function postAudioURL(id: number, musicID: number): Promise<string> {
  const c = await client();
  const res = await c.post(`/plays/${id}/musics/${musicID}/audios/url`);
  const { url } = res.data;
  if (typeof url === "string") {
    return url;
  }
  throw Error("failed to post audio url");
}

async function postAudioUpload(
  id: number,
  musicID: number
): Promise<CloudinaryAudio> {
  const c = await client();
  const res = await c.post(`/plays/${id}/musics/${musicID}/audios/upload`);
  const { audio } = res.data;
  if (isCloudinaryAudio(audio)) {
    return audio;
  }
  throw Error("failed to upload the audio");
}

//
// chapters
//
export type Chapter = {
  name: string;
  time: number;
};

function isChapter(x: unknown): x is Chapter {
  return (
    typeof x === "object" &&
    typeof (x as Chapter).name === "string" &&
    typeof (x as Chapter).time === "number"
  );
}

function isChapterArray(xs: unknown): xs is Chapter[] {
  return Array.isArray(xs) && xs.every(isChapter);
}

export async function getAudioChapters(
  id: number,
  musicID: number
): Promise<Chapter[]> {
  const c = await client();
  const res = await c.get(`/plays/${id}/musics/${musicID}/chapters`);
  const { chapters } = res.data;
  if (isChapterArray(chapters)) {
    return chapters;
  }
  throw Error("failed to get chapters");
}

export const CHAPTER_NAME_MAX_LEN = 64;

export async function postAudioChapter(
  id: number,
  musicID: number,
  name: string,
  time: number
): Promise<void> {
  const c = await client();
  await c.post(`/plays/${id}/musics/${musicID}/chapters`, { name, time });
}

export async function deleteAudioChapter(
  id: number,
  musicID: number,
  time: number
): Promise<void> {
  const c = await client();
  await c.delete(`/plays/${id}/musics/${musicID}/chapters/${time}`);
}

//
// musicTypes
//
export async function getMusicTypes(): Promise<MusicType[]> {
  const c = await client();
  const res = await c.get("/musicTypes");
  const { musicTypes } = res.data;
  if (isMusicTypeArray(musicTypes)) {
    return musicTypes;
  }
  throw Error("failed to get musicTypes");
}

export const MUSIC_TYPE_NAME_MAX_LEN = 64;

export type MusicTypeInput = {
  name: string;
};

export async function postMusicType(input: MusicTypeInput): Promise<MusicType> {
  const c = await client();
  const res = await c.post("/musicTypes", { ...input });
  if (isMusicType(res.data)) {
    return res.data;
  }
  throw Error("failed to post musicTypes");
}

export async function patchMusicType(
  id: number,
  input: MusicTypeInput
): Promise<void> {
  const c = await client();
  await c.patch(`/musicTypes/${id}`, { ...input });
}

export async function deleteMusicType(id: number): Promise<void> {
  const c = await client();
  await c.delete(`/musicTypes/${id}`);
}
