import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Code from "./Code";
import Delete from "./Delete";
import General from "./General";
import UserCoupon from "./UserCoupon";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

function parseID(id?: string): number | undefined {
  if (id) {
    const num = parseInt(id, 10);
    if (!isNaN(num)) {
      return num;
    }
  }
  return undefined;
}

export default function Play() {
  const classes = useStyles();

  const { id } = useParams<{
    id?: string;
  }>();
  const couponID = parseID(id);

  if (!couponID) return null;

  return (
    <Container>
      <Paper>
        <Box p={3} mt={3} className={classes.container}>
          <General id={couponID} />
          <Code id={couponID} />
          <UserCoupon id={couponID} />
          <Delete id={couponID} />
        </Box>
      </Paper>
    </Container>
  );
}
