import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  deleteButton: {
    color: "white",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default function Header({
  onClickCreate,
  onClickSelect,
  onClickSort,
  onClickDelete,
  editable,
  canDelete,
  className,
}: {
  onClickCreate: () => void;
  onClickSelect: () => void;
  onClickSort: () => void;
  onClickDelete: () => void;
  editable: boolean;
  canDelete: boolean;
  className?: string;
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="flex-end"
        spacing={3}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onClickCreate}
            disabled={!editable}
          >
            レッスン作成
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onClickSelect}
            disabled={!editable}
          >
            レッスン追加
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onClickSort}
            disabled={!editable}
          >
            並べ替え
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.deleteButton}
            variant="contained"
            onClick={onClickDelete}
            disabled={!canDelete}
          >
            削除
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
