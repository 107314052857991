import * as colors from "@material-ui/core/colors";
import Label from "../Label";

const labels = {
  trial: {
    color: colors.grey[600],
    name: "トライアルコース",
  },
  publication: {
    color: colors.green[600],
    name: "配信コース",
  },
};

export default function TrialCourseLabel({ isTrial }: { isTrial: boolean }) {
  const label = isTrial ? labels.trial : labels.publication;
  return (
    <Label color={label.color} variant="outlined">
      {label.name}
    </Label>
  );
}
