import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Description from "./Description";
import General from "./General";
import Lesson from "./Lesson";
import Music from "./Music";
import Publish from "./Publish";
import Public from "./Public";
import Score from "./Score";
import Delete from "./Delete";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

function parseID(id?: string): number | undefined {
  if (id) {
    const num = parseInt(id, 10);
    if (!isNaN(num)) {
      return num;
    }
  }
  return undefined;
}

export default function Play() {
  const classes = useStyles();

  const { id } = useParams<{
    id?: string;
  }>();
  const playID = parseID(id);

  if (!playID) return null;

  return (
    <Container>
      <Paper>
        <Box p={3} mt={3} className={classes.container}>
          <General id={playID} />
          <Description id={playID} />
          <Lesson id={playID} />
          <Score id={playID} />
          <Music id={playID} />
          <Publish id={playID} />
          <Public id={playID} />
          <Delete id={playID} />
        </Box>
      </Paper>
    </Container>
  );
}
