import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as colors from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import PostCard from "../../../components/PostCard";
import ToggleSwitch from "../../../components/ToggleSwitch";
import {
  checkComment,
  COMMENT_MAX_LEN,
  createComment,
  deleteHidden,
  disableComment,
  enableComment,
  getPost,
  putHidden,
  Submission,
  updateComment,
} from "../../../models/homework";
import { Post } from "../../../models/post";
import { Teacher } from "../../../models/teacher";
import CommentView from "../../Post/Comment";
import Delete from "./Delete";
import DescriptionTable from "./DescriptionTable";
import Reset from "./Reset";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 900,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    closeButton: {
      position: "absolute",
      top: "0",
      right: "0",
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: theme.typography.h5.fontSize,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export default function Preview({
  submission,
  teachers,
  open,
  onClose,
  onChange,
  className,
}: {
  submission: Submission;
  teachers: Teacher[];
  open: boolean;
  onClose: () => void;
  onChange: () => void;
  className?: string;
}) {
  const classes = useStyles();

  const [post, setPost] = useState<Post>();
  useEffect(() => {
    getPost(submission.uid, submission.lesson.lessonID).then(
      setPost,
      console.error
    );
  }, [submission.uid, submission.lesson.lessonID]);

  const [comment, setComment] = useState("");
  const handleChangeComment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const save = (fn: Promise<void>): Promise<void> => {
    return fn.then(
      () => {
        setNotification("✅ 保存しました");
        onChange();
      },
      (e) => {
        setNotification("❗ 保存に失敗しました");
      }
    );
  };

  const [disabledComment, setDisabledComment] = useState(false);
  const handleChangeDisabledComment = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked },
    } = e;
    setDisabledComment(checked);
    const method = checked ? disableComment : enableComment;
    save(method(submission.uid, submission.lesson.lessonID));
  };

  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    setHidden(submission.hidden);
  }, [submission.hidden]);
  const handleChangeHidden = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    setHidden(checked);
    const method = checked ? putHidden : deleteHidden;
    save(method(submission.uid, submission.lesson.lessonID));
  };

  const [notification, setNotification] = useState("");
  const handleCloseNotification = () => {
    setNotification("");
  };
  useEffect(() => {
    if (open) setNotification("");
  }, [open]);

  const [teacherID, setTeacherID] = useState(0);
  const handleChangeTeacherID = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value } = e.target;
    if (typeof value === "number") {
      setTeacherID(value);
    }
  };
  const [error, setError] = useState<{ comment?: string; teacherID?: string }>(
    {}
  );
  useEffect(() => {
    const comment =
      submission.comments.length > 0 ? submission.comments[0] : null;
    if (comment) {
      setComment(comment.comment);
      setTeacherID(comment.teacher.teacherID);
    }
    setError({});
  }, [submission.comments]);
  useEffect(() => {
    setDisabledComment(submission.disabledComment);
  }, [submission.disabledComment]);

  const [processing, setProcessing] = useState(false);

  const teacherComment =
    submission.comments.length > 0 ? submission.comments[0] : null;
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!teacherID) {
      setError({ teacherID: "選択してください" });
      return;
    }
    const msg = checkComment(comment);
    if (msg) {
      setError({ comment: msg });
      return;
    }
    if (!window.confirm("コメントを提出します。よろしいですか？")) {
      return;
    }
    setProcessing(true);
    if (teacherComment) {
      save(
        updateComment(teacherComment.teacherCommentID, teacherID, comment)
      ).finally(() => setProcessing(false));
    } else {
      save(
        createComment(
          submission.uid,
          submission.lesson.lessonID,
          teacherID,
          comment
        )
      ).finally(() => setProcessing(false));
    }
  };
  // 処理中または内容が変わらない場合
  const clickDisabled =
    processing ||
    (!!teacherComment &&
      teacherComment.teacher.teacherID === teacherID &&
      teacherComment.comment === comment);

  const handleDelete = () => {
    onClose();
    onChange();
  };

  return (
    <>
      <Modal onClose={onClose} open={open}>
        <Card className={clsx(classes.root, className)}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon color="action" />
          </IconButton>
          <CardContent>
            <div>
              <Typography className={classes.title}>投稿</Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <DescriptionTable submission={submission} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {post && <PostCard post={post} />}
                </Grid>
              </Grid>
            </div>
            <Divider className={classes.divider} variant="fullWidth" />
            <Grid container>
              <Grid item sm={6} xs={12}>
                <Box my={1}>
                  <Typography className={classes.title}>設定</Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={7}>
                      <Typography component="span">
                        講師によるコメント
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <ToggleSwitch
                        label="無効にする"
                        checked={disabledComment}
                        onChange={handleChangeDisabledComment}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Typography component="span">
                        コミュニティへの非表示
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <ToggleSwitch
                        label="非表示にする"
                        checked={hidden}
                        onChange={handleChangeHidden}
                      />
                    </Grid>
                  </Grid>
                  <Typography className={classes.title}>
                    投稿のリセット
                  </Typography>
                  <Reset submission={submission} onReset={handleDelete} />
                  <Typography className={classes.title}>投稿の削除</Typography>
                  <Delete submission={submission} onDelete={handleDelete} />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Typography className={classes.title}>講師コメント</Typography>
                <form onSubmit={handleSubmit}>
                  <Box my={1}>
                    <FormControl error={!!error.teacherID}>
                      <InputLabel>講師</InputLabel>
                      <Select
                        value={teacherID}
                        onChange={handleChangeTeacherID}
                        autoWidth
                      >
                        <MenuItem value={0}>未選択</MenuItem>
                        {teachers.map((x) => (
                          <MenuItem
                            key={x.teacherID}
                            value={x.teacherID}
                          >{`${x.familyName} ${x.firstName}`}</MenuItem>
                        ))}
                      </Select>
                      {error.teacherID && (
                        <FormHelperText>{error.teacherID}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                  <Box my={1}>
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      onChange={handleChangeComment}
                      value={comment}
                      error={!!error.comment}
                      helperText={error.comment}
                      variant="outlined"
                      label="コメント"
                    />
                  </Box>
                  <Box mt={1} mb={2} display="flex" justifyContent="flex-end">
                    <Typography variant="body2">{`${
                      Array.from(comment).length
                    }/${COMMENT_MAX_LEN}`}</Typography>
                  </Box>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        disabled={clickDisabled}
                        type="submit"
                        className={classes.saveButton}
                        variant="contained"
                      >
                        {teacherComment ? "訂正" : "提出"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <Divider className={classes.divider} variant="fullWidth" />
            <Box my={2}>
              <Typography className={classes.title}>コメント</Typography>
              {post && <CommentView postID={post.postID} />}
            </Box>
          </CardContent>
        </Card>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!notification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        message={notification}
      />
    </>
  );
}
