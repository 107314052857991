import Box from "@material-ui/core/Box";
import * as colors from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import Progress from "../../components/Progress";
import { getLesson, updateLessonName } from "../../models/lesson";
import Header from "./Header";
import Materials from "./Materials";
import Overview from "./Overview";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    tabs: {
      marginTop: theme.spacing(3),
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function LessonDetails() {
  const classes = useStyles();
  const location = useLocation();
  const { lessonID, tab } = useParams<{ lessonID: string; tab?: string }>();
  const history = useHistory();
  const [lesson, setLesson] = useState<
    import("../../models/lesson").Lesson | null
  >(null);
  const loadLesson = useCallback(async () => {
    const id = parseInt(lessonID, 10);
    if (!isNaN(id)) {
      return getLesson(id).then(setLesson);
    }
  }, [lessonID]);
  useEffect(() => {
    loadLesson().catch(console.error);
  }, [loadLesson]);

  if (!lesson) {
    return <Progress />;
  }

  const changeLessonName = (name: string) => {
    const id = parseInt(lessonID, 10);
    return updateLessonName(id, name).then(loadLesson);
  };

  const handleTabsChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (typeof value === "string") {
      history.push(`/lessons/${lessonID}/${value}${location.search}`);
    }
  };

  const tabs = [
    { value: "overview", label: "概要" },
    { value: "materials", label: "教材" },
  ];

  return (
    <div className={classes.root}>
      <Header
        lessonName={lesson.lessonName}
        changeLessonName={changeLessonName}
      />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab || "overview"}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Box display={!tab || tab === "overview" ? "block" : "none"}>
          <Overview lesson={lesson} />
        </Box>
        <Box display={tab === "materials" ? "block" : "none"}>
          <Materials lessonID={lesson.lessonID} />
        </Box>
      </div>
    </div>
  );
}
