import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import type { Page } from "../../models/page";
import {
  buildQueryParams,
  getUsers,
  parseQueryParams,
  QueryParams,
  User,
} from "../../models/user";
import Filter from "./Filter";
import Header from "./Header";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(3),
    },
    hasPrevNext: {
      justifyContent: "space-between",
    },
    hasNext: {
      justifyContent: "flex-end",
    },
  })
);

export default function UserList() {
  const classes = useStyles();

  const { pathname, search } = useLocation();

  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState<Page>({});
  const [params, setParams] = useState<QueryParams>({});

  const load = (params: QueryParams) => {
    getUsers(params)
      .then((res) => {
        setUsers(res.users);
        setPage(res.page);
      })
      .catch(console.error);
  };

  useEffect(() => {
    const params = parseQueryParams(search);
    setParams(params);
    load(params);
  }, [search]);

  const [openFilter, setOpenFilter] = useState(false);
  const handleClickFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const history = useHistory();
  const handleQueryParams = (params: QueryParams) => {
    const p = buildQueryParams(params);
    history.push(`${pathname}?${p.toString()}`);
  };

  const handleNext = () => {
    if (page?.next) {
      handleQueryParams({ ...params, page: page.next });
    }
  };
  const handlePrev = () => {
    if (page?.prev) {
      handleQueryParams({ ...params, page: page.prev });
    }
  };

  const handleFilter = (params: QueryParams) => {
    handleQueryParams(params);
    setOpenFilter(false);
  };

  return (
    <div className={classes.root}>
      <Header onClickFilter={handleClickFilter} />
      <Table className={classes.results} users={users} />
      {page && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: page.prev && page.next,
            [classes.hasNext]: !page.prev && page.next,
          })}
        >
          {page.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {page.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
      <Filter
        open={openFilter}
        onClose={handleCloseFilter}
        params={params}
        onFilter={handleFilter}
      />
    </div>
  );
}
