import Box from "@material-ui/core/Box";
import * as colors from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import Progress from "../../components/Progress";
import { getCourse } from "../../models/course";
import Header from "./Header";
import LessonList from "./LessonList";
import Overview from "./Overview";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    tabs: {
      marginTop: theme.spacing(3),
    },
    divider: {
      backgroundColor: colors.grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function CourseDetail() {
  const classes = useStyles();

  const { code, ver, tab } = useParams<{
    code: string;
    ver: string;
    tab?: string;
  }>();
  const version = parseInt(ver, 10);
  const history = useHistory();
  const [course, setCourse] =
    useState<import("../../models/course").AdminCourse>();
  const load = useCallback(async () => {
    return getCourse(code, version).then(setCourse);
  }, [code, version]);
  useEffect(() => {
    load().catch(console.error);
  }, [load]);

  if (!course) {
    return <Progress />;
  }

  const handleTabsChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (typeof value === "string") {
      history.push(`/courses/${code}/versions/${ver}/${value}`);
    }
  };

  const tabs = [
    { value: "lessons", label: "レッスン" },
    { value: "overview", label: "概要" },
  ];

  return (
    <div className={classes.root}>
      <Header course={course} reload={load} />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab || "lessons"}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Box display={!tab || tab === "lessons" ? "block" : "none"}>
          <LessonList course={course} editable={!course?.published} />
        </Box>
        <Box display={tab === "overview" ? "block" : "none"}>
          <Overview courseCode={code} courseVer={version} />
        </Box>
      </div>
    </div>
  );
}
