import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Progress from "../../../components/Progress/Progress";
import { Curriculum, getCurr } from "../../../models/curriculum";
import Publish from "./Publish";
import Complete from "./Complete";
import Archive from "./Archive";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

export default function CategorySettings() {
  const classes = useStyles();
  const params = useParams<{ currCode: string }>();
  const currCode = params.currCode;

  const [curriculum, setCurriculum] = useState<Curriculum>();
  useEffect(() => {
    getCurr(currCode).then(setCurriculum, console.error);
  }, [currCode]);

  if (!curriculum) return <Progress />;

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <Typography gutterBottom component="h2" variant="h5">
            カリキュラムの公開
          </Typography>
          <Publish currCode={currCode} />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <Typography gutterBottom component="h2" variant="h5">
            カリキュラムの完了
          </Typography>
          <Complete currCode={currCode} />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <Typography gutterBottom component="h2" variant="h5">
            カリキュラムのアーカイブ
          </Typography>
          <Archive currCode={currCode} />
        </Grid>
      </Grid>
    </div>
  );
}
