import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { deleteCouponCode } from "../../../../models/coupon";
import DeleteDialog from "./Dialog";

export default function Delete({
  code,
  onDelete,
}: {
  code: string;
  onDelete: () => void;
}) {
  const [error, setError] = useState<string>();

  const handleDelete = () => {
    deleteCouponCode(code).then(
      () => {
        onDelete();
      },
      (e) => {
        switch (e?.response?.status) {
          default:
            setError("エラーが発生し、削除に失敗しました。");
            break;
        }
      }
    );
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setError("");
  };

  return (
    <div>
      <Tooltip title="削除">
        <IconButton onClick={handleOpen}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <DeleteDialog
        codeName={code}
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
      />
      <Snackbar open={!!error} onClose={handleSnackbarClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
