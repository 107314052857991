import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import TagLabels from "../../../components/TagLabels";
import { validateLessonName } from "../../../models/lesson";
import { useLocation } from "react-router-dom";
import { parseQuery } from "../../../models/tag";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  textField: {
    width: 300,
  },
  labels: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function Header({
  lessonName,
  changeLessonName,
}: {
  lessonName: string;
  changeLessonName: (name: string) => Promise<void>;
}) {
  const classes = useStyles();
  const { search } = useLocation();
  const tag = parseQuery(search);

  const [tmpLessonName, setTmpLessonName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    setTmpLessonName(lessonName);
  }, [lessonName]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTmpLessonName(value);
  };
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = () => {
    const err = validateLessonName(tmpLessonName);
    if (err) {
      setErrorMsg(err);
      return;
    }
    changeLessonName(tmpLessonName).then(toggleEdit).catch(console.error);
  };

  const Label = () => {
    return Object.entries(tag).length > 0 ? (
      <div className={classes.labels}>
        <TagLabels tag={tag} />
      </div>
    ) : null;
  };

  if (edit) {
    return (
      <div>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              レッスン
            </Typography>
            <TextField
              className={classes.textField}
              required
              onChange={handleChange}
              value={tmpLessonName}
              error={!!errorMsg}
              helperText={errorMsg}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              保存
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="default"
              variant="contained"
              onClick={toggleEdit}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>
        <Label />
      </div>
    );
  }
  return (
    <div>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="flex-start"
        spacing={1}
      >
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            レッスン
          </Typography>
          <Typography component="h1" variant="h3">
            {lessonName}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={toggleEdit}
          >
            編集
          </Button>
        </Grid>
      </Grid>
      <Label />
    </div>
  );
}
