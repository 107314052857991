import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getCategoryCurriculums } from "../../../models/category";
import type { Curriculum } from "../../../models/curriculum";
import Editor from "./Editor";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    table: {
      minWidth: 650,
    },
  })
);

export default function CurriculumList({
  categoryCode,
}: {
  categoryCode: string;
}) {
  const classes = useStyles();

  const [curriculums, setCurriculums] = useState<Curriculum[]>([]);
  useEffect(() => {
    getCategoryCurriculums(categoryCode)
      .then(setCurriculums)
      .catch(console.error);
  }, [categoryCode]);

  const [editorOpen, setEditorOpen] = useState(false);
  const handleEditorOpen = () => setEditorOpen(true);
  const handleEditorClose = (added?: Curriculum) => {
    setEditorOpen(false);
    if (added) {
      setCurriculums((c) => [...c, added]);
    }
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={handleEditorOpen}>
          カリキュラムの追加
        </Button>
      </Box>
      <Editor
        categoryCode={categoryCode}
        open={editorOpen}
        onClose={handleEditorClose}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="deadlines">
          <TableHead>
            <TableRow>
              <TableCell>カリキュラム名</TableCell>
              <TableCell>カリキュラムコード</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {curriculums.length > 0 ? (
              curriculums.map((c) => (
                <TableRow key={c.currCode} hover>
                  <TableCell>{c.currName}</TableCell>
                  <TableCell>{c.currCode}</TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/currs/${c.currCode}`}
                      variant="contained"
                      color="primary"
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography component="span" color="textSecondary">
                    カリキュラムがありません
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
