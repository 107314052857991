import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import CreateButton from "./CreateButton";
import Editor from "./Editor";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    table: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function Course({
  currCode,
  courseCodes,
  completed,
}: {
  currCode: string;
  courseCodes: string[];
  completed: boolean;
}) {
  const classes = useStyles();
  const [openEditor, setOpenEditor] = useState<boolean>(false);

  const handleOpen = () => setOpenEditor(true);
  const handleClose = () => setOpenEditor(false);

  return (
    <>
      <Grid alignItems="center" container justifyContent="flex-end" spacing={3}>
        <CreateButton
          currCode={currCode}
          onClick={handleOpen}
          disabled={completed}
        />
      </Grid>
      <Table className={classes.table} courses={courseCodes} />
      <Editor currCode={currCode} open={openEditor} onClose={handleClose} />
    </>
  );
}
