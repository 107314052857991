import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Drawer from "@material-ui/core/Drawer";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import type { Category } from "../../../models/category";
import {
  getCategories,
  getCategoryCurriculums,
} from "../../../models/category";
import type { Curriculum } from "../../../models/curriculum";
import type { QueryParams, Status } from "../../../models/order";
import { STATUS_CANCELED, STATUS_PAID } from "../../../models/order";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    width: 420,
    maxWidth: "100%",
  },
  header: {
    padding: theme.spacing(2, 1),
    display: "flex",
    justifyContent: "space-between",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center",
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  actions: {
    padding: theme.spacing(3),
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const statusList: {
  value: Status;
  label: string;
}[] = [
  { value: STATUS_PAID, label: "支払い済み" },
  { value: STATUS_CANCELED, label: "キャンセル済み" },
];

export default function Filter({
  open,
  onClose,
  onFilter,
  params,
  className,
}: {
  open: boolean;
  onClose: () => void;
  onFilter: (params: QueryParams) => void;
  params: QueryParams;
  className?: string;
}) {
  const classes = useStyles();

  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);
  const [category, setCategory] = useState<Category>();
  useEffect(() => {
    setCategory(categories.find((x) => x.categoryCode === params.categoryCode));
  }, [categories, params.categoryCode]);
  const handleChangeCategory = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;
    if (typeof value === "string") {
      setCategory(categories.find((c) => c.categoryCode === value));
    }
  };

  const [curriculums, setCurriculums] = useState<Curriculum[]>([]);
  useEffect(() => {
    if (!category) return;
    getCategoryCurriculums(category.categoryCode).then(
      setCurriculums,
      console.error
    );
  }, [category]);
  useEffect(() => {
    setCurriculum(undefined); // カテゴリ変更時にカリキュラムの値をクリアする
  }, [category]);
  const [curriculum, setCurriculum] = useState<Curriculum>();
  useEffect(() => {
    setCurriculum(curriculums.find((c) => c.currCode === params.currCode));
  }, [curriculums, params.currCode]);
  const handleChageCurr = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;
    if (typeof value === "string") {
      setCurriculum(curriculums.find((c) => c.currCode === value));
    }
  };

  const [status, setStatus] = useState<Status[]>();
  useEffect(() => {
    setStatus(
      params.status?.filter((s) => !!statusList.find((l) => l.value === s))
    );
  }, [params.status]);
  const handleChangeStatus =
    (statusValue: Status) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      if (checked) {
        setStatus((s) => (s ? [...s, statusValue] : [statusValue]));
      } else {
        setStatus((s) => s && s.filter((x) => x !== statusValue));
      }
    };

  const [uid, setUID] = useState<string>("");
  useEffect(() => {
    if (params.uid) {
      setUID(params.uid);
    }
  }, [params]);
  const handleChangeUID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUID(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFilter({
      categoryCode: category?.categoryCode,
      currCode: curriculum?.currCode,
      status,
      uid,
    });
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="カテゴリ"
                margin="dense"
                onChange={handleChangeCategory}
                select
                SelectProps={{ native: true }}
                value={category ? category.categoryCode : ""}
                variant="outlined"
              >
                <option value="" />
                {categories.map((x) => (
                  <option key={x.categoryCode} value={x.categoryCode}>
                    {x.categoryName}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="カリキュラム"
                margin="dense"
                onChange={handleChageCurr}
                select
                SelectProps={{ native: true }}
                value={curriculum ? curriculum.currCode : ""}
                variant="outlined"
              >
                <option value="" />
                {curriculums.map((x) => (
                  <option key={x.currCode} value={x.currCode}>
                    {x.currName}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.fieldGroup}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">ステータス</FormLabel>
                  <FormGroup>
                    {statusList.map(({ value, label }) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            checked={!!status?.find((s) => s === value)}
                            onChange={handleChangeStatus(value)}
                          />
                        }
                        label={label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <div className={classes.formGroup}>
              <TextField
                label="UID"
                variant="outlined"
                fullWidth
                value={uid}
                onChange={handleChangeUID}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="primary" fullWidth type="submit" variant="contained">
            絞り込む
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
