import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Category, getCategories } from "../../../models/category";
import type { QueryParams } from "../../../models/play";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    width: 420,
    maxWidth: "100%",
  },
  header: {
    padding: theme.spacing(2, 1),
    display: "flex",
    justifyContent: "space-between",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center",
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  actions: {
    padding: theme.spacing(3),
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Filter({
  open,
  onClose,
  onFilter,
  params,
  className,
}: {
  open: boolean;
  onClose: () => void;
  onFilter: (params: QueryParams) => void;
  params: QueryParams;
  className?: string;
}) {
  const classes = useStyles();

  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);
  const [category, setCategory] = useState<Category>();
  useEffect(() => {
    setCategory(categories.find((x) => x.categoryCode === params.categoryCode));
  }, [categories, params]);
  const handleChangeCategory = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as string;
    setCategory(categories.find((c) => c.categoryCode === value));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFilter({
      categoryCode: category?.categoryCode,
    });
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="カテゴリ"
                margin="dense"
                onChange={handleChangeCategory}
                select
                SelectProps={{ native: true }}
                value={category?.categoryCode}
                variant="outlined"
              >
                <option value="" />
                {categories.map((x) => (
                  <option key={x.categoryCode} value={x.categoryCode}>
                    {x.categoryName}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="primary" fullWidth type="submit" variant="contained">
            絞り込む
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
