import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  getProduct,
  patchProduct,
  Product,
  ProductInput,
} from "../../../models/product";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function General({ productID }: { productID: number }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [product, setProduct] = useState<Product>();

  const load = (id: number) => getProduct(id).then(setProduct, console.error);

  useEffect(() => {
    load(productID).finally(() => setLoading(false));
  }, [productID]);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleOnSave = (data: ProductInput) => {
    setIsSaving(true);
    setSaveError("");
    patchProduct(productID, data)
      .then(
        (updated) => {
          handleClose();
          setProduct(updated);
        },
        (e) => {
          setSaveError(e.message);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (loading) return null;

  if (!product) {
    return (
      <Typography component="span" color="textSecondary">
        商品が見つかりません
      </Typography>
    );
  }

  return (
    <div>
      <Viewer product={product} onClick={handleOpen} />
      <Editor
        defaultValues={{
          name: product.productName || "",
          price: product.productPrice || 0,
          stripeProductID: product.stripeProductID || "",
          stripePriceID: product.stripePriceID || "",
        }}
        onSave={handleOnSave}
        onCancel={handleClose}
        isSaving={isSaving}
        saveError={saveError}
        open={open}
      />
    </div>
  );
}
