import { client } from "../utils/axios";
import { isTeacher, Teacher } from "./teacher";

export type TeacherCommentStat = {
  teacher: Teacher;
  commentNum: number;
};

function isTeacherCommentStat(x: unknown): x is TeacherCommentStat {
  return (
    typeof x === "object" &&
    isTeacher((x as TeacherCommentStat).teacher) &&
    typeof (x as TeacherCommentStat).commentNum === "number"
  );
}

function isTeacherCommentStatArray(xs: unknown): xs is TeacherCommentStat[] {
  return Array.isArray(xs) && xs.every((x) => isTeacherCommentStat(x));
}

export async function getTeacherCommentStat(
  from: Date,
  to: Date
): Promise<TeacherCommentStat[]> {
  const c = await client();
  const res = await c.get("/statistics/teachers/comments", {
    params: { from: from.toISOString(), to: to.toISOString() },
  });
  const { statistics } = res.data;
  if (isTeacherCommentStatArray(statistics)) {
    return statistics;
  }
  throw Error("failed to get teacher comment statistics");
}
