import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import ImageSelect from "../../../components/ImageSelect";
import TeacherSelect from "../../../components/TeacherSelect";
import {
  getLessonDesc,
  getLessonImage,
  getLessonMusicalWorkCode,
  getLessonTeacher,
  updateDesc,
  updateImage,
  updateLessonMusicalWorkCode,
  updateTeacher,
} from "../../../models/lesson";
import { getTeachers } from "../../../models/teacher";
import Description from "./Description";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Feedback from "./Feedback";

type Teacher = import("../../../models/teacher").Teacher;
type Lesson = import("../../../models/lesson").Lesson;

export default function LessonOverview({ lesson }: { lesson: Lesson }) {
  const [desc, setDesc] = useState("");
  const changeLessonDesc = (desc: string) =>
    updateDesc(lesson.lessonID, desc)
      .then(() => getLessonDesc(lesson.lessonID))
      .then((x) => setDesc(x.description), console.error);
  useEffect(() => {
    getLessonDesc(lesson.lessonID).then(
      (x) => setDesc(x.description),
      console.error
    );
  }, [lesson.lessonID]);

  const [jasracCode, setJasracCode] = useState("");
  useEffect(() => {
    getLessonMusicalWorkCode(lesson.lessonID).then(
      (x) => setJasracCode(x.jasracWorkCode),
      console.error
    );
  }, [lesson.lessonID]);

  const [nextoneCode, setNextoneCode] = useState("");
  useEffect(() => {
    getLessonMusicalWorkCode(lesson.lessonID).then(
      (x) => setNextoneCode(x.nextoneWorkCode),
      console.error
    );
  }, [lesson.lessonID]);

  const handleSave = () => {
    updateLessonMusicalWorkCode(lesson.lessonID, jasracCode, nextoneCode).catch(
      console.error
    );
  };

  const [image, setImage] =
    useState<import("../../../models/media").CloudinaryImage>();
  const changeImageID = (id: number) =>
    updateImage(lesson.lessonID, id)
      .then(() => getLessonImage(lesson.lessonID))
      .then(setImage);
  useEffect(() => {
    getLessonImage(lesson.lessonID).then(setImage).catch(console.error);
  }, [lesson.lessonID]);

  const [teacher, setTeacher] = useState<Teacher>();
  const saveTeacherID = (ids: number[]) =>
    updateTeacher(lesson.lessonID, ids[0])
      .then(() => getLessonTeacher(lesson.lessonID))
      .then(setTeacher);
  useEffect(() => {
    getLessonTeacher(lesson.lessonID).then(setTeacher).catch(console.error);
  }, [lesson.lessonID]);

  const [teachers, setTeachers] = useState<Teacher[]>([]);
  useEffect(() => {
    getTeachers().then(setTeachers).catch(console.error);
  }, []);

  return (
    <Card>
      <CardContent>
        <Box mb={1}>
          <Typography variant="h5" gutterBottom>
            <b>導入文</b>
          </Typography>
          <Description desc={desc} changeDesc={changeLessonDesc} />
        </Box>
        <Box mb={3}>
          <Typography variant="h5" gutterBottom>
            <b>ヘッダー画像</b>
          </Typography>
          <ImageSelect image={image} saveImageID={changeImageID} />
        </Box>
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            <b>担当講師</b>(1人だけ選択)
          </Typography>
          <TeacherSelect
            selectedTeachers={teacher ? [teacher] : []}
            teachers={teachers}
            save={saveTeacherID}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            <b>作品コード</b>(社内管理)
          </Typography>
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems={"center"}
          >
            <Grid item>
              <TextField
                helperText="JASRAC作品コード"
                variant="outlined"
                value={jasracCode}
                onChange={(event) => setJasracCode(event.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                helperText="NexTone作品コード"
                variant="outlined"
                value={nextoneCode}
                onChange={(event) => setNextoneCode(event.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleSave}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography variant="h5" gutterBottom>
            <b>ユーザーフィードバック</b>(社内確認用)
          </Typography>
          <Feedback lessonID={lesson.lessonID} />
        </Box>
      </CardContent>
    </Card>
  );
}
