import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { CSSProperties, ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    lineHeight: "10px",
    fontSize: "10px",
    height: 20,
    minWidth: 20,
    whiteSpace: "nowrap",
    padding: theme.spacing(0.5, 1),
  },
  rounded: {
    borderRadius: 10,
    padding: theme.spacing(0.5),
  },
}));

export default function Label({
  className,
  variant,
  color,
  shape,
  children,
  style,
}: {
  color: string;
  children: ReactNode;
  className?: string;
  variant?: "outlined" | "contained";
  shape?: "rounded";
  style?: CSSProperties;
}) {
  const classes = useStyles();

  const rootClassName = clsx(
    {
      [classes.root]: true,
      [classes.rounded]: shape === "rounded",
    },
    className
  );

  const finalStyle: CSSProperties = { ...style };

  if (variant === "contained") {
    finalStyle.backgroundColor = color;
    finalStyle.color = "#FFF";
  } else {
    finalStyle.border = `1px solid ${color}`;
    finalStyle.color = color;
  }

  return (
    <Typography className={rootClassName} style={finalStyle} variant="overline">
      {children}
    </Typography>
  );
}
