import firebase from "firebase/app";
import "firebase/auth";

export function onAuthStateChanged(observer: (authenticated: boolean) => void) {
  firebase
    .app()
    .auth()
    .onAuthStateChanged((user) => observer(!!user));
}

export function signOut() {
  firebase.app().auth().signOut().catch(console.error);
}

export function getCurrentUserOrThrow(): firebase.User {
  const u = firebase.auth().currentUser;
  if (u === null) {
    throw Error("user not found");
  }
  return u;
}

export function getUser() {
  const u = getCurrentUserOrThrow();
  return {
    uid: u.uid,
    displayName: u.displayName,
    email: u.email,
    photoURL: u.photoURL,
  };
}
