import { useState, useEffect } from "react";
import { getLessons, postLessons } from "../../../models/play";
import Viewer from "./Viewer";
import Editor from "./Editor";
import { LessonInput } from "./Editor/Form";

export default function Lesson({ id }: { id: number }) {
  const [edit, setEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [lessons, setLessons] = useState<number[]>([]);

  const loadLessons = (id: number) =>
    getLessons(id).then(setLessons, console.error);

  useEffect(() => {
    loadLessons(id).finally(() => setLoading(false));
  }, [id]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleOnSave = (data: LessonInput) => {
    const input = data.lessons.map((x) => parseInt(x, 10));
    setIsSaving(true);
    postLessons(id, input)
      .then(
        () => {
          toggleEdit();
          setLessons(input.sort((a, b) => a - b));
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です。");
              break;
            default:
              setSaveError("エラーが発生し、保存に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (loading) return null;

  return edit ? (
    <Editor
      defaultValues={{
        lessons: lessons.map((x) => x.toString()),
      }}
      onSave={handleOnSave}
      onCancel={toggleEdit}
      isSaving={isSaving}
      saveError={saveError}
    />
  ) : (
    <Viewer lessons={lessons} onClick={toggleEdit} />
  );
}
