export const requiredMessage = "必須項目です";
export const numberPattern = {
  value: /^[0-9]+$/,
  message: "整数値を入力してください",
};
export const timePattern = {
  value: /^(([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?|[0-9]+)$/,
  message: "mm:ssの形式または、秒数を入力してください",
};
export const alphaNumPattern = {
  value: /^[a-zA-Z0-9]+$/,
  message: "半角英数字を入力してください",
};
export const maxLengthRule = (length: number) => ({
  value: length,
  message: `${length} 文字以内で入力してください`,
});
