import { makeStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import { validateLessonName } from "../../models/lesson";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 700,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    container: {
      marginTop: theme.spacing(3),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
  })
);

export default function LessonCreateForm({
  open,
  onClose,
  save,
  reload,
  className,
}: {
  open: boolean;
  onClose: () => void;
  save: (lessonName: string) => Promise<void>;
  reload: () => Promise<void>;
  className?: string;
}) {
  const classes = useStyles();

  const [lessonName, setLessonName] = useState("");
  const handleFieldChange = (
    e: import("react").ChangeEvent<HTMLInputElement>
  ) => {
    setLessonName(e.target.value);
  };
  const [error, setError] = useState<{ lessonName?: string }>({});
  const [uploading, setUploading] = useState(false);
  const handleSave = () => {
    const err = validateLessonName(lessonName);
    if (err) {
      setError({ lessonName: err });
      return;
    }
    setError({});
    setUploading(true);
    save(lessonName)
      .then(reload)
      .then(onClose)
      .then(() => setLessonName(""), console.error)
      .finally(() => {
        setUploading(false);
      });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            レッスン追加
          </Typography>
          <Grid className={classes.container} container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                label="レッスンタイトル"
                onChange={handleFieldChange}
                value={lessonName}
                error={!!error.lessonName}
                helperText={error.lessonName}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button onClick={onClose} variant="contained">
            閉じる
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleSave}
            disabled={uploading}
            variant="contained"
          >
            新規作成
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
}
