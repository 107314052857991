import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import CloudinaryImage from "../../../components/CloudinaryImage";
import { createTeacherImage } from "../../../models/teacher";
import { makeStyles } from "@material-ui/core/styles";

type Image = import("../../../models/media").CloudinaryImage;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  image: {
    width: 160,
  },
}));

export default function AvatarEditor({
  teacher,
  onChange,
}: {
  teacher: import("../../../models/teacher").Teacher;
  onChange: (img: Image) => void;
}) {
  const classes = useStyles();

  const [image, setImage] = useState<Image>();
  useEffect(() => {
    setImage(teacher.avatar);
  }, [teacher.avatar]);

  const [uploading, setUploading] = useState(false);
  const handleUpload = () => {
    if (!image || uploading) {
      return;
    }
    setUploading(true);
    createTeacherImage(teacher.teacherID, image.cldnImageID)
      .then(() => onChange(image), console.error)
      .finally(() => setUploading(false));
  };

  const noChange = image?.cldnImageID === teacher?.avatar?.cldnImageID;

  return (
    <div className={classes.root}>
      <div className={classes.image}>
        <CloudinaryImage
          image={image}
          onChangeImage={setImage}
          aspectRatio={1}
        />
      </div>
      <Button
        onClick={handleUpload}
        variant="contained"
        color="primary"
        disabled={!image || uploading || noChange}
      >
        更新
      </Button>
    </div>
  );
}
