import { Button } from "@material-ui/core";
import { useState } from "react";
import {
  cancelSubscription,
  Subscription,
} from "../../../../models/subscriptions";
import CancelDialog from "./CancelDialog";

export default function Cancel({
  subscription,
  disabled: forceDisabled,
  onCancel,
}: {
  subscription: Subscription;
  disabled?: boolean;
  onCancel?: () => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleCancel = () => {
    setOpen(false);
    setSubmitting(true);
    cancelSubscription(subscription.subscriptionID).then(
      () => {
        if (onCancel) {
          onCancel();
        }
      },
      (e) => {
        setSubmitting(false);
        console.error(e);
      }
    );
  };

  let disabled: boolean;
  let label: string;
  if (subscription.completed) {
    label = "修了済";
    disabled = true;
  } else if (subscription.cancel) {
    label = "キャンセル済";
    disabled = true;
  } else {
    label = "キャンセル";
    disabled = false;
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        disabled={forceDisabled || submitting || disabled}
      >
        {label}
      </Button>
      <CancelDialog open={open} onClose={handleClose} onClick={handleCancel} />
    </>
  );
}
