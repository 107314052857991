import Button from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import Alert from "../../../components/Alert";
import CourseStatusLabel from "../../../components/CourseStatusLabel";
import TagLabels from "../../../components/TagLabels";
import {
  updateName,
  publishCourse,
  validateName,
} from "../../../models/course";
import { Link as RouterLink } from "react-router-dom";

type Course = import("../../../models/course").AdminCourse;

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  textField: {
    width: 300,
  },
  statusButton: {
    color: "white",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  space: {
    flexGrow: 1,
  },
  labels: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function Header({
  course,
  reload,
}: {
  course: Course;
  reload: () => Promise<void>;
}) {
  const classes = useStyles();

  const [tmpName, setTmpName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    setTmpName(course.courseName);
  }, [course.courseName]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTmpName(value);
  };
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const [snackbar, setSnackbar] = useState("");
  const handleSnackbarClose = () => {
    setSnackbar("");
  };

  const handleSaveName = () => {
    const errMsg = validateName(tmpName);
    if (errMsg) {
      setErrorMsg(errMsg);
      return;
    }
    updateName(course.courseCode, course.courseVer, tmpName)
      .then(reload)
      .then(toggleEdit)
      .catch(console.error);
  };

  const handleClickPublish = () => {
    if (
      window.confirm(
        `「${course.courseName}」コースを公開します。よろしいですか？`
      )
    ) {
      publishCourse(course.courseCode, course.courseVer)
        .then(reload)
        .catch((e: import("axios").AxiosError) => {
          if (e.response && e.response.status === 404) {
            setSnackbar("コースを公開するには概要を入力してください");
          } else {
            console.error(e);
          }
        });
    }
  };

  const Labels = () => {
    const tag: import("../../../models/tag").Tag = {
      categoryCode: course.categoryCode,
    };
    return (
      <div className={classes.labels}>
        <CourseStatusLabel published={course.published} />
        <TagLabels tag={tag} />
      </div>
    );
  };

  if (edit) {
    return (
      <div>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item>
            <Typography component="h2" gutterBottom variant="overline">
              コース
            </Typography>
            <TextField
              className={classes.textField}
              required
              onChange={handleChange}
              value={tmpName}
              error={!!errorMsg}
              helperText={errorMsg}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSaveName}
            >
              保存
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="default"
              variant="contained"
              onClick={toggleEdit}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>
        <Labels />
      </div>
    );
  }
  return (
    <div>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="flex-start"
        spacing={1}
      >
        <Grid item>
          <Typography component="h2" gutterBottom variant="caption">
            {"コース > "}
            <Link component={RouterLink} to={`/courses/${course.courseCode}`}>
              {course.courseCode}
            </Link>
            {` > ver. ${course.courseVer}`}
          </Typography>
          <Typography component="h1" variant="h3">
            {course.courseName}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={toggleEdit}
          >
            編集
          </Button>
        </Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Button
            className={classes.statusButton}
            variant="contained"
            onClick={handleClickPublish}
            disabled={course.published}
          >
            {course.published ? "公開中" : "公開する"}
          </Button>
        </Grid>
      </Grid>
      <Labels />
      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbar}
        </Alert>
      </Snackbar>
    </div>
  );
}
