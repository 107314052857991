import { Box } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useEffect, useState } from "react";
import { AdminComment } from "../../../models/comment";
import { Page } from "../../../models/page";
import {
  getUserAdminComments,
  postUserAdminComment,
} from "../../../models/user";
import { Form, FormValues } from "./Form";
import Thread from "./Thread";

export default function CommentView({ uid }: { uid: string }) {
  const [comments, setComments] = useState<AdminComment[]>([]);
  const [page, setPage] = useState<Page>({});

  useEffect(() => {
    getUserAdminComments(uid).then(({ comments, page }) => {
      setComments(comments);
      setPage(page);
    }, console.error);
  }, [uid]);

  const handleSubmit = async (data: FormValues) => {
    try {
      const newComment = await postUserAdminComment(uid, data.comment);
      setComments([newComment, ...comments]);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const fetchMore = () => {
    if (page?.next) {
      getUserAdminComments(uid, page.next).then(({ comments, page }) => {
        setComments((c) => [...c, ...comments]);
        setPage(page);
      }, console.error);
    }
  };

  return (
    <div>
      {page.next && (
        <Box p={1} display="flex" justifyContent="center">
          <Link component="button" onClick={fetchMore}>
            さらに読み込む
          </Link>
        </Box>
      )}
      <Thread comments={comments} />
      <Box mt={2}>
        <Form onSubmit={handleSubmit} />
      </Box>
    </div>
  );
}
