import { useEffect, useState } from "react";
import {
  deleteAudioChapter,
  getAudioChapters,
  Music,
  postAudioChapter,
} from "../../../models/play";
import { parseTime } from "../../../utils/time";
import Chapter, { ChapterInput } from "./Chapter";
import Song from "./Song";

type Props = {
  playID?: number;
  musicID?: number;
  music?: Music;
  isUploading: boolean;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
};

export default function SongView(props: Props) {
  const { playID, musicID, music } = props;

  const [chapters, setChapters] = useState<
    import("../../../models/play").Chapter[]
  >([]);
  useEffect(() => {
    if (playID && musicID) {
      setChapters([]);
      getAudioChapters(playID, musicID).then(setChapters, console.error);
    }
  }, [playID, musicID, music]);

  const [saveError, setSaveError] = useState<string>();
  const handleSaveChapter = ({ name, time }: ChapterInput) => {
    if (!playID || !musicID) return;
    setSaveError("");
    const sec = parseTime(time);
    postAudioChapter(playID, musicID, name, sec).then(
      () => {
        setChapters((c) =>
          [...c, { name, time: sec }].sort((a, b) => a.time - b.time)
        );
      },
      (e) => {
        switch (e?.response?.status) {
          case 400:
            setSaveError(
              "不正な入力です。入力を確認して再度入力してください。"
            );
            break;
          case 409:
            setSaveError(
              "すでに同じ時間にタグの登録が存在します。同じ時間にタグを登録するには、該当のタグを削除してください。"
            );
            break;
          default:
            setSaveError("エラーが発生し、追加に失敗しました。");
            break;
        }
      }
    );
  };

  const handleDeleteChapter = (time: number) => {
    if (!playID || !musicID) return;
    deleteAudioChapter(playID, musicID, time).then(() => {
      setChapters((c) => c.filter((x) => x.time !== time));
    }, console.error);
  };

  return (
    <Song
      {...props}
      render={({ jump }) => (
        <Chapter
          chapters={chapters}
          onClick={jump}
          onSave={handleSaveChapter}
          onDelete={handleDeleteChapter}
          saveError={saveError}
        />
      )}
    />
  );
}
