import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { getDeadlines } from "../../models/deadline";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
    table: {
      minWidth: 650,
    },
    outOfDate: {
      color: "red",
    },
  })
);

export default function Deadline() {
  const classes = useStyles();

  const [deadlines, setDeadlines] = useState<
    import("../../models/deadline").Deadline[]
  >([]);
  useEffect(() => {
    getDeadlines().then(setDeadlines, console.error);
  }, []);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4">
        入稿期限
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="deadlines">
          <TableHead>
            <TableRow>
              <TableCell>カテゴリ名</TableCell>
              <TableCell>カリキュラム名</TableCell>
              <TableCell>締め切り</TableCell>
              <TableCell>完了済み</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deadlines.map(({ category, curriculum, deadline, completed }) => (
              <TableRow key={curriculum.currCode}>
                <TableCell component="th" scope="row">
                  {category.categoryName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {curriculum.currName}
                </TableCell>
                <TableCell
                  className={clsx(
                    deadline &&
                      deadline.getTime() < new Date().getTime() &&
                      classes.outOfDate
                  )}
                >
                  {deadline
                    ? Intl.DateTimeFormat("ja-JP", {
                        timeZone: "Asia/Tokyo",
                      }).format(deadline)
                    : "-"}
                </TableCell>
                <TableCell>{completed ? "はい" : "いいえ"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
