import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function CompleteDialog({
  open,
  onClose,
  onComplete,
}: {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>カリキュラム完了の確認</DialogTitle>
      <DialogContent>
        <DialogContentText color="error">
          カリキュラムを完了にしてもよろしいですか？
          <br />
          一度カリキュラムを完了にすると、完了前の状態に戻すことはできません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onComplete} color="default">
          カリキュラムを完了にする
        </Button>
      </DialogActions>
    </Dialog>
  );
}
