import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    space: {
      flexGrow: 1,
    },
  })
);

export default function Header({
  onClickAdd,
  onClickFilter,
  className,
}: {
  onClickAdd: () => void;
  onClickFilter: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            演奏
          </Typography>
        </Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Button color="primary" variant="contained" onClick={onClickAdd}>
            演奏を追加
          </Button>
        </Grid>
        <Grid item>
          <Tooltip title="絞り込み">
            <IconButton onClick={onClickFilter}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
}
