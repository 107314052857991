import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { CHAPTER_NAME_MAX_LEN } from "../../../models/play";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  maxLengthRule,
  requiredMessage,
  timePattern,
} from "../../../utils/form";
import { formatTime } from "../../../utils/time";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    inputArea: {
      verticalAlign: "top",
    },
  })
);

export type ChapterInput = {
  name: string;
  time: string;
};

export default function Chapter({
  chapters,
  onClick,
  onSave,
  onDelete,
  saveError,
}: {
  chapters: import("../../../models/play").Chapter[];
  onClick: (time: number) => void;
  onSave: (input: ChapterInput) => void;
  onDelete: (time: number) => void;
  saveError?: string;
}) {
  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm<ChapterInput>({
    defaultValues: { name: "", time: "" },
  });

  useEffect(() => {
    reset();
  }, [reset, chapters]);

  return (
    <Box>
      <Typography gutterBottom>タグ</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="45%">名前</TableCell>
              <TableCell width="45%">時間</TableCell>
              <TableCell width="10%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {chapters.map(({ name, time }) => (
              <TableRow key={time}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  <Link component="button" onClick={() => onClick(time)}>
                    {formatTime(time)}
                  </Link>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => onDelete(time)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell className={classes.inputArea}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    maxLength: maxLengthRule(CHAPTER_NAME_MAX_LEN),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      placeholder="タグ名"
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </TableCell>
              <TableCell className={classes.inputArea}>
                <Controller
                  name="time"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    pattern: timePattern,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      placeholder="0:00"
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(onSave)}
                >
                  追加
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {saveError && <Typography color="error">{saveError}</Typography>}
    </Box>
  );
}
