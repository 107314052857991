import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  space: {
    flexGrow: 1,
  },
}));

export default function Header({
  className,
  onClickEditor,
}: {
  className?: string;
  onClickEditor: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            クーポン
          </Typography>
        </Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Button color="primary" variant="contained" onClick={onClickEditor}>
            クーポン追加
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
