export type Tag = {
  categoryCode?: string;
  lessonOrder?: number;
};

const categoryCodeKey = "categoryCode";
const lessonOrderKey = "lessonOrder";

export function parseQuery(query: string) {
  const params = new URLSearchParams(query);
  const tag: Tag = {};
  const categoryCode = params.get(categoryCodeKey);
  if (categoryCode) {
    tag.categoryCode = categoryCode;
  }
  const lessonOrder = params.get(lessonOrderKey);
  if (lessonOrder && !isNaN(parseInt(lessonOrder))) {
    tag.lessonOrder = parseInt(lessonOrder);
  }
  return tag;
}

export function buildQuery(tag: Tag) {
  const params = new URLSearchParams();
  tag.categoryCode && params.set(categoryCodeKey, tag.categoryCode);
  tag.lessonOrder !== undefined &&
    params.set(lessonOrderKey, tag.lessonOrder.toString());
  return params.toString();
}
