import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { CloudinaryImage, getURL } from "../../models/media";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingTop: "56.25%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export default function Image({
  className,
  image,
  alt,
}: {
  className?: string;
  image: CloudinaryImage;
  alt: string;
}) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} key={image.cldnImageID}>
      <img className={classes.image} src={getURL(image)} alt={alt} />
    </div>
  );
}
