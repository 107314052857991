import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Link as RouterLink } from "react-router-dom";
import ProviderIcon from "../../../components/ProviderIcon";
import { User } from "../../../models/user";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  users,
}: {
  className?: string;
  users: User[];
}) {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="25%">UID</TableCell>
                <TableCell width="25%">メールアドレス</TableCell>
                <TableCell width="10%">プロバイダ</TableCell>
                <TableCell width="10%">アカウント作成日時</TableCell>
                <TableCell width="10%">最終ログイン日時</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow hover={true} key={user.uid}>
                  <TableCell>
                    <Typography noWrap>{user.uid}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{user.email || "-"}</Typography>
                  </TableCell>
                  <TableCell>
                    {Array.from(new Set(user.providers)).map((p) => (
                      <ProviderIcon key={p} provider={p} />
                    ))}
                  </TableCell>
                  <TableCell>
                    {format(
                      user.userMetadata.creationTime,
                      "yyyy-MM-dd HH:mm:ss",
                      {
                        locale: ja,
                      }
                    )}
                  </TableCell>
                  <TableCell>
                    {format(
                      user.userMetadata.lastLogInTime,
                      "yyyy-MM-dd HH:mm:ss",
                      {
                        locale: ja,
                      }
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/users/${user.uid}`}
                      color="primary"
                      size="small"
                      variant="contained"
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
