import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import Snackbar from "@material-ui/core/Snackbar";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import type { Submission } from "../../../../models/homework";
import { resetSubmission } from "../../../../models/homework";
import ResetDialog from "./Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  redButton: {
    color: "white",
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[900],
    },
  },
}));

export default function Reset({
  submission,
  onReset,
}: {
  submission: Submission;
  onReset: () => void;
}) {
  const classes = useStyles();
  const [error, setError] = useState<string>();

  const handleReset = () => {
    resetSubmission(submission.uid, submission.lesson.lessonID)
      .then(
        () => {
          onReset();
        },
        (e) => {
          switch (e?.response?.status) {
            default:
              setError("エラーが発生し、リセットに失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setOpen(false);
      });
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setError("");
  };

  return (
    <>
      <Button
        className={classes.redButton}
        variant="contained"
        onClick={handleClick}
      >
        リセットする
      </Button>
      <ResetDialog open={open} onClose={handleClose} onReset={handleReset} />
      <Snackbar open={!!error} onClose={handleSnackbarClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
}
