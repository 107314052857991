import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import type { Category } from "./category";
import { isCategory } from "./category";
import type { Curriculum } from "./curriculum";
import { isCurriculum } from "./curriculum";
import type { Page, QueryParams as PageQueryParams } from "./page";
import {
  buildQueryParams as pageBuildQueryParams,
  isPage,
  parseQueryParams as pageParseQueryParams,
} from "./page";
import { typeFormatFromArray } from "./utils";

export type Item = {
  productID: number;
  productName: string;
  productPrice: number;
};

function isItem(x: unknown): x is Item {
  return (
    typeof x === "object" &&
    typeof (x as Item).productID === "number" &&
    typeof (x as Item).productName === "string" &&
    typeof (x as Item).productPrice === "number"
  );
}

function isItemArray(xs: unknown): xs is Item[] {
  return Array.isArray(xs) && xs.every(isItem);
}

export type Order = {
  orderID: number;
  uid: string;
  totalPrice: number;
  orderTime: Date;
  stripeInvoiceID: string;
  items: Item[];
  curriculum: Curriculum;
  category: Category;
  cancelTime?: Date;
  paidTime?: Date;
};

function isOrder(x: unknown): x is Order {
  return (
    typeof x === "object" &&
    typeof (x as Order).orderID === "number" &&
    typeof (x as Order).uid === "string" &&
    typeof (x as Order).totalPrice === "number" &&
    (x as Order).orderTime instanceof Date &&
    typeof (x as Order).stripeInvoiceID === "string" &&
    isItemArray((x as Order).items) &&
    isCurriculum((x as Order).curriculum) &&
    isCategory((x as Order).category) &&
    (typeof (x as Order).cancelTime === "undefined" ||
      (x as Order).cancelTime instanceof Date) &&
    (typeof (x as Order).paidTime === "undefined" ||
      (x as Order).paidTime instanceof Date)
  );
}

export function formatOrder(x: unknown): Order | null {
  if (typeof x !== "object") {
    return null;
  }
  const { orderTime, cancelTime, paidTime } = x as Order;
  if (typeof orderTime !== "string") {
    return null;
  }
  if (typeof cancelTime !== "undefined" && typeof cancelTime !== "string") {
    return null;
  }
  if (typeof paidTime !== "undefined" && typeof paidTime !== "string") {
    return null;
  }
  const ret = {
    ...x,
    orderTime: parseISO(orderTime),
    cancelTime: cancelTime && parseISO(cancelTime),
    paidTime: paidTime && parseISO(paidTime),
  };
  if (!isOrder(ret)) {
    return null;
  }
  return ret;
}

export const STATUS_CANCELED = "canceled";
export const STATUS_PAID = "paid";

const statusList: Status[] = [STATUS_CANCELED, STATUS_PAID];

export type Status = typeof STATUS_CANCELED | typeof STATUS_PAID;

export type QueryParams = {
  currCode?: string;
  categoryCode?: string;
  status?: Status[];
  uid?: string;
} & PageQueryParams;

const CURR_CODE = "currCode";
const CATEGORY_CODE = "categoryCode";
const STATUS = "status[]";
const UID = "uid";

export function parseQueryParams(search: string): QueryParams {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};

  const currCode = params.get(CURR_CODE);
  if (currCode) {
    result[CURR_CODE] = currCode;
  }
  const categoryCode = params.get(CATEGORY_CODE);
  if (categoryCode) {
    result[CATEGORY_CODE] = categoryCode;
  }
  const status = params.getAll(STATUS);
  if (status.length > 0) {
    result.status = statusList.filter((s) => status.includes(s));
  }
  const uid = params.get(UID);
  if (uid) {
    result[UID] = uid;
  }

  const page = pageParseQueryParams(search);

  return { ...result, ...page };
}

export function buildQueryParams(params: QueryParams): URLSearchParams {
  const init: string[][] = [];

  const p = new URLSearchParams();
  const { currCode, categoryCode, status, uid } = params;
  if (currCode) {
    p.set(CURR_CODE, currCode);
  }
  if (categoryCode) {
    p.set(CATEGORY_CODE, categoryCode);
  }
  if (uid) {
    p.set(UID, uid);
  }
  init.push(...Array.from(p));

  if (status) {
    init.push(...status.map((s) => [STATUS, s]));
  }

  const pp = pageBuildQueryParams(params);
  init.push(...Array.from(pp));

  return new URLSearchParams(init);
}

export async function getOrders(
  params: QueryParams
): Promise<{ orders: Order[]; page: Page }> {
  const c = await client();
  const { data } = await c.get("/orders", { params });
  const { orders: rawOrders, page } = data;
  const orders = typeFormatFromArray<Order>(rawOrders, formatOrder);
  if (orders && isPage(page)) {
    return { orders, page };
  }
  throw new Error(`invalid response: ${JSON.stringify(data)}`);
}
