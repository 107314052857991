import { makeStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { createTeacher, validate } from "../../../models/teacher";
import { useState } from "react";

type Teacher = import("../../../models/teacher").Teacher;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 700,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    container: {
      marginTop: theme.spacing(3),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
  })
);

const initTeacher: Teacher = {
  teacherID: 0,
  familyName: "",
  firstName: "",
  familyNameEn: "",
  firstNameEn: "",
};

export default function Form({
  open,
  onClose,
  reload,
  className,
}: {
  open: boolean;
  onClose: () => void;
  reload: () => Promise<void>;
  className?: string;
}) {
  const classes = useStyles();

  const [formState, setFormState] = useState<Teacher>(initTeacher);
  const handleFieldChange = (
    event: import("react").ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >
  ) => {
    event.persist();
    const { name, value } = event.target;
    if (
      name === "familyName" ||
      name === "firstName" ||
      name === "familyNameEn" ||
      name === "firstNameEn"
    ) {
      if (typeof value === "string") {
        setFormState((formState) => ({
          ...formState,
          [name]: value,
        }));
      }
    }
  };
  const [error, setError] = useState<
    import("../../../models/teacher").ValidationError
  >({});
  const [saving, setSaving] = useState(false);
  const handleSave = () => {
    const e = validate(formState);
    if (e) {
      setError(e);
      return;
    }
    setError({});
    setSaving(true);
    createTeacher(formState)
      .catch((e) => {
        window.alert("保存中にエラーが発生しました");
        console.error(e);
      })
      .then(reload)
      .then(onClose)
      .then(() => {
        setFormState(initTeacher);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <form>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              講師追加
            </Typography>
            <Grid className={classes.container} container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="姓"
                  name="familyName"
                  onChange={handleFieldChange}
                  value={formState.familyName}
                  error={!!error.familyName}
                  helperText={error.familyName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="名"
                  name="firstName"
                  onChange={handleFieldChange}
                  value={formState.firstName}
                  error={!!error.firstName}
                  helperText={error.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="姓(英字)"
                  name="familyNameEn"
                  onChange={handleFieldChange}
                  value={formState.familyNameEn}
                  error={!!error.familyNameEn}
                  helperText={error.familyNameEn}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  required
                  label="名(英字)"
                  name="firstNameEn"
                  onChange={handleFieldChange}
                  value={formState.firstNameEn}
                  error={!!error.firstNameEn}
                  helperText={error.firstNameEn}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onClose} variant="contained">
              閉じる
            </Button>
            <Button
              className={classes.saveButton}
              onClick={handleSave}
              disabled={saving}
              variant="contained"
            >
              {"新規作成"}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
