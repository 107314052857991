import { makeStyles } from "@material-ui/core/styles";
import type { CloudinaryImage } from "../../models/media";
import Image from "./Image";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
  },
  item1: {
    gridArea: "1 / 1 / 3 / 3",
  },
  item21: {
    gridArea: "1 / 1 / 3 / 2",
  },
  item22: {
    gridArea: "1 / 2 / 3 / 3",
  },
  item31: {
    gridArea: "1 / 1 / 2 / 2",
  },
  item32: {
    gridArea: "2 / 1 / 3 / 2",
  },
  item33: {
    gridArea: "1 / 2 / 3 / 3",
  },
  item41: {
    gridArea: "1 / 1 / 2 / 2",
  },
  item42: {
    gridArea: "1 / 2 / 2 / 3",
  },
  item43: {
    gridArea: "2 / 1 / 3 / 2",
  },
  item44: {
    gridArea: "2 / 2 / 3 / 3",
  },
}));

export default function MediaImage({ images }: { images: CloudinaryImage[] }) {
  const classes = useStyles();

  if (images.length === 1) {
    return (
      <div className={classes.root}>
        <Image className={classes.item1} image={images[0]} alt="画像1" />
      </div>
    );
  }
  if (images.length === 2) {
    return (
      <div className={classes.root}>
        <Image className={classes.item21} image={images[0]} alt="画像1" />
        <Image className={classes.item22} image={images[1]} alt="画像2" />
      </div>
    );
  }
  if (images.length === 3) {
    return (
      <div className={classes.root}>
        <Image className={classes.item31} image={images[0]} alt="画像1" />
        <Image className={classes.item32} image={images[1]} alt="画像2" />
        <Image className={classes.item33} image={images[2]} alt="画像3" />
      </div>
    );
  }
  if (images.length === 4) {
    return (
      <div className={classes.root}>
        <Image className={classes.item41} image={images[0]} alt="画像1" />
        <Image className={classes.item42} image={images[1]} alt="画像2" />
        <Image className={classes.item43} image={images[2]} alt="画像3" />
        <Image className={classes.item44} image={images[3]} alt="画像4" />
      </div>
    );
  }

  return null;
}
