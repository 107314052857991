import Button from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Fragment } from "react";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  text: {
    border: `0.5px solid ${colors.grey[300]}`,
    borderRadius: 8,
    height: "8rem",
    overflow: "auto",
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  singleLine: {
    height: "3rem",
  },
  multiline: {
    height: "8rem",
  },
}));

export default function Viewer({
  desc,
  onClick,
  disableEdit,
}: {
  desc: import("../../../../../models/course").Description;
  onClick: () => void;
  disableEdit?: boolean;
}) {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography>リード文</Typography>
      <Typography
        className={clsx(classes.text, classes.multiline)}
        variant="subtitle1"
        component="pre"
      >
        {desc.lead}
      </Typography>
      <Typography>サブタイトル</Typography>
      <Typography
        className={clsx(classes.text, classes.singleLine)}
        variant="subtitle1"
        component="pre"
      >
        {desc.subtitle}
      </Typography>
      <Typography>本文</Typography>
      <Typography
        className={clsx(classes.text, classes.multiline)}
        variant="subtitle1"
        component="pre"
      >
        {desc.body}
      </Typography>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={onClick}
            disabled={disableEdit}
          >
            編集
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
