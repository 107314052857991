import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import type { Product } from "../../../models/product";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
}));

export default function Table({
  className,
  products,
}: {
  className?: string;
  products: Product[];
}) {
  const classes = useStyles();

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>商品名</TableCell>
                <TableCell>価格</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((x) => (
                <TableRow hover={true} key={x.productID}>
                  <TableCell>
                    <Typography>{x.productID}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{x.productName}</Typography>
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("ja-JP", {
                      style: "currency",
                      currency: "JPY",
                    }).format(x.productPrice)}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component={RouterLink}
                      to={`/products/${x.productID}`}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
