import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import GoogleIcon from "@material-ui/icons/Google";

export default function ProviderIcon({ provider }: { provider: string }) {
  switch (provider) {
    case "password":
      return <EmailIcon color="action" />;
    case "google.com":
      return <GoogleIcon color="action" />;
    case "facebook.com":
      return <FacebookIcon color="action" />;
    default:
      throw new Error("Unknown provider");
  }
}
