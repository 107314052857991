import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { deleteCategoryPlan } from "../../../../models/category";
import type { Plan } from "../../../../models/plan";
import DeleteDialog from "./Dialog";

export default function Delete({
  categoryCode,
  plan,
  onDelete,
}: {
  categoryCode: string;
  plan: Plan;
  onDelete: () => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [error, setError] = useState<string>("");
  const handleDelete = () => {
    deleteCategoryPlan(categoryCode, plan.planID)
      .then(
        () => {
          onDelete();
        },
        (e) => {
          switch (e?.response?.status) {
            case 422:
              setError(
                "このプランはユーザによって使用されているため、削除できません"
              );
              return;
            default:
              setError("エラーが発生したため、削除に失敗しました。");
              return;
          }
        }
      )
      .finally(() => handleClose());
  };

  const handleSnackbarClose = () => setError("");

  return (
    <>
      <Tooltip title="削除">
        <IconButton onClick={handleOpen}>
          <DeleteIcon color="action" />
        </IconButton>
      </Tooltip>
      <DeleteDialog
        open={open}
        planName={plan.planName}
        onClose={handleClose}
        onDelete={handleDelete}
      />
      <Snackbar open={!!error} onClose={handleSnackbarClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
}
