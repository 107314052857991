import "firebase/firestore";
import { client } from "../utils/axios";
import { isCloudinaryImage } from "./media";
import { isTeacher } from "./teacher";

export type Lesson = {
  lessonID: number;
  lessonName: string;
};

function isLesson(x: any): x is Lesson {
  return (
    x && typeof x.lessonID === "number" && typeof x.lessonName === "string"
  );
}

export type Description = {
  lessonID: number;
  description: string;
};

function isDescription(x: any): x is Description {
  return (
    x && typeof x.lessonID === "number" && typeof x.description === "string"
  );
}

export type MusicalWorkCode = {
  lessonID: number;
  jasracWorkCode: string;
  nextoneWorkCode: string;
};

function isMusicalWorkCode(x: any): x is MusicalWorkCode {
  return (
    x &&
    typeof x.lessonID === "number" &&
    typeof x.jasracWorkCode === "string" &&
    typeof x.nextoneWorkCode === "string"
  );
}

export type LessonSummary = {
  lessonID: number;
  lessonName: string;
  lessonOrder: number;
  description: boolean;
  image: boolean;
  teacher: boolean;
  mainMovie: boolean;
  homework: boolean;
  text: boolean;
};

export function isLessonSummary(x: any): x is LessonSummary {
  return (
    x &&
    typeof x.lessonID === "number" &&
    typeof x.lessonName === "string" &&
    typeof x.lessonOrder === "number" &&
    typeof x.description === "boolean" &&
    typeof x.image === "boolean" &&
    typeof x.teacher === "boolean" &&
    typeof x.mainMovie === "boolean" &&
    typeof x.homework === "boolean" &&
    typeof x.text === "boolean"
  );
}

export function isLessonSummaryArray(x: any): x is LessonSummary[] {
  return x && Array.isArray(x) && x.every(isLessonSummary);
}

//
// lessons
//
export async function getLesson(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}`);
  const { data } = res;
  if (isLesson(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateLessonName(lessonID: number, lessonName: string) {
  const c = await client();
  await c.post(`/lessons/${lessonID}`, { lessonName });
}

//
// descriptions
//
export async function getLessonDesc(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/descriptions`);
  const { data } = res;
  if (isDescription(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateDesc(lessonID: number, description: string) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/descriptions`, { description });
}

export async function getLessonImage(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/images`);
  const { data } = res;
  if (isCloudinaryImage(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function getLessonTeacher(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/teachers`);
  const { data } = res;
  if (isTeacher(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function getTextURL(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/texts`);
  const { url } = res.data;
  if (typeof url === "string") {
    return url;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateImage(lessonID: number, cldnImageID: number) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/images`, { cldnImageID });
}

export async function updateTeacher(lessonID: number, teacherID: number) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/teachers`, { teacherID });
}

//
// movies
//
export async function getMovie(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/movies`);
  const { vimeoID } = res.data;
  if (typeof vimeoID === "number") {
    return vimeoID;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateMovie(lessonID: number, vimeoID: number) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/movies`, { vimeoID });
}

export async function deleteMovie(lessonID: number) {
  const c = await client();
  await c.delete(`/lessons/${lessonID}/movies`);
}

//
// homework
//
export async function getHomework(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/homework`);
  const { vimeoID } = res.data;
  if (typeof vimeoID === "number") {
    return vimeoID;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateHomework(lessonID: number, vimeoID: number) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/homework`, { vimeoID });
}

export async function deleteHomework(lessonID: number) {
  const c = await client();
  await c.delete(`/lessons/${lessonID}/homework`);
}

export async function updateText(lessonID: number, file: File) {
  const c = await client();
  const form = new FormData();
  form.append("file", file);
  await c.post(`/lessons/${lessonID}/texts`, form);
}

export async function deleteText(lessonID: number) {
  const c = await client();
  await c.delete(`/lessons/${lessonID}/texts`);
}

//
// musical-work-code
//
export async function getLessonMusicalWorkCode(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/musical-work-code`);
  const { data } = res;
  if (isMusicalWorkCode(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}

export async function updateLessonMusicalWorkCode(
  lessonID: number,
  jasracWorkCode: string,
  nextoneWorkCode: string
) {
  const c = await client();
  await c.post(`/lessons/${lessonID}/musical-work-code`, {
    jasracWorkCode: jasracWorkCode,
    nextoneWorkCode: nextoneWorkCode,
  });
}

const NAME_MAX_LEN = 20;
export const DESCRIPTION_MAX_LEN = 150;

export function validateLessonName(lessonName: string): string {
  if (lessonName === "") {
    return "必須項目です";
  }
  if (Array.from(lessonName).length > NAME_MAX_LEN) {
    return `上限は${NAME_MAX_LEN}文字です`;
  }
  return "";
}

export function validateDescription(description: string): string {
  if (description === "") {
    return "必須項目です";
  }
  if (Array.from(description).length > DESCRIPTION_MAX_LEN) {
    return `上限は${DESCRIPTION_MAX_LEN}文字です`;
  }
  return "";
}

//
// feedback
//
export type Feedback = {
  feedbackID: number;
  rating: number;
  text: string;
  createTime: string;
};

function isFeedback(x: unknown): x is Feedback {
  return (
    typeof x === "object" &&
    typeof (x as Feedback).feedbackID === "number" &&
    typeof (x as Feedback).rating === "number" &&
    typeof (x as Feedback).text === "string" &&
    typeof (x as Feedback).createTime === "string"
  );
}

export type FeedbackResponse = {
  average: number;
  feedbacks: Feedback[];
};

function isFeedbackResponse(x: unknown): x is FeedbackResponse {
  return (
    typeof x === "object" &&
    typeof (x as FeedbackResponse).average === "number" &&
    Array.isArray((x as FeedbackResponse).feedbacks) &&
    (x as FeedbackResponse).feedbacks.every(isFeedback)
  );
}

export async function getLessonFeedback(lessonID: number) {
  const c = await client();
  const res = await c.get(`/lessons/${lessonID}/feedbacks`);
  const { data } = res;
  if (isFeedbackResponse(data)) {
    return data;
  }
  throw Error("invalid data " + JSON.stringify(res.data));
}
