import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: "1rem",
  },
}));

export default function ResetDialog({
  open,
  onClose,
  onReset,
}: {
  open: boolean;
  onClose: () => void;
  onReset: () => void;
}) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="error">提出のリセットの確認</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography gutterBottom color="textSecondary">
            提出をリセットしてもよろしいですか？
          </Typography>
          <ul className={classes.list}>
            <li>
              提出をリセットすると、演奏提出前の状態に戻し、ユーザーが再度演奏を提出することができるようになります。
            </li>
            <li>ユーザーの演奏は削除されません。</li>
            <li>講師のコメントは削除されます。</li>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onReset} color="default">
          リセットする
        </Button>
      </DialogActions>
    </Dialog>
  );
}
