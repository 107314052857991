import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import type { Subscription } from "../../../models/subscriptions";
import AdminOpration from "./AdminOperation";
import DescriptionTable from "./DescriptionTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  content: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Preview({
  subscription,
  open,
  onClose,
  onCancel,
  className,
}: {
  subscription: Subscription;
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon color="action" />
        </IconButton>
        <CardContent className={classes.content}>
          <DescriptionTable subscription={subscription} />
          <Box>
            <Typography component="h2" variant="subtitle1">
              Admin 操作
            </Typography>
            <AdminOpration subscription={subscription} onCancel={onCancel} />
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}
