import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeleteDialog({
  planName,
  open,
  onClose,
  onDelete,
}: {
  planName: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="error">プラン削除の確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          プラン「<Typography component="span">{planName}</Typography>
          」を削除してもよろしいですか？
          <br />
          (ユーザーがすでに使用しているプランは削除できません。)
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onDelete} color="default">
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
}
