import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { ADMIN, MEMBER, postRole, Role } from "../../../models/admin";

const roles = [
  { value: ADMIN, label: "管理者" },
  { value: MEMBER, label: "メンバー" },
];

export default function RoleView({
  uid,
  defaultRole,
  disabledLabels,
}: {
  uid: string;
  defaultRole: Role;
  disabledLabels?: string[];
}) {
  const [role, setRole] = useState<Role>(defaultRole);

  const [error, setError] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as Role;
    setRole(value);
    postRole(uid, value).catch((e) => {
      setError("エラーが発生し、ロールの変更に失敗しました");
      setRole(role); // 変更前のロールに戻す
    });
  };

  const handleClose = () => {
    setError("");
  };

  return (
    <>
      <FormControl fullWidth>
        <Select value={role} onChange={handleChange}>
          {roles.map(({ value, label }) => (
            <MenuItem
              key={value}
              value={value}
              disabled={disabledLabels?.includes(value)}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Snackbar open={!!error} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
}
