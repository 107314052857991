import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import {
  deleteMusicType,
  getMusicTypes,
  MusicType,
  MusicTypeInput,
  patchMusicType,
  postMusicType,
} from "../../models/play";
import Header from "./Header";
import Table from "./Table";
import AddDialog from "./AddDialog";
import { Typography } from "@material-ui/core";
import EditDialog from "./EditDialog";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function MusicTypeList() {
  const classes = useStyles();

  const [musicTypes, setMusicTypes] = useState<MusicType[]>([]);

  const loader = () => getMusicTypes().then(setMusicTypes, console.error);

  useEffect(() => {
    loader();
  }, []);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const handleHeaderClick = () => {
    setOpenAddDialog(true);
  };
  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleAdd = (data: MusicTypeInput) => {
    setSaveError("");
    setIsSaving(true);
    postMusicType(data)
      .then(
        (newOne) => {
          handleAddDialogClose();
          setMusicTypes((m) => [...m, newOne]);
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です。");
              break;
            default:
              setSaveError("エラーが発生し、追加に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [editID, setEditID] = useState<number>();
  const handleEdit = (id: number) => {
    setEditID(id);
    setEditorOpen(true);
  };
  const handleEditorClose = () => {
    setEditorOpen(false);
  };

  const handleSave = (data: MusicTypeInput) => {
    if (!editID) return;
    setSaveError("");
    setIsSaving(true);
    patchMusicType(editID, data)
      .then(
        () => {
          handleEditorClose();
          loader();
        },
        (e) => {
          switch (e?.response?.status) {
            case 400:
              setSaveError("不正な入力です。");
              break;
            default:
              setSaveError("エラーが発生し、更新に失敗しました。");
              break;
          }
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const [deleteError, setDeleteError] = useState<string>("");
  const handleDelete = (id: number) => {
    setDeleteError("");
    deleteMusicType(id).then(
      () => {
        setMusicTypes((ms) =>
          ms.filter(({ musicTypeID }) => musicTypeID !== id)
        );
      },
      (e) => {
        switch (e?.response?.status) {
          case 422:
            setDeleteError(
              "この演奏形態は使用されているため、削除できません。"
            );
            break;
          default:
            setDeleteError("エラーが発生し、削除に失敗しました。");
            break;
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      {deleteError && <Typography color="error">{deleteError}</Typography>}
      <Header onClick={handleHeaderClick} />
      <Table
        className={classes.results}
        musicTypes={musicTypes}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <AddDialog
        open={openAddDialog}
        onSave={handleAdd}
        onClose={handleAddDialogClose}
        isSaving={isSaving}
        saveError={saveError}
      />
      <EditDialog
        open={editorOpen}
        onSave={handleSave}
        onClose={handleEditorClose}
        isSaving={isSaving}
        saveError={saveError}
        defaultValues={{
          name:
            musicTypes.find((m) => m.musicTypeID === editID)?.musicType || "",
        }}
      />
    </div>
  );
}
