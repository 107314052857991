import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import MovieIcon from "@material-ui/icons/Movie";
import ImageIcon from "@material-ui/icons/Image";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import type { SvgIconProps } from "@material-ui/core";
import { AUDIO, VIDEO, IMAGE, IMAGES } from "../../models/post";
import type { ContentType } from "../../models/post";

type ContentTypeIconProps = {
  contentType: ContentType;
  svgIconProps?: SvgIconProps;
};

export default function ContentTypeIcon(props: ContentTypeIconProps) {
  const { contentType, svgIconProps } = props;
  switch (contentType) {
    case AUDIO:
      return <AudiotrackIcon {...svgIconProps} />;
    case VIDEO:
      return <MovieIcon {...svgIconProps} />;
    case IMAGE:
      return <ImageIcon {...svgIconProps} />;
    case IMAGES:
      return <PhotoLibraryIcon {...svgIconProps} />;
    default:
      return null;
  }
}
