import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import CloudinaryImage from "../CloudinaryImage";
import { getURL } from "../../models/media";
import * as colors from "@material-ui/core/colors";

type Image = import("../../models/media").CloudinaryImage;

const useStyles = makeStyles(() => ({
  empty: {
    width: 640,
    height: 360,
    backgroundColor: colors.grey[300],
  },
  image: {
    width: 640,
  },
}));

export default function ImageSelect({
  image,
  saveImageID,
}: {
  image?: Image;
  saveImageID: (id: number) => Promise<void>;
}) {
  const classes = useStyles();

  const [tmpImage, setTmpImage] = useState<Image>();
  useEffect(() => {
    setTmpImage(image);
  }, [image]);
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = () => {
    tmpImage &&
      saveImageID(tmpImage.cldnImageID).then(toggleEdit).catch(console.error);
  };

  if (edit) {
    return (
      <Grid
        container
        spacing={1}
        justifyContent={"flex-start"}
        alignItems={"flex-end"}
      >
        <Grid item className={classes.image}>
          <CloudinaryImage image={tmpImage} onChangeImage={setTmpImage} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSave}
            disabled={!tmpImage || image?.cldnImageID === tmpImage?.cldnImageID}
          >
            保存
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="default"
            variant="contained"
            size="small"
            onClick={toggleEdit}
          >
            キャンセル
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent={"flex-start"}
      alignItems={"flex-end"}
    >
      <Grid item>
        {image ? (
          <img
            className={classes.image}
            src={getURL(image)}
            alt="レッスン画像"
          />
        ) : (
          <div className={classes.empty} />
        )}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={toggleEdit}
        >
          編集
        </Button>
      </Grid>
    </Grid>
  );
}
