import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  from: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    flex: "none",
  },
  action: {
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export type FormValues = {
  comment: string;
};

export default function Editor({
  defaultValues,
  onSubmit,
  onCancel,
}: {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
}) {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormValues>({ defaultValues });

  return (
    <form className={classes.from} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextField
            variant="outlined"
            multiline
            fullWidth
            maxRows={10}
            autoFocus
            {...field}
          />
        )}
      />
      <Box className={classes.action} display="flex">
        <Button
          className={classes.button}
          variant="contained"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isDirty}
        >
          保存する
        </Button>
      </Box>
    </form>
  );
}
