import Button from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getURL } from "../../../../models/media";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  text: {
    border: `0.5px solid ${colors.grey[300]}`,
    borderRadius: 8,
    height: "8rem",
    overflow: "auto",
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  singleLine: {
    height: "3rem",
  },
  multiline: {
    height: "8rem",
  },
  description: {
    height: "2.5rem",
  },
  media: {
    maxHeight: "250px",
    width: "auto",
  },
  admissionTexts: {
    marginTop: theme.spacing(1),
  },
}));

export default function Viewer({
  header,
  onClick,
  disableEdit,
}: {
  header?: import("../../../../models/curriculum").Header;
  onClick: () => void;
  disableEdit?: boolean;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={onClick}
            disabled={disableEdit}
          >
            編集
          </Button>
        </Grid>
      </Grid>
      <div>
        <Typography>タイトル</Typography>
        <Typography
          className={clsx(classes.text, classes.singleLine)}
          variant="subtitle1"
          component="pre"
        >
          {header?.title}
        </Typography>
      </div>
      <div>
        <Typography>画像</Typography>
        {header?.image ? (
          <Grid container>
            <Grid item>
              <Card>
                <img
                  className={classes.media}
                  alt="ヘッダー画像"
                  src={getURL(header?.image)}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography>画像がありません</Typography>
        )}
      </div>
      <div>
        <Typography>概要</Typography>
        <Typography
          className={clsx(classes.text, classes.multiline)}
          variant="subtitle1"
          component="pre"
        >
          {header?.description}
        </Typography>
      </div>
      <div>
        <Typography>入会情報</Typography>
        <ul>
          {header?.admissionTexts.map((x, i) => (
            <Typography
              key={i}
              variant="subtitle1"
              component="li"
              className={clsx(
                classes.text,
                classes.singleLine,
                classes.admissionTexts
              )}
            >
              {x}
            </Typography>
          ))}
        </ul>
      </div>
      <div>
        <Typography>サムネイル画像</Typography>
        {header?.thumbnail ? (
          <Grid container>
            <Grid item>
              <Card>
                <img
                  className={classes.media}
                  alt="サムネイル画像"
                  src={getURL(header?.thumbnail)}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography>サムネイル画像がありません</Typography>
        )}
      </div>
      <div>
        <Typography>購入画面トップ画像</Typography>
        {header?.purchaseTopImage ? (
          <Grid container>
            <Grid item>
              <Card>
                <img
                  className={classes.media}
                  alt="購入画面トップ画像"
                  src={getURL(header?.purchaseTopImage)}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography>画像がありません</Typography>
        )}
      </div>
    </div>
  );
}
