import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { getCategories } from "../../../models/category";
import { getCourseBases } from "../../../models/course";
import {
  getTeacherAssignment,
  updateTeacherAssignment,
} from "../../../models/teacher";

type CourseBase = import("../../../models/course").CourseBase;

const useStyles = makeStyles((theme) => ({
  category: {
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function CourseAssignment({ teacherID }: { teacherID: number }) {
  const classes = useStyles();

  const [categories, setCategories] = useState<
    import("../../../models/category").Category[]
  >([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);

  const [categoryToCourseBases, setCategoryToCourseBases] = useState<
    Map<string, CourseBase[]>
  >(new Map());
  useEffect(() => {
    getCourseBases().then((xs) => {
      const m = new Map<string, CourseBase[]>();
      xs.sort((a, b) => a.courseID - b.courseID).forEach((x) => {
        const arr = m.get(x.categoryCode);
        if (arr) {
          arr.push(x);
        } else {
          m.set(x.categoryCode, [x]);
        }
      });
      setCategoryToCourseBases(m);
    }, console.error);
  }, []);

  const [assignment, setAssignment] = useState<Set<number>>(new Set());
  const [changed, setChanged] = useState(false);
  useEffect(() => {
    getTeacherAssignment(teacherID).then((xs) => {
      setAssignment(new Set(xs));
      setChanged(false);
    }, console.error);
  }, [teacherID]);
  const handleCheck = (id: number) => {
    setChanged(true);
    setAssignment((x) => {
      x.has(id) ? x.delete(id) : x.add(id);
      return new Set(x);
    });
  };
  const handleSave = () => {
    updateTeacherAssignment(teacherID, Array.from(assignment)).then(() => {
      setChanged(false);
    }, console.error);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4">Homework 担当コース</Typography>
        {categories.map((x) => (
          <div key={x.categoryCode} className={classes.category}>
            <Typography variant="h5" gutterBottom>
              <b>{x.categoryName}</b>
            </Typography>
            <Grid container>
              {(categoryToCourseBases.get(x.categoryCode) || []).map((y) => (
                <Grid item xs={12} sm={2} key={y.courseCode}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={assignment.has(y.courseID)}
                        onChange={() => handleCheck(y.courseID)}
                        color="primary"
                      />
                    }
                    label={y.courseCode}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
        <div className={classes.buttonContainer}>
          <Button
            onClick={handleSave}
            disabled={!changed}
            variant="contained"
            color="primary"
          >
            更新
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
