import { client } from "../utils/axios";

export type Plan = {
  planID: number;
  planName: string;
  planPrice: number;
};

export function isPlan(x: unknown): x is Plan {
  return (
    typeof x === "object" &&
    typeof (x as Plan).planName === "string" &&
    typeof (x as Plan).planPrice === "number"
  );
}

export function isPlanArray(xs: unknown): xs is Plan[] {
  return Array.isArray(xs) && xs.every(isPlan);
}

export const STANDARD_PLAN = "standard";
export const ENTRY_PLAN = "entry";

export function toJaName(x: Plan) {
  switch (x.planName) {
    case STANDARD_PLAN:
      return "スタンダード";
    case ENTRY_PLAN:
      return "エントリー";
    default:
      console.warn(`Unknown plan name: ${x.planName}`);
      return x.planName;
  }
}

export async function getPlans(): Promise<Plan[]> {
  const c = await client();
  const { data } = await c.get("/plans");
  const { plans } = data;
  if (isPlanArray(plans)) {
    return plans;
  }
  throw new Error(`Invalid response: ${JSON.stringify(data)}`);
}
