import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { createRef, Fragment, useState } from "react";
import { getURL, uploadImage } from "../../models/media";
import Progress from "../Progress";

type CloudinaryImageType = import("../../models/media").CloudinaryImage;

const defaultAspectRatio = 9 / 16;

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  card: {
    "&:hover": {
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  placeholderBox: {
    position: "relative",
  },
  placeholder: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: colors.blueGrey[50],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  insertDriveFileIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    fontSize: theme.spacing(6),
  },
  inputFile: {
    display: "none",
  },
}));

export default function CloudinaryImage({
  image,
  onChangeImage,
  aspectRatio,
}: {
  image?: CloudinaryImageType;
  onChangeImage: (image: CloudinaryImageType) => void;
  aspectRatio?: number;
}) {
  const classes = useStyles();
  const ref = createRef<HTMLInputElement>();
  const handleClick = () => {
    if (ref?.current) {
      const { current } = ref;
      current.click();
    }
  };
  const [uploading, setUploading] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }

    setUploading(true);
    uploadImage(files[0])
      .then(onChangeImage)
      .catch(console.error)
      .finally(() => {
        setUploading(false);
      });
  };

  const ratio = (aspectRatio || defaultAspectRatio) * 100;
  const style = { paddingTop: `${ratio}%` };

  return (
    <Fragment>
      <Card className={classes.card} onClick={handleClick}>
        {uploading ? (
          <div style={style} className={classes.placeholderBox}>
            <div className={classes.placeholder}>
              <Progress />
            </div>
          </div>
        ) : image ? (
          <CardMedia style={style} image={getURL(image)} />
        ) : (
          <div style={style} className={classes.placeholderBox}>
            <div className={classes.placeholder}>
              <AddCircleOutlineIcon className={classes.insertDriveFileIcon} />
            </div>
          </div>
        )}
      </Card>
      <input
        ref={ref}
        type="file"
        className={classes.inputFile}
        accept="image/png, image/jpeg"
        onChange={handleChange}
      />
    </Fragment>
  );
}
