import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import * as colors from "@material-ui/core/colors";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { createCurrCourses, validateCode } from "../../../../models/course";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 400,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    container: {
      marginTop: theme.spacing(3),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
  })
);

export default function CourseEditor({
  open,
  onClose,
  currCode,
}: {
  currCode: string;
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  const [code, setCode] = useState("");
  const handleChange = (
    event: import("react").ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    const { value } = event.target;
    setCode(value);
  };
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    const error = validateCode(code);
    if (error) {
      setError(error);
      return;
    }
    setError("");
    setUploading(true);
    createCurrCourses(currCode, code).then(
      () => {
        history.push(`/courses/${code}`);
      },
      (e) => {
        setError("エラーが発生しました");
        setUploading(false);
        console.error(e);
      }
    );
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <form onSubmit={handleSave}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              コース作成
            </Typography>
            <div className={classes.container}>
              <TextField
                fullWidth
                required
                label="コースコード"
                onChange={handleChange}
                value={code}
                error={!!error}
                helperText={error}
                variant="outlined"
              />
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onClose} variant="contained">
              閉じる
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              type="submit"
              disabled={uploading}
            >
              保存
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
