import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import type { ProductInput } from "../../../models/product";
import { PRODUCT_NAME_MAX_LEN } from "../../../models/product";
import {
  maxLengthRule,
  numberPattern,
  requiredMessage,
} from "../../../utils/form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
    buttons: {
      marginTop: theme.spacing(3),
      textAlign: "right",
      "& > * + *": {
        marginLeft: theme.spacing(1),
      },
    },
  })
);

const defaultValues: ProductInput = {
  name: "",
  price: 0,
  stripeProductID: "",
  stripePriceID: "",
};

function formatInput(input: ProductInput): ProductInput {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  return {
    ...input,
    price: toInt(input.price),
  };
}

type Props = {
  onSave: (data: ProductInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

export default function Form({ onSave, onCancel, isSaving, saveError }: Props) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<ProductInput>({
    defaultValues,
  });

  const handleSave = (data: ProductInput) => {
    onSave(formatInput(data));
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      {saveError && <Typography color="error">{saveError}</Typography>}
      <div className={classes.container}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(PRODUCT_NAME_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="商品名"
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.container}>
        <Controller
          name="price"
          control={control}
          rules={{
            required: requiredMessage,
            pattern: numberPattern,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="価格"
              inputMode="numeric"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">¥</InputAdornment>
                ),
              }}
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.container}>
        <Controller
          name="stripeProductID"
          control={control}
          rules={{
            required: requiredMessage,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="Stripe商品ID"
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.container}>
        <Controller
          name="stripePriceID"
          control={control}
          rules={{
            required: requiredMessage,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              label="Stripe価格ID"
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              {...field}
            />
          )}
        />
      </div>
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
