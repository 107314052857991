import { makeStyles } from "@material-ui/styles";
import type { Theme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { Page } from "../../../models/page";
import type { Comment } from "../../../models/post";
import { getPostComments } from "../../../models/post";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Table from "./Table";

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    marginTop: theme.spacing(3),
  },
  hasPrevNext: {
    justifyContent: "space-between",
  },
  hasNext: {
    justifyContent: "flex-end",
  },
}));

export default function CommentView({ postID }: { postID: string }) {
  const classes = useStyles();

  const [comments, setComments] = useState<Comment[]>([]);
  const [page, setPage] = useState<Page>({});
  const [nowPage, setNowPage] = useState<number>(1);

  useEffect(() => {
    getPostComments(postID, nowPage).then(({ comments, page }) => {
      setComments(comments);
      setPage(page);
    }, console.error);
  }, [postID, nowPage]);

  const handleNext = () => {
    if (page?.next) {
      setNowPage(page.next);
    }
  };
  const handlePrev = () => {
    if (page?.prev) {
      setNowPage(page.prev);
    }
  };

  return (
    <div>
      <Table comments={comments} />
      {page && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: page.prev && page.next,
            [classes.hasNext]: !page.prev && page.next,
          })}
        >
          {page.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {page.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
