import List from "@material-ui/core/List";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { JSXElementConstructor } from "react";
import { matchPath, useLocation } from "react-router-dom";
import NavigationListItem from "./NavigationListItem";

type Page = {
  title: string;
  href: string;
  icon?: JSXElementConstructor<any>;
  label?: JSXElementConstructor<any>;
  disabled?: boolean;
  children?: Page[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const NavigationList = ({
  depth,
  pages,
  pathname,
}: {
  depth: number;
  pathname: string;
  pages: Page[];
}) => {
  return (
    <List>
      {pages.map((page, i) => (
        <Route key={i} page={page} depth={depth} pathname={pathname} />
      ))}
    </List>
  );
};

const Route = ({
  pathname,
  page,
  depth,
}: {
  pathname: string;
  page: Page;
  depth: number;
}) => {
  if (page.children && page.children.length > 0) {
    const open = matchPath(pathname, {
      path: page.href,
      exact: false,
    });

    return (
      <NavigationListItem
        title={page.title}
        href={page.href}
        depth={depth}
        icon={page.icon}
        label={page.label}
        open={Boolean(open)}
        disabled={page.disabled}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          pathname={pathname}
        />
      </NavigationListItem>
    );
  } else {
    return (
      <NavigationListItem
        title={page.title}
        href={page.href}
        depth={depth}
        open={false}
        icon={page.icon}
        label={page.label}
        disabled={page.disabled}
      />
    );
  }
};

export default function Navigation({
  title,
  pages,
}: {
  title: string;
  pages: Page[];
}) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div className={classes.root}>
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList depth={0} pages={pages} pathname={location.pathname} />
    </div>
  );
}
