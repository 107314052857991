export type Profile = {
  uid: string;
  nickname: string;
  introduction: string;
};

export function isProfile(x: any): x is Profile {
  return (
    x &&
    typeof x.uid === "string" &&
    typeof x.nickname === "string" &&
    typeof x.introduction === "string"
  );
}
