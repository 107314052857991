import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { deleteHidden, putHidden } from "../../../models/post";

export default function Hidden({
  postID,
  defaultValue,
}: {
  postID: string;
  defaultValue: boolean;
}) {
  const [hidden, setHidden] = useState<boolean>(defaultValue);
  const [notification, setNotification] = useState<string>("");

  const handleCloseNotification = () => {
    setNotification("");
  };

  const save = (fn: Promise<void>) => {
    fn.then(
      () => {
        setNotification("✅ 保存しました");
      },
      (e) => {
        setNotification("❗ 保存に失敗しました");
      }
    );
  };

  const handleChangeHidden = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    setHidden(checked);
    const method = checked ? putHidden : deleteHidden;
    save(method(postID));
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography component="span">コミュニティへの非表示</Typography>
        <ToggleSwitch
          label="非表示にする"
          checked={hidden}
          onChange={handleChangeHidden}
        />
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!notification}
        autoHideDuration={3000}
        onClose={handleCloseNotification}
        message={notification}
      />
    </>
  );
}
