import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import pink from "@material-ui/core/colors/pink";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Progress from "../../components/Progress/Progress";
import { getTrial, postTrial } from "../../models/course";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    redButton: {
      color: grey[50],
      backgroundColor: pink[500],
      "&:hover": {
        backgroundColor: pink[600],
      },
    },
  })
);

export default function CourseSettings() {
  const classes = useStyles();
  const { code } = useParams<{ code: string }>();

  const [trial, setTrial] = useState<boolean>();
  useEffect(() => {
    getTrial(code).then(setTrial, console.error);
  }, [code]);

  const handleClick = () => {
    if (
      !window.confirm(
        `${code} コースをトライアルに設定します。よろしいですか？`
      )
    ) {
      return;
    }
    postTrial(code).then((res) => {
      setTrial(true);
    }, console.error);
  };

  if (typeof trial === "undefined") return <Progress />;

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        container
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h4">
            コース設定
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h4">
            {code}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction="column">
        <Grid item>
          <Typography gutterBottom component="h2" variant="h5">
            トライアルの設定
          </Typography>
          <Button
            className={classes.redButton}
            color="inherit"
            variant="contained"
            disabled={trial}
            onClick={handleClick}
          >
            {trial ? "トライアルに設定済み" : "トライアルに設定する"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
