import axios from "axios";
import { getUser } from "./auth";

axios.defaults.baseURL = "/api/v1";

export async function client() {
  const user = getUser();
  if (!user) {
    throw Error("not authenticated");
  }
  const token = await user.getIdToken();
  return axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
