import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import { typeFormatFromArray } from "./utils";

export type Coupon = {
  couponID: number;
  stripeCouponID: string;
  couponName: string;
  durationInMonths: number;
  amountOff: number;
  expireTime: Date;
};

function isCoupon(x: unknown): x is Coupon {
  return (
    typeof x === "object" &&
    typeof (x as Coupon).couponID === "number" &&
    typeof (x as Coupon).stripeCouponID === "string" &&
    typeof (x as Coupon).couponName === "string" &&
    typeof (x as Coupon).durationInMonths === "number" &&
    typeof (x as Coupon).amountOff === "number" &&
    (x as Coupon).expireTime instanceof Date
  );
}

function formatCoupon(x: unknown): Coupon | null {
  if (typeof x !== "object") {
    return null;
  }
  const { expireTime } = x as Coupon;
  if (typeof expireTime !== "string") {
    return null;
  }
  const ret = {
    ...x,
    expireTime: parseISO(expireTime),
  };
  if (!isCoupon(ret)) {
    return null;
  }
  return ret;
}

export async function getCoupons(): Promise<Coupon[]> {
  const c = await client();
  const { data } = await c.get("/coupons");
  const coupons = typeFormatFromArray<Coupon>(data.coupons, formatCoupon);
  if (coupons) {
    return coupons;
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export type CouponInput = {
  name: string;
  stripeCouponID: string;
  durationInMonths: number;
  amountOff: number;
  expireTime: string;
};

export const NAME_MAX_LEN = 200;
export const STRIPE_COUPON_ID_MAX_LEN = 64;

export async function postCoupon(input: CouponInput): Promise<Coupon> {
  const c = await client();
  const { data } = await c.post("/coupons", input);
  const coupon = formatCoupon(data);
  if (coupon) {
    return coupon;
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export async function getCoupon(id: number): Promise<Coupon> {
  const c = await client();
  const { data } = await c.get(`/coupons/${id}`);
  const coupon = formatCoupon(data);
  if (coupon) {
    return coupon;
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export type CouponPatchInput = {
  name: string;
};

export async function patchCoupon(
  id: number,
  input: CouponPatchInput
): Promise<void> {
  const c = await client();
  return c.patch(`/coupons/${id}`, input);
}

export async function deleteCoupon(id: number): Promise<void> {
  const c = await client();
  return c.delete(`/coupons/${id}`);
}

export async function expireCoupon(id: number): Promise<void> {
  const c = await client();
  return c.put(`/coupons/${id}/expire`);
}

function isCouponCode(x: unknown): x is string {
  return typeof x === "string";
}

function isCouponCodeArray(xs: unknown): xs is string[] {
  return Array.isArray(xs) && xs.every(isCouponCode);
}

export async function getCouponCodes(id: number): Promise<string[]> {
  const c = await client();
  const {
    data: { codes },
  } = await c.get(`/coupons/${id}/codes`);
  if (isCouponCodeArray(codes)) {
    return codes;
  }
  throw Error(`Invalid response: ${JSON.stringify(codes)}`);
}

export const COUPON_CODE_MAX_LEN = 64;

export type CouponCodeInput = {
  code: string;
};

export async function postCouponCodes(
  id: number,
  input: CouponCodeInput
): Promise<void> {
  const c = await client();
  return c.post(`/coupons/${id}/codes`, input);
}

export async function deleteCouponCode(code: string): Promise<void> {
  const c = await client();
  return c.delete(`/couponCodes/${code}`);
}

export type RedeemableUserCoupon = {
  couponType: string;
};

function isRedeemableUserCoupon(x: unknown): x is RedeemableUserCoupon {
  return (
    typeof x === "object" &&
    typeof (x as RedeemableUserCoupon).couponType === "string"
  );
}

export async function getRedeemableUserCoupon(
  id: number
): Promise<RedeemableUserCoupon> {
  const c = await client();
  const { data } = await c.get(`/coupons/${id}/redeemableUserCoupon`);
  if (isRedeemableUserCoupon(data)) {
    return data;
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export type SignUpURL = {
  url: string;
};

function isSignUpURL(x: unknown): x is SignUpURL {
  return typeof x === "object" && typeof (x as SignUpURL).url === "string";
}

export type RedeemableUserCouponInput = {
  couponType: string;
};

export const COUPON_TYPE_MAX_LEN = 64;

export async function putRedeemableUserCoupon(
  id: number,
  input: RedeemableUserCouponInput
): Promise<SignUpURL> {
  const c = await client();
  const { data } = await c.put(`/coupons/${id}/redeemableUserCoupon`, input);
  if (isSignUpURL(data)) {
    return data;
  }
  throw Error(`Invalid response: ${JSON.stringify(data)}`);
}

export async function deleteRedeemableUserCoupon(
  id: number
): Promise<SignUpURL> {
  const c = await client();
  return c.delete(`/coupons/${id}/redeemableUserCoupon`);
}
