import { client } from "../utils/axios";
import { isObject } from "./utils";

export type Admin = {
  uid: string;
  email: string;
};

export function isAdmin(x: unknown): x is Admin {
  return (
    typeof x === "object" &&
    typeof (x as Admin).uid === "string" &&
    typeof (x as Admin).email === "string"
  );
}

export type AdminComment = {
  adminCommentID: number;
  admin: Admin;
  comment: string;
  createTime: Date;
  updateTime: Date;
};

export function isAdminComment(x: unknown): x is AdminComment {
  return (
    typeof x === "object" &&
    typeof (x as AdminComment).adminCommentID === "number" &&
    isAdmin((x as AdminComment).admin) &&
    typeof (x as AdminComment).comment === "string" &&
    (x as AdminComment).createTime instanceof Date &&
    (x as AdminComment).updateTime instanceof Date
  );
}

export function formatAdminComment(x: unknown): AdminComment | null {
  if (!isObject(x)) {
    return null;
  }
  const { createTime, updateTime } = x as AdminComment;
  if (typeof createTime !== "string") {
    return null;
  }
  if (typeof updateTime !== "string") {
    return null;
  }
  const res = {
    ...x,
    createTime: new Date(createTime),
    updateTime: new Date(updateTime),
  };
  if (!isAdminComment(res)) {
    return null;
  }
  return res;
}

export async function patchAdminComment(
  id: number,
  comment: string
): Promise<void> {
  const c = await client();
  await c.patch(`/adminComments/${id}`, { comment });
}
