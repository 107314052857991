import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function PublishDialog({
  open,
  onClose,
  onPublish,
}: {
  open: boolean;
  onClose: () => void;
  onPublish: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>カリキュラムアーカイブの確認</DialogTitle>
      <DialogContent>
        <DialogContentText color="error">
          カリキュラムをアーカイブしてもよろしいですか？
          <br />
          アーカイブにすると、ユーザーが新規にこのカリキュラムを受講することができなくなります。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onPublish} color="default">
          アーカイブする
        </Button>
      </DialogActions>
    </Dialog>
  );
}
