import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import {
  Description as CurrDesc,
  getDescription,
  updateDescription,
} from "../../../models/curriculum";
import Editor from "./Editor";
import Viewer from "./Viewer";

export default function Description({ currCode }: { currCode: string }) {
  const [desc, setDesc] = useState<CurrDesc>();
  useEffect(() => {
    getDescription(currCode).then(setDesc, console.error);
  }, [currCode]);

  const [edit, setEdit] = useState(false);
  const toggleEdit = () => {
    setEdit((edit) => !edit);
  };

  const handleSave = (desc: CurrDesc) => {
    updateDescription(currCode, desc)
      .then(() => {
        toggleEdit();
        setDesc(desc);
      })
      .catch(console.error);
  };

  return (
    <Paper>
      <Box p={3}>
        <Box display={edit ? "block" : "none"}>
          <Editor desc={desc} save={handleSave} onClickCancel={toggleEdit} />
        </Box>
        <Box display={edit ? "none" : "block"}>
          <Viewer desc={desc} onClick={toggleEdit} />
        </Box>
      </Box>
    </Paper>
  );
}
