import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { sortLessons } from "../../../../models/course";
import Table from "./Table";

type LessonSummary = import("../../../../models/lesson").LessonSummary;

export default function Sort({
  course,
  lessons,
  onCancel,
  reload,
}: {
  course: import("../../../../models/course").AdminCourse;
  lessons: LessonSummary[];
  onCancel: () => void;
  reload: () => Promise<void>;
}) {
  const [tmpLessons, setTmpLessons] = useState<LessonSummary[]>([]);
  useEffect(() => {
    setTmpLessons([...lessons]);
  }, [lessons]);

  const handleChangeOrder = (i: number, move: number) => {
    if (i < 0 || move === 0) {
      return;
    }
    if (move < 0) {
      if (i === 0) {
        return;
      }
      setTmpLessons((ls) => {
        if (i >= ls.length || ls.length <= 1) {
          return ls;
        }
        const cs = [...ls];
        cs[i - 1] = ls[i];
        cs[i] = ls[i - 1];
        return cs;
      });
    }
    if (move > 0) {
      setTmpLessons((ls) => {
        if (i >= ls.length - 1 || ls.length <= 1) {
          return ls;
        }
        const cs = [...ls];
        cs[i] = ls[i + 1];
        cs[i + 1] = ls[i];
        return cs;
      });
    }
  };

  const handleSave = () => {
    sortLessons(
      course.courseCode,
      course.courseVer,
      tmpLessons.map((x) => x.lessonID)
    )
      .then(reload)
      .then(onCancel)
      .catch(console.error);
  };

  return (
    <div>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="flex-end"
        spacing={3}
      >
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleSave}>
            更新
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={onCancel}>
            キャンセル
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Table lessons={tmpLessons} onChangeOrder={handleChangeOrder} />
      </Box>
    </div>
  );
}
