import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import { format, isBefore } from "date-fns";
import ja from "date-fns/locale/ja";
import type { Coupon } from "../../../../models/coupon";
import { couponPage } from "../../../../utils/stripe";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Viewer({
  coupon,
  onClick,
  expireActionElment,
}: {
  coupon?: Coupon;
  onClick: () => void;
  expireActionElment: React.ReactNode;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">クーポンの詳細</Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onClick}
            startIcon={<EditIcon />}
          >
            編集
          </Button>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row" width="25%">
              <Typography component="span" color="textSecondary">
                ID
              </Typography>
            </TableCell>
            <TableCell>{coupon?.couponID}</TableCell>
            <TableCell width="12%" />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                クーポン名
              </Typography>
            </TableCell>
            <TableCell>{coupon?.couponName}</TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                Stripe クーポン ID
              </Typography>
            </TableCell>
            <TableCell>
              {coupon?.stripeCouponID && (
                <Link href={couponPage(coupon.stripeCouponID)} target="_blank">
                  {coupon.stripeCouponID}
                </Link>
              )}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                期間
              </Typography>
            </TableCell>
            <TableCell>
              {coupon?.durationInMonths && `${coupon.durationInMonths}ヶ月`}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                割引額
              </Typography>
            </TableCell>
            <TableCell>
              {coupon?.amountOff &&
                new Intl.NumberFormat("ja-JP", {
                  style: "currency",
                  currency: "JPY",
                }).format(coupon.amountOff)}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="span" color="textSecondary">
                失効日時
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                component="span"
                color={
                  isBefore(coupon?.expireTime || 0, new Date())
                    ? "error"
                    : "textPrimary"
                }
              >
                {coupon?.expireTime &&
                  format(coupon.expireTime, "yyyy-MM-dd HH:mm:ss", {
                    locale: ja,
                  })}
              </Typography>
            </TableCell>
            <TableCell align="right">{expireActionElment}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
