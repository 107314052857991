import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { getTeachers } from "../../models/teacher";
import Form from "./Form";
import Header from "./Header";
import Table from "./Table";

type Teacher = import("../../models/teacher").Teacher;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function TeacherList() {
  const classes = useStyles();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const loadTeachers = () => getTeachers().then(setTeachers);
  useEffect(() => {
    loadTeachers().catch(console.error);
  }, []);

  const [openEditor, setOpenEditor] = useState(false);
  const handleEditorOpen = () => {
    setOpenEditor(true);
  };
  const handleEditorClose = () => {
    setOpenEditor(false);
  };

  return (
    <div className={classes.root}>
      <Header onClickEditor={handleEditorOpen} />
      <Table
        className={classes.results}
        teachers={teachers}
        reload={loadTeachers}
      />
      <Form
        open={openEditor}
        onClose={handleEditorClose}
        reload={loadTeachers}
      />
    </div>
  );
}
