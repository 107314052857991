import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";

type Teacher = import("../../models/teacher").Teacher;

export default function TeacherSelect({
  selectedTeachers,
  teachers,
  save,
}: {
  selectedTeachers: Teacher[];
  teachers: Teacher[];
  save: (teacherIDs: number[]) => Promise<void>;
}) {
  const [selected, setSelected] = useState<number[]>([]);
  useEffect(() => {
    setSelected(selectedTeachers.map((x) => x.teacherID));
  }, [selectedTeachers]);
  const checked = (id: number) => selected.some((v) => id === v);
  const genHandleChange = (id: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelected((xs) => xs.concat([id]));
      } else {
        setSelected((xs) => xs.filter((x) => x !== id));
      }
    };
  };
  const changed =
    selected.length !== selectedTeachers.length ||
    selected.some(
      (x) => selectedTeachers.findIndex((y) => x === y.teacherID) < 0
    );

  const handleSave = () => {
    save(selected).catch(console.error);
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Grid item>
        {teachers.map((x) => (
          <FormControlLabel
            key={x.teacherID}
            control={
              <Checkbox
                checked={checked(x.teacherID)}
                onChange={genHandleChange(x.teacherID)}
              />
            }
            label={`${x.familyName} ${x.firstName}`}
          />
        ))}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSave}
          disabled={!changed || selected.length === 0}
        >
          更新
        </Button>
      </Grid>
    </Grid>
  );
}
