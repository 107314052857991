import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link as RouterLink } from "react-router-dom";
import { capitalize } from "../../../utils/format";

type Teacher = import("../../../models/teacher").Teacher;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
  })
);

export default function Table({
  className,
  teachers,
  reload,
}: {
  className: string;
  teachers: Teacher[];
  reload: () => void;
}) {
  const classes = useStyles();

  return (
    <>
      <div className={className}>
        <Card>
          <Divider />
          <CardContent className={classes.content}>
            <div className={classes.inner}>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableCell>講師ID</TableCell>
                    <TableCell>氏名</TableCell>
                    <TableCell>英字氏名</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teachers.map((x) => (
                    <TableRow hover={true} key={x.teacherID}>
                      <TableCell>{x.teacherID}</TableCell>
                      <TableCell>{`${x.familyName} ${x.firstName}`}</TableCell>
                      <TableCell>{`${capitalize(x.familyNameEn)} ${capitalize(
                        x.firstNameEn
                      )}`}</TableCell>
                      <TableCell align="right">
                        <Button
                          component={RouterLink}
                          to={`/teachers/${x.teacherID}`}
                          variant="contained"
                          color="primary"
                        >
                          詳細
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MuiTable>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
