import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import { useEffect, useState } from "react";
import { getCurrPublish, postCurrPublish } from "../../../../models/curriculum";
import Dialog from "./Dialog";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    greenButton: {
      color: "white",
      backgroundColor: green[600],
      "&:hover": {
        backgroundColor: green[900],
      },
    },
  })
);

export default function Publish({ currCode }: { currCode: string }) {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>();

  useEffect(() => {
    getCurrPublish(currCode).then(
      (res) => setIsPublished(res.isPublished),
      console.error
    );
  }, [currCode]);

  const handleClick = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePublish = () => {
    postCurrPublish(currCode).then(() => {
      setIsPublished(true);
      setDialogOpen(false);
    }, console.error);
  };

  return (
    <>
      <Button
        className={classes.greenButton}
        color="inherit"
        variant="contained"
        disabled={isPublished}
        onClick={handleClick}
      >
        {isPublished ? "公開済み" : "公開する"}
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onPublish={handlePublish}
      />
    </>
  );
}
