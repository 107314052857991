import { ADMIN, Role } from "./admin";

type Base = {
  methods: string[];
  path: string;
  roles: Role[];
};

type Permission = {
  key: string;
  has(role: Role): boolean;
};

function create({ methods, path, roles }: Base): Permission {
  return {
    key: `${path},${methods.join(",")}`,
    has: (role: Role) => roles.includes(role),
  };
}

const permissions = {
  coupons: create({
    path: "coupons/*",
    methods: ["ALL"],
    roles: [ADMIN],
  }),
  categoriesPost: create({
    path: "categories",
    methods: ["POST"],
    roles: [ADMIN],
  }),
  categoriesCodePatch: create({
    path: "categories/:code",
    methods: ["PATCH"],
    roles: [ADMIN],
  }),
  categoriesPlans: create({
    path: "categories/:code/plans",
    methods: ["POST", "DELETE"],
    roles: [ADMIN],
  }),
  products: create({
    path: "products",
    methods: ["ALL"],
    roles: [ADMIN],
  }),
  admin: create({
    path: "admin",
    methods: ["ALL"],
    roles: [ADMIN],
  }),
  subscriptions: create({
    path: "subscriptions/:id/cancel",
    methods: ["POST"],
    roles: [ADMIN],
  }),
};

export default permissions;
