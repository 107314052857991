import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import * as colors from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Label from "../../../components/Label";
import Visibility from "../../../components/Visibility";
import { submissionKey } from "../../../models/homework";
import { getTeachers } from "../../../models/teacher";
import Preview from "../Preview";

const useStyles = makeStyles(() => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
}));

const statusColors = {
  grey: colors.grey[600],
  green: colors.green[600],
};

export default function SubmissionListTable({
  submissions,
  lessonToCategory,
  reload,
  className,
}: {
  submissions: import("../../../models/homework").Submission[];
  lessonToCategory: Map<number, string>;
  reload: () => Promise<void>;
  className?: string;
}) {
  const classes = useStyles();

  const [teachers, setTeachers] = useState<
    import("../../../models/teacher").Teacher[]
  >([]);
  useEffect(() => {
    getTeachers().then(setTeachers, console.error);
  }, []);

  const [key, setKey] = useState("");
  const handleClickOpen = (key: string) => () => {
    setKey(key);
  };
  const handleClose = () => {
    setKey("");
  };
  const handleChange = () => {
    reload().catch(console.error);
  };

  return (
    <div className={className}>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>レッスンID</TableCell>
                  <TableCell>レッスン名</TableCell>
                  <TableCell>カテゴリ</TableCell>
                  <TableCell>ユーザー名</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>提出時刻</TableCell>
                  <TableCell>コメント状況</TableCell>
                  <TableCell>コミュニティ表示</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions.map((x) => (
                  <Fragment key={submissionKey(x)}>
                    <TableRow>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/lessons/${x.lesson.lessonID}`}
                          color="inherit"
                        >
                          {x.lesson.lessonID}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/lessons/${x.lesson.lessonID}`}
                          color="inherit"
                        >
                          {x.lesson.lessonName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {lessonToCategory.get(x.lesson.lessonID)}
                      </TableCell>
                      <TableCell>{x.profile?.nickname || "[未登録]"}</TableCell>
                      <TableCell>{x.uid}</TableCell>
                      <TableCell>
                        {format(x.createTime, "yyyy-MM-dd HH:mm:ss", {
                          locale: ja,
                        })}
                      </TableCell>
                      <TableCell>
                        <Label
                          color={
                            statusColors[
                              x.comments.length > 0 ? "green" : "grey"
                            ]
                          }
                        >
                          {x.comments.length > 0 ? "完了" : "未完了"}
                        </Label>
                        {x.disabledComment && (
                          <Label color={statusColors["grey"]}>
                            コメント不要
                          </Label>
                        )}
                      </TableCell>
                      <TableCell>
                        <Visibility invisible={x.hidden} />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={handleClickOpen(submissionKey(x))}
                          color="primary"
                          size="small"
                          variant="contained"
                        >
                          コメント編集
                        </Button>
                      </TableCell>
                    </TableRow>
                    {key === submissionKey(x) && (
                      <Preview
                        submission={x}
                        teachers={teachers}
                        open
                        onClose={handleClose}
                        onChange={handleChange}
                      />
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
