import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import { getCouponCodes } from "../../../models/coupon";
import Delete from "./Delete";
import Editor from "./Editor";

const useStyles = makeStyles((theme) => ({
  head: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Code({ id }: { id: number }) {
  const classes = useStyles();

  const [codes, setCodes] = useState<string[]>([]);

  useEffect(() => {
    getCouponCodes(id).then(setCodes).catch(console.error);
  }, [id]);

  const handleSave = (code: string) => {
    setCodes((codes) => [...codes, code]);
  };

  const handleDelete = (code: string) => () => {
    setCodes((codes) => codes.filter((c) => c !== code));
  };

  return (
    <div>
      <Grid
        className={classes.head}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle1">クーポンコード</Typography>
        </Grid>
        <Grid item>
          <Editor couponID={id} onSave={handleSave} />
        </Grid>
      </Grid>
      <div>
        {codes.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>コード名</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {codes.map((code) => (
                <TableRow key={code}>
                  <TableCell width="90%">
                    <Typography component="span">{code}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Delete code={code} onDelete={handleDelete(code)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography component="span" color="textSecondary">
            クーポンコードはありません
          </Typography>
        )}
      </div>
    </div>
  );
}
