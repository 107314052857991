import isEmail from "validator/lib/isEmail";
import { client } from "../utils/axios";
import { AdminComment, formatAdminComment } from "./comment";
import { formatOrder, Order } from "./order";
import {
  buildQueryParams as pageBuildQueryParams,
  isPage,
  Page,
  parseQueryParams as pageParseQueryParams,
  QueryParams as PageQueryParams,
} from "./page";
import { formatSubscription, Subscription } from "./subscriptions";
import { isObject, typeFormatFromArray } from "./utils";

export type UserMetadata = {
  creationTime: Date;
  lastLogInTime: Date;
};

export function isUserMetadata(x: unknown): x is UserMetadata {
  return (
    typeof x === "object" &&
    (x as UserMetadata).creationTime instanceof Date &&
    (x as UserMetadata).lastLogInTime instanceof Date
  );
}

export type User = {
  uid: string;
  email?: string;
  providers: string[];
  userMetadata: UserMetadata;
};

export function isUser(x: unknown): x is User {
  return (
    typeof x === "object" &&
    typeof (x as User).uid === "string" &&
    (typeof (x as User).email === "undefined" ||
      typeof (x as User).email === "string") &&
    Array.isArray((x as User).providers) &&
    (x as User).providers.every((p) => typeof p === "string") &&
    isUserMetadata((x as User).userMetadata)
  );
}

function formatUser(x: unknown): User | null {
  if (!isObject(x)) {
    return null;
  }
  const { userMetadata } = x as User;
  if (!isObject(userMetadata)) {
    return null;
  }
  const { creationTime, lastLogInTime } = userMetadata;
  if (typeof creationTime !== "string") {
    return null;
  }
  if (typeof creationTime !== "string") {
    return null;
  }
  const res = {
    ...x,
    userMetadata: {
      creationTime: new Date(creationTime),
      lastLogInTime: new Date(lastLogInTime),
    },
  };
  if (!isUser(res)) {
    return null;
  }
  return res;
}

export async function getUser(uid: string): Promise<User> {
  const c = await client();
  const res = await c.get(`/users/${uid}`);
  const user = formatUser(res.data);
  if (user) {
    return user;
  }
  throw Error("failed to get user");
}

export type QueryParams = {
  uid?: string;
  email?: string;
} & PageQueryParams;

const SEARCH_QUERY = "q";
const UID = "uid";
const EMAIL = "email";

export function parseQueryParams(search: string): QueryParams {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};

  const query = params.get(SEARCH_QUERY);
  if (query) {
    if (isEmail(query)) {
      result[EMAIL] = query;
    } else {
      result[UID] = query;
    }
  }

  const page = pageParseQueryParams(search);

  return { ...result, ...page };
}

export function buildQueryParams(params: QueryParams): URLSearchParams {
  const init: string[][] = [];

  const p = new URLSearchParams();
  const { uid, email } = params;
  if (uid) {
    p.set(SEARCH_QUERY, uid);
  }
  if (email) {
    p.set(SEARCH_QUERY, email);
  }
  init.push(...Array.from(p));

  const pp = pageBuildQueryParams(params);
  init.push(...Array.from(pp));

  return new URLSearchParams(init);
}

export async function getUsers(
  params?: QueryParams
): Promise<{ users: User[]; page: Page }> {
  const c = await client();
  const res = await c.get("/users", { params });
  const { users: resUsers, page: resPage } = res.data;
  const users = typeFormatFromArray(resUsers, formatUser);
  if (users && isPage(resPage)) {
    return { users, page: resPage };
  }
  throw Error("failed to get users");
}

export async function getUserSubscriptions(
  uid: string,
  page?: number
): Promise<{ subscriptions: Subscription[]; page: Page }> {
  const c = await client();
  const res = await c.get(`/users/${uid}/subscriptions`, { params: { page } });
  const { subscriptions: resSubscriptions, page: resPage } = res.data;
  const subscriptions = typeFormatFromArray(
    resSubscriptions,
    formatSubscription
  );
  if (subscriptions && isPage(resPage)) {
    return { subscriptions, page: resPage };
  }
  throw Error("failed to get user subscriptions");
}

export async function getUserOrders(
  uid: string,
  page?: number
): Promise<{ orders: Order[]; page: Page }> {
  const c = await client();
  const res = await c.get(`/users/${uid}/orders`, { params: { page } });
  const { orders: resOrders, page: resPage } = res.data;
  const orders = typeFormatFromArray(resOrders, formatOrder);
  if (orders && isPage(resPage)) {
    return { orders, page: resPage };
  }
  throw Error("failed to get user orders");
}

export async function getUserAdminComments(
  uid: string,
  page?: number
): Promise<{ comments: AdminComment[]; page: Page }> {
  const c = await client();
  const res = await c.get(`/users/${uid}/adminComments`, { params: { page } });
  const { comments: resComments, page: resPage } = res.data;
  const comments = typeFormatFromArray(resComments, formatAdminComment);
  if (comments && isPage(resPage)) {
    return { comments, page: resPage };
  }
  throw Error("failed to get user admin comments");
}

export async function postUserAdminComment(
  uid: string,
  comment: string
): Promise<AdminComment> {
  const c = await client();
  const res = await c.post(`/users/${uid}/adminComments`, { comment });
  const adminComment = formatAdminComment(res.data);
  if (adminComment) {
    return adminComment;
  }
  throw Error("failed to post user admin comment");
}
