import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import type { Order } from "../../../models/order";
import type { User } from "../../../models/user";
import { getUser } from "../../../models/user";
import { invoicePage } from "../../../utils/stripe";

export default function DescriptionTable({ order }: { order: Order }) {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    getUser(order.uid).then(setUser).catch(console.error);
  }, [order.uid]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row" width="25%">
            ID
          </TableCell>
          <TableCell align="right">{order.orderID}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            UID
          </TableCell>
          <TableCell align="right">{order.uid}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            メールアドレス
          </TableCell>
          <TableCell align="right">{user?.email}</TableCell>
        </TableRow>
        {order.items.map(({ productID, productName }, index) => (
          <TableRow key={productID}>
            {index === 0 && (
              <TableCell
                component="th"
                scope="row"
                rowSpan={order.items.length}
              >
                購入商品
              </TableCell>
            )}
            <TableCell align="right">
              <Link component={RouterLink} to={`/products/${productID}`}>
                {productName}
              </Link>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell component="th" scope="row">
            カテゴリ
          </TableCell>
          <TableCell align="right">
            <Link
              component={RouterLink}
              to={`/categories/${order.category.categoryCode}`}
            >
              {order.category.categoryName}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            カリキュラム
          </TableCell>
          <TableCell align="right">
            <Link
              component={RouterLink}
              to={`/currs/${order.curriculum.currCode}`}
            >
              {order.curriculum.currName}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            注文日時
          </TableCell>
          <TableCell align="right">
            {format(order.orderTime, "yyyy-MM-dd HH:mm:ss", {
              locale: ja,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            支払い日時
          </TableCell>
          <TableCell align="right">
            {order.paidTime
              ? format(order.paidTime, "yyyy-MM-dd HH:mm:ss", {
                  locale: ja,
                })
              : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            キャンセル日時
          </TableCell>
          <TableCell align="right">
            {order.cancelTime
              ? format(order.cancelTime, "yyyy-MM-dd HH:mm:ss", {
                  locale: ja,
                })
              : "-"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th" scope="row">
            Stripe ID
          </TableCell>
          <TableCell align="right">
            <Link href={invoicePage(order.stripeInvoiceID)} target="_blank">
              {order.stripeInvoiceID}
            </Link>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
