import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import clsx from "clsx";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  text: {
    border: `0.5px solid ${grey[300]}`,
    borderRadius: 8,
    height: "8rem",
    overflow: "auto",
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  singleLine: {
    height: "3rem",
  },
  multiline: {
    height: "8rem",
  },
}));

export default function Viewer({
  description,
  onClick,
}: {
  description?: string;
  onClick: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="subtitle1" gutterBottom>
          概要
        </Typography>
        <Typography
          className={clsx(classes.text, classes.multiline)}
          variant="subtitle1"
          component="pre"
        >
          {description}
        </Typography>
      </div>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={onClick}
          >
            編集
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
