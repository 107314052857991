import { Button } from "@material-ui/core";
import amber from "@material-ui/core/colors/amber";
import Snackbar from "@material-ui/core/Snackbar";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { isBefore } from "date-fns";
import { useState } from "react";
import type { Coupon } from "../../../models/coupon";
import { expireCoupon } from "../../../models/coupon";
import ExpireDialog from "./Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  amberButton: {
    color: "white",
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor: amber[800],
    },
  },
}));

export default function Expire({
  coupon,
  onExpire,
}: {
  coupon: Coupon;
  onExpire: () => void;
}) {
  const classes = useStyles();

  const [error, setError] = useState<string>();

  const handleExpire = () => {
    expireCoupon(coupon.couponID).then(
      () => {
        handleClose();
        onExpire();
      },
      (e) => {
        setOpen(false);
        switch (e?.response?.status) {
          case 409:
            setError("このクーポンはすでに失効しています。");
            return;
          default:
            setError("エラーが発生し、削除に失敗しました。");
            break;
        }
      }
    );
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setError("");
  };

  return (
    <div>
      <Button
        className={classes.amberButton}
        size="small"
        variant="contained"
        onClick={handleClick}
        disabled={isBefore(coupon.expireTime, new Date())}
      >
        失効
      </Button>
      <ExpireDialog open={open} onClose={handleClose} onExpire={handleExpire} />
      <Snackbar open={!!error} onClose={handleSnackbarClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
