import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "../layouts/Dashboard";
import AdminUserList from "../views/AdminUserList";
import Category from "../views/Category";
import CategoryList from "../views/CategoryList";
import Coupon from "../views/Coupon";
import CouponList from "../views/CouponList";
import Course from "../views/Course";
import CourseDetail from "../views/CourseDetail";
import CourseSettings from "../views/CourseSettings";
import CurriculumDetails from "../views/CurriculumDetails";
import Deadline from "../views/Deadline";
import LessonDetails from "../views/LessonDetails";
import Login from "../views/Login";
import MusicTypeList from "../views/MusicTypeList";
import OrderList from "../views/OrderList";
import Play from "../views/Play";
import PlayList from "../views/PlayList";
import Post from "../views/Post";
import PostList from "../views/PostList";
import Product from "../views/Product";
import ProductList from "../views/ProductList";
import TeacherComment from "../views/Statistics/TeacherComment";
import SubmissionList from "../views/SubmissionList";
import SubscriptionList from "../views/SubscriptionList";
import Teacher from "../views/Teacher";
import TeacherList from "../views/TeacherList";
import User from "../views/User";
import UserList from "../views/UserList";

export default function Routes({ authenticated }: { authenticated: boolean }) {
  return (
    <BrowserRouter>
      {authenticated ? (
        <Dashboard>
          <Switch>
            <Route path="/categories" exact component={CategoryList} />
            <Route path="/categories/:code" exact component={Category} />
            <Route path="/categories/:code/:tab" component={Category} />
            <Route
              path="/currs/:currCode"
              exact
              component={CurriculumDetails}
            />
            <Route path="/currs/:currCode/:tab" component={CurriculumDetails} />
            <Route
              path="/courses/:code/settings"
              exact
              component={CourseSettings}
            />
            <Route path="/courses/:code" exact component={Course} />
            <Route
              path="/courses/:code/versions/:ver"
              exact
              component={CourseDetail}
            />
            <Route
              path="/courses/:code/versions/:ver/:tab"
              component={CourseDetail}
            />

            <Route path="/lessons/:lessonID/" exact component={LessonDetails} />
            <Route
              path="/lessons/:lessonID/:tab"
              exact
              component={LessonDetails}
            />
            <Route path="/plays" exact component={PlayList} />
            <Route path="/plays/:id" component={Play} />
            <Route path="/musicTypes" exact component={MusicTypeList} />
            <Route path="/teachers" exact component={TeacherList} />
            <Route path="/teachers/:id" component={Teacher} />
            <Route path="/submissions" component={SubmissionList} />
            <Route path="/deadlines" exact component={Deadline} />
            <Route
              path="/statistics/teachers/comments"
              exact
              component={TeacherComment}
            />
            <Route path="/posts" exact component={PostList} />
            <Route path="/posts/:id" exact component={Post} />
            <Route path="/subscriptions" exact component={SubscriptionList} />
            <Route path="/orders" exact component={OrderList} />
            <Route path="/coupons" exact component={CouponList} />
            <Route path="/coupons/:id" exact component={Coupon} />
            <Route path="/products" exact component={ProductList} />
            <Route path="/products/:id" exact component={Product} />
            <Route path="/admin/users" exact component={AdminUserList} />
            <Route path="/users" exact component={UserList} />
            <Route path="/users/:uid" exact component={User} />
          </Switch>
        </Dashboard>
      ) : (
        <Login />
      )}
    </BrowserRouter>
  );
}
