import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toJaName } from "../../../models/plan";
import type { Subscription } from "../../../models/subscriptions";
import Preview from "../Preview";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  subscriptions,
  onUpdate,
}: {
  className?: string;
  subscriptions: Subscription[];
  onUpdate?: () => void;
}) {
  const classes = useStyles();

  const [id, setId] = useState<number>();
  const handleClickOpen = (id: number) => () => {
    setId(id);
  };
  const handleClose = () => {
    setId(undefined);
  };

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="5%">ID</TableCell>
                <TableCell width="20%">UID</TableCell>
                <TableCell width="11%">プラン名</TableCell>
                <TableCell width="10%">カテゴリ名</TableCell>
                <TableCell width="10%">カリキュラム名</TableCell>
                <TableCell width="10%">開始日時</TableCell>
                <TableCell width="10%">キャンセル日時</TableCell>
                <TableCell width="10%">失効日時</TableCell>
                <TableCell width="4%">修了</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((s) => (
                <Fragment key={s.subscriptionID}>
                  <TableRow hover={true} key={s.subscriptionID}>
                    <TableCell>
                      <Typography noWrap>{s.subscriptionID}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{s.uid}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography noWrap>{toJaName(s.plan)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/categories/${s.category.categoryCode}`}
                      >
                        {s.category.categoryName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/currs/${s.curriculum.currCode}`}
                      >
                        {s.curriculum.currName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {format(s.startTime, "yyyy-MM-dd HH:mm:ss", {
                        locale: ja,
                      })}
                    </TableCell>
                    <TableCell>
                      {s.cancel
                        ? format(s.cancel.cancelTime, "yyyy-MM-dd HH:mm:ss", {
                            locale: ja,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {s.cancel
                        ? format(s.cancel.expireTime, "yyyy-MM-dd HH:mm:ss", {
                            locale: ja,
                          })
                        : "-"}
                    </TableCell>
                    <TableCell>{s.completed ? "✅" : "-"}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={handleClickOpen(s.subscriptionID)}
                        color="primary"
                        size="small"
                        variant="contained"
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                  {id === s.subscriptionID && (
                    <Preview
                      subscription={s}
                      open
                      onClose={handleClose}
                      onCancel={onUpdate}
                    />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
