import React, { useState, useEffect } from "react";
import { getPlay, Play, PlayInput, updatePlay } from "../../../models/play";
import Viewer from "./Viewer";
import Editor from "./Editor";
import Typography from "@material-ui/core/Typography";

export default function General({ id }: { id: number }) {
  const [edit, setEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [play, setPlay] = useState<Play>();

  const loadPlay = (id: number) => getPlay(id).then(setPlay, console.error);

  useEffect(() => {
    loadPlay(id).finally(() => setLoading(false));
  }, [id]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleOnSave = (data: PlayInput) => {
    setIsSaving(true);
    updatePlay(id, data)
      .then(
        () => {
          toggleEdit();
          loadPlay(id);
        },
        (e) => setSaveError(`${e}`)
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (loading) return null;

  return (
    <div>
      {edit ? (
        <Editor
          defaultValues={{
            name: play?.playName || "",
            categoryCode: play?.category.categoryCode || "",
          }}
          onSave={handleOnSave}
          onCancel={toggleEdit}
          isSaving={isSaving}
          saveError={saveError}
        />
      ) : (
        <Viewer play={play} onClick={toggleEdit} />
      )}
      <Typography variant="subtitle1">外部公開用URL</Typography>
      <Typography variant="subtitle2">
        {`https://music.phonim.com/plays/${play?.playSlug || ""}`}
      </Typography>
    </div>
  );
}
