import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import {
  CategoryPatchInput,
  CATEGORY_CODE_MAX_LEN,
  CATEGORY_NAME_MAX_LEN,
} from "../../../models/category";
import {
  alphaNumPattern,
  maxLengthRule,
  requiredMessage,
} from "../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 400,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
  container: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginTop: theme.spacing(3),
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function Form({
  defaultValues,
  open,
  onSubmit,
  onClose,
  isSaving,
  saveError,
}: {
  defaultValues: CategoryPatchInput;
  open: boolean;
  onSubmit: (input: CategoryPatchInput) => void;
  onClose: () => void;
  isSaving: boolean;
  saveError?: string;
}) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<CategoryPatchInput>({
    defaultValues,
  });

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            カテゴリの編集
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {saveError && <Typography color="error">{saveError}</Typography>}
            <div className={classes.container}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(CATEGORY_CODE_MAX_LEN),
                  pattern: alphaNumPattern,
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="カテゴリコード"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
            <div className={classes.container}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(CATEGORY_NAME_MAX_LEN),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="カテゴリ名"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                size="small"
                variant="contained"
                disabled={isSaving}
                onClick={onClose}
              >
                キャンセル
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSaving}
              >
                保存
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
