import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProviderIcon from "../../components/ProviderIcon";
import { getUser, User } from "../../models/user";
import Comment from "./Comment";
import OrderList from "./OrderList";
import SubscriptionList from "./SubscriptionList";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& > *": {
      marginTop: theme.spacing(4),
    },
  },
}));

export default function UserView() {
  const classes = useStyles();

  const { uid } = useParams<{
    uid?: string;
  }>();

  const [user, setUser] = useState<User>();
  useEffect(() => {
    if (!uid) return;
    getUser(uid).then(setUser, console.error);
  }, [uid]);

  if (!user) return null;

  return (
    <Container className={classes.root}>
      <section id="customer">
        <Typography variant="h2" component="h1" gutterBottom>
          顧客
        </Typography>
        <Paper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  UID
                </TableCell>
                <TableCell align="right">{user.uid}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  メールアドレス
                </TableCell>
                <TableCell align="right">{user.email || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  プロバイダ
                </TableCell>
                <TableCell align="right">
                  {Array.from(new Set(user.providers)).map((p) => (
                    <ProviderIcon key={p} provider={p} />
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  アカウント作成日時
                </TableCell>
                <TableCell align="right">
                  {format(
                    user.userMetadata.creationTime,
                    "yyyy-MM-dd HH:mm:ss",
                    {
                      locale: ja,
                    }
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  最終ログイン日時
                </TableCell>
                <TableCell align="right">
                  {format(
                    user.userMetadata.lastLogInTime,
                    "yyyy-MM-dd HH:mm:ss",
                    {
                      locale: ja,
                    }
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </section>
      <section id="subscriptions">
        <Typography variant="h2" gutterBottom>
          サブスクリプション
        </Typography>
        <SubscriptionList uid={user.uid} />
      </section>
      <section id="orders">
        <Typography variant="h2" gutterBottom>
          購入履歴
        </Typography>
        <OrderList uid={user.uid} />
      </section>
      <section id="comments">
        <Typography variant="h2" gutterBottom>
          コメント
        </Typography>
        <Comment uid={user.uid} />
      </section>
    </Container>
  );
}
