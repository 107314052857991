import type { SvgIconProps } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default function Visibility({
  invisible,
  svgIconProps,
}: {
  invisible: boolean;
  svgIconProps?: SvgIconProps;
}) {
  return invisible ? (
    <VisibilityOffIcon fontSize="small" color="action" {...svgIconProps} />
  ) : (
    <VisibilityIcon fontSize="small" color="action" {...svgIconProps} />
  );
}
