import { makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { lightGreen } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import CommentIcon from "@material-ui/icons/Comment";
import FavoriteIcon from "@material-ui/icons/Favorite";
import clsx from "clsx";
import type { Post } from "../../models/post";
import MediaPlayer from "../MediaPlayer";
import MediaImage from "./MediaImage";

const formatCount = (count: number): string =>
  Intl.NumberFormat("ja-JP", {
    notation: "compact",
  })
    .format(count)
    .toString();

const useStyles = makeStyles((theme: Theme) => ({
  audio: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3),
    backgroundColor: lightGreen[400],
  },
  player: {
    width: "100%",
  },
  text: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },
  indicator: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export default function PostCard({
  post,
  elevation,
  className,
}: {
  post: Post;
  elevation?: number;
  className?: string;
}) {
  const classes = useStyles();
  const { images, audio, contentType, text, profile, statistics } = post;

  const isAudio: boolean = contentType ? contentType.includes("audio/") : false;
  return (
    <Card className={className} elevation={elevation}>
      {audio && contentType && (
        <CardMedia className={clsx(isAudio && classes.audio)}>
          <MediaPlayer
            className={classes.player}
            audio={audio}
            contentType={contentType}
          />
        </CardMedia>
      )}
      <MediaImage images={images} />
      <CardContent>
        <Box p={2}>
          <Typography component="pre" className={classes.text}>
            {text}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box display="flex" alignItems="center">
              <Box mx={1}>
                <Typography component="span">
                  <b>{profile?.nickname}</b>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.indicator} display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="center">
              <FavoriteIcon color="error" />
              <Typography
                component="span"
                align="center"
                color="textSecondary"
                noWrap
              >
                {formatCount(statistics.likeCount)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CommentIcon color="action" />
              <Typography
                component="span"
                align="center"
                color="textSecondary"
                noWrap
              >
                {formatCount(statistics.commentCount)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
