import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useEffect, useState } from "react";
import {
  getTeacherCommentStat,
  TeacherCommentStat,
} from "../../models/statistics";
import Header from "./Header";
import { add, format, parse } from "date-fns";
import Progress from "../../components/Progress";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: "100%",
    margin: "0 auto",
    padding: theme.spacing(3),
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  table: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

export default function TeacherComment() {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const [value, setValue] = useState<string>(format(new Date(), "yyyy-MM"));
  const [statistics, setStatistics] = useState<TeacherCommentStat[]>([]);

  useEffect(() => {
    setLoading(true);
    const from = parse(value, "yyyy-MM", new Date());
    const to = add(from, { months: 1 });
    getTeacherCommentStat(from, to)
      .then(setStatistics)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [value]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  return (
    <div className={classes.root}>
      <Header title="コメント集計" />
      <Box>
        <Typography component="label" htmlFor="month">
          集計月：
        </Typography>
        <input
          id="month"
          name="month"
          type="month"
          min="2020-04"
          max={format(new Date(), "yyyy-MM")}
          required
          value={value}
          onChange={handleChange}
        />
      </Box>
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="20%">講師ID</TableCell>
                <TableCell width="40%">講師名</TableCell>
                <TableCell width="40%">コメント数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statistics.length > 0 ? (
                statistics.map((stat) => (
                  <TableRow key={stat.teacher.teacherID}>
                    <TableCell>{stat.teacher.teacherID}</TableCell>
                    <TableCell>{`${stat.teacher.familyName} ${stat.teacher.firstName}`}</TableCell>
                    <TableCell>{stat.commentNum}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography color="textSecondary">
                      講師のコメントはありません
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <Box className={classes.progress}>
            <Progress />
          </Box>
        )}
      </div>
    </div>
  );
}
