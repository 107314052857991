import * as colors from "@material-ui/core/colors";
import Label from "../Label";

const statusColors = {
  draft: colors.grey[600],
  published: colors.green[600],
};

const statusWords = {
  draft: "準備中",
  published: "公開中",
};

export default function CourseStatusLabel({
  published,
}: {
  published: boolean;
}) {
  const status = published ? "published" : "draft";
  return (
    <Label color={statusColors[status]} variant="outlined">
      {statusWords[status]}
    </Label>
  );
}
