import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format, isBefore } from "date-fns";
import ja from "date-fns/locale/ja";
import { useHistory } from "react-router-dom";
import type { Coupon } from "../../../models/coupon";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: "scroll",
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  table: {
    tableLayout: "fixed",
    width: "100%",
  },
}));

export default function Table({
  className,
  coupons,
}: {
  className?: string;
  coupons: Coupon[];
}) {
  const classes = useStyles();

  const history = useHistory();
  const handleClick = (id: number) => () => {
    history.push(`/coupons/${id}`);
  };

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell width="10%">ID</TableCell>
                <TableCell width="45%">クーポン名</TableCell>
                <TableCell width="10%">期間</TableCell>
                <TableCell width="10%">割引額</TableCell>
                <TableCell width="15%">失効日時</TableCell>
                <TableCell width="10%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((coupon) => (
                <TableRow hover={true} key={coupon.couponID}>
                  <TableCell>
                    <Typography noWrap>{coupon.couponID}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{coupon.couponName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>
                      {coupon.durationInMonths}ヶ月
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>
                      {new Intl.NumberFormat("ja-JP", {
                        style: "currency",
                        currency: "JPY",
                      }).format(coupon.amountOff)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color={
                        isBefore(coupon.expireTime, new Date())
                          ? "error"
                          : "textPrimary"
                      }
                    >
                      {format(coupon.expireTime, "yyyy-MM-dd HH:mm:ss", {
                        locale: ja,
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={handleClick(coupon.couponID)}
                      color="primary"
                      size="small"
                      variant="contained"
                    >
                      詳細
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
