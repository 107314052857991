import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import {
  getCourseImage,
  getCourseTeachers,
  updateCourseImage,
  updateCourseTeacher,
} from "../../../models/course";
import { getTeachers } from "../../../models/teacher";
import Description from "./Description";
import ImageSelect from "../../../components/ImageSelect";
import TeacherSelect from "../../../components/TeacherSelect";

type Teacher = import("../../../models/teacher").Teacher;

export default function Overview({
  courseCode,
  courseVer,
}: {
  courseCode: string;
  courseVer: number;
}) {
  const [image, setImage] =
    useState<import("../../../models/media").CloudinaryImage>();
  const saveImageID = (id: number) =>
    updateCourseImage(courseCode, courseVer, id)
      .then(() => getCourseImage(courseCode, courseVer))
      .then(setImage);

  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const saveTeachers = (ids: number[]) =>
    updateCourseTeacher(courseCode, courseVer, ids)
      .then(() => getCourseTeachers(courseCode, courseVer))
      .then(setTeachers);

  useEffect(() => {
    setTeachers([]);
    setImage(undefined);
    getCourseTeachers(courseCode, courseVer).then(setTeachers, console.error);
    getCourseImage(courseCode, courseVer).then(setImage, console.error);
  }, [courseCode, courseVer]);

  const [teacherList, setTeacherList] = useState<Teacher[]>([]);
  useEffect(() => {
    getTeachers().then(setTeacherList, console.error);
  }, []);

  return (
    <Card>
      <CardContent>
        <Box mb={1}>
          <Typography variant="h5" gutterBottom>
            <b>導入</b>
          </Typography>
          <Description courseCode={courseCode} courseVer={courseVer} />
        </Box>
        <Box mb={3}>
          <Typography variant="h5" gutterBottom>
            <b>ヘッダー画像</b>
          </Typography>
          <ImageSelect image={image} saveImageID={saveImageID} />
        </Box>
        <Box>
          <Typography variant="h5" gutterBottom>
            <b>担当講師</b>
          </Typography>
          <TeacherSelect
            selectedTeachers={teachers}
            teachers={teacherList}
            save={saveTeachers}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
