import { Button, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useCallback, createRef } from "react";
import AudioPlayer from "../../../components/AudioPlayer";
import { getAudioURL } from "../../../models/media";
import { Music } from "../../../models/play";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
    },
  })
);

type RenderType = {
  jump: (time: number) => void;
};

export default function Song({
  music,
  isUploading,
  onUpload,
  onDelete,
  render,
}: {
  music?: Music;
  isUploading: boolean;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  render?: (props: RenderType) => React.ReactNode;
}) {
  const classes = useStyles();

  const audioRef = createRef<HTMLAudioElement>();
  const jump = useCallback(
    (time: number) => {
      if (audioRef.current) {
        audioRef.current.currentTime = time;
      }
    },
    [audioRef]
  );

  const ref = createRef<HTMLInputElement>();
  const handleUpload = () => {
    if (ref?.current) {
      const { current } = ref;
      current.value = ""; // 削除後など再アップロードできるよう（changeイベントが発火されるよう）一度空にする
      current.click();
    }
  };

  const handleDelete = () => {
    if (!window.confirm("音源を削除してもよろしいですか?")) return;
    onDelete();
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        {music ? (
          <AudioPlayer
            ref={audioRef}
            key={music.audio.cldnAudioID}
            audios={["mp4", "webm", "acc"].map((ext) => ({
              src: getAudioURL(music.audio, ext),
              type: `audio/${ext}`,
            }))}
          />
        ) : (
          <Typography>音源の登録がありません</Typography>
        )}
        <Box ml={2}>
          <Button
            color="primary"
            onClick={handleUpload}
            size="small"
            variant="contained"
            disabled={isUploading}
          >
            アップロード
          </Button>
          <Box display="none">
            <input ref={ref} type="file" accept="audio/*" onChange={onUpload} />
          </Box>
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            size="small"
            disabled={!music || isUploading}
          >
            削除
          </Button>
        </Box>
      </Box>
      {render && <Box mt={2}>{render({ jump })}</Box>}
    </Box>
  );
}
