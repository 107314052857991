import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Divider from "@material-ui/core/Divider";
import grey from "@material-ui/core/colors/grey";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Progress from "../../components/Progress/Progress";
import { getCurrCourses } from "../../models/course";
import { getCurr, getCurrCompleted } from "../../models/curriculum";
import Header from "./Header";
import Course from "./Course";
import Settings from "./Settings";
import Description from "./Description";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

type Curriculum = import("../../models/curriculum").Curriculum;

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    tabs: {
      marginTop: theme.spacing(3),
    },
    divider: {
      backgroundColor: grey[300],
    },
    content: {
      marginTop: theme.spacing(3),
    },
  })
);

export default function CurriculumDetail() {
  const classes = useStyles();

  const { currCode, tab } = useParams<{
    currCode: string;
    tab?: string;
  }>();
  const history = useHistory();

  const [curr, setCurr] = useState<Curriculum | null>(null);
  const [courseCodes, setCourseCodes] = useState<string[]>([]);
  const [currCompleted, setCurrCompleted] = useState<boolean>();
  useEffect(() => {
    if (currCode) {
      getCurr(currCode).then(setCurr, console.error);
      getCurrCourses(currCode).then(setCourseCodes, console.error);
      getCurrCompleted(currCode).then(
        (res) => setCurrCompleted(res.completed),
        console.error
      );
    }
  }, [currCode]);

  const handleTabsChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (typeof value === "string") {
      history.push(`/currs/${currCode}/${value}`);
    }
  };

  if (curr === null || currCompleted === undefined) {
    return <Progress />;
  }

  const tabName = tab || "courses";

  const tabs = [
    {
      value: "courses",
      label: "コース",
      component: (
        <Course
          currCode={currCode}
          completed={currCompleted}
          courseCodes={courseCodes}
        />
      ),
    },
    {
      value: "description",
      label: "概要",
      component: <Description currCode={currCode} />,
    },
    {
      value: "header",
      label: "ヘッダ情報",
      component: <Header currCode={currCode} />,
    },
    { value: "settings", label: "設定", component: <Settings /> },
  ];

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            カリキュラム
          </Typography>
          <Typography component="h1" variant="h3">
            {curr.currName}
          </Typography>
        </Grid>
      </Grid>

      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tabName}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        {tabs.find((t) => t.value === tabName)?.component}
      </div>
    </div>
  );
}
