import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import PaymentIcon from "@material-ui/icons/Payment";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import WidgetsIcon from "@material-ui/icons/Widgets";
import clsx from "clsx";
import { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navigation from "../../../components/Navigation";
import useMyRole from "../../../hooks/useMyRole";
import { getUser } from "../../../models/auth";
import permissions from "../../../models/permission";

type Category = import("../../../models/category").Category;

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}));

export default function NavBar({
  openMobile,
  onMobileClose,
  categories,
  className,
}: {
  openMobile: boolean;
  onMobileClose?: () => void;
  categories: Category[];
  className?: string;
}) {
  const classes = useStyles();
  const location = useLocation();

  const { role } = useMyRole();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const categoryListPages = [
    {
      title: "カテゴリ一覧",
      href: "/categories",
      icon: CategoryIcon,
    },
  ];

  const categoryPages = categories.map((x) => ({
    title: x.categoryName,
    href: "/",
    icon: MusicNoteIcon,
    children: [
      {
        title: "カリキュラム",
        href: `/categories/${x.categoryCode}`,
      },
    ],
  }));

  const playPages = [
    {
      title: "演奏",
      href: "/plays",
      icon: PlayCircleFilledIcon,
    },
  ];

  const deadlinePages = [
    {
      title: "入稿期限",
      href: "/deadlines",
      icon: EventIcon,
    },
  ];

  const homeworkPages = [
    {
      title: "フィードバック",
      href: "/submissions",
      icon: HeadsetMicIcon,
    },
    {
      title: "コメント集計",
      href: "/statistics/teachers/comments",
      icon: ShowChartIcon,
    },
  ];

  const postPages = [
    {
      title: "自由投稿",
      href: "/posts",
      icon: DescriptionIcon,
    },
  ];

  const contractPages = [
    {
      title: "サブスクリプション",
      href: "/subscriptions",
      icon: PaymentIcon,
    },
    {
      title: "購入履歴",
      href: "/orders",
      icon: ReceiptIcon,
    },
  ];

  const customerManagePages = [
    {
      title: "顧客",
      href: "/users",
      icon: PersonIcon,
    },
  ];

  const productPages = [
    {
      title: "商品",
      href: "/products",
      icon: WidgetsIcon,
      disabled: !permissions.products.has(role),
    },
    {
      title: "クーポン",
      href: "/coupons",
      icon: LocalOfferIcon,
      disabled: !permissions.coupons.has(role),
    },
  ];

  const settingPages = [
    {
      title: "講師",
      href: "/teachers",
      icon: PeopleIcon,
    },
  ];

  const adminPages = [
    {
      title: "アカウント",
      href: "/admin/users",
      icon: SupervisorAccountIcon,
      disabled: !permissions.admin.has(role),
    },
  ];

  const user = getUser();

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt={user.displayName || "メンバー"}
          className={classes.avatar}
          src={user.photoURL || "/images/logos/phonim_logo_RGB.svg"}
        />
        <Typography className={classes.name} variant="h4">
          {user.displayName || "フォニム・メンバー"}
        </Typography>
        <Typography variant="body2">{user.email}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        <Navigation pages={categoryListPages} title={"カテゴリ"} />
        <Navigation pages={categoryPages} title={"入稿"} />
        <Navigation pages={playPages} title={"演奏"} />
        <Navigation pages={deadlinePages} title={"入稿期限"} />
        <Navigation pages={homeworkPages} title={"Homework"} />
        <Navigation pages={postPages} title={"自由投稿"} />
        <Navigation pages={contractPages} title={"契約履歴"} />
        <Navigation pages={customerManagePages} title={"顧客管理"} />
        <Navigation pages={productPages} title={"商品"} />
        <Navigation pages={settingPages} title={"設定"} />
        <Navigation pages={adminPages} title={"管理者"} />
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div className={clsx(classes.root, className)}>{navbarContent}</div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
}
