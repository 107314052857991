export const formatTime = (time: number): string => {
  const t = Math.floor(time);
  const hour = Math.floor(t / 3600);
  const min = Math.floor((t - hour * 3600) / 60);
  const sec = t - hour * 3600 - min * 60;
  let res = [min, sec];
  if (hour > 0) {
    res = [hour, ...res];
  }
  return res.map((x) => (x < 10 ? `0${x}` : x)).join(":");
};

export const parseTime = (time: string): number => {
  const s = time.split(":");
  const t = s.map((x) => parseInt(x, 10));
  switch (s.length) {
    case 1:
      return t[0];
    case 2:
      return t[0] * 60 + t[1];
    case 3:
      return t[0] * 60 * 60 + t[1] * 60 + t[2];
    default:
      throw new Error("Invalid data");
  }
};
