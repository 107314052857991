import { CardContent } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Form, { FromProps } from "./Form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
}));

export type EditorProps = FromProps & {
  onCancel: () => void;
  open: boolean;
};

export default function Editor({
  features,
  onSave,
  onCancel,
  isSaving,
  saveError,
  open,
}: EditorProps) {
  const classes = useStyles();
  return (
    <Modal onClose={onCancel} open={open}>
      <Card className={classes.root}>
        <CardHeader title="機能の追加" />
        <CardContent>
          <Form
            features={features}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={isSaving}
            saveError={saveError}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
