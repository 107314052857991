import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  CouponInput,
  NAME_MAX_LEN,
  postCoupon,
  STRIPE_COUPON_ID_MAX_LEN,
} from "../../../models/coupon";
import {
  maxLengthRule,
  numberPattern,
  requiredMessage,
} from "../../../utils/form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 700,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    container: {
      marginTop: theme.spacing(2),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
  })
);

const defaultValues: CouponInput = {
  name: "",
  stripeCouponID: "",
  durationInMonths: 0,
  amountOff: 0,
  expireTime: "",
};

function formatInput(input: CouponInput): CouponInput {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  const durationInMonths = toInt(input.durationInMonths);
  const amountOff = toInt(input.amountOff);
  const expireTime = new Date(input.expireTime).toISOString();
  return {
    ...input,
    durationInMonths,
    amountOff,
    expireTime,
  };
}

export default function Form({
  open,
  onClose,
  className,
}: {
  open: boolean;
  onClose: () => void;
  className?: string;
}) {
  const classes = useStyles();

  const history = useHistory();

  const { handleSubmit, control } = useForm<CouponInput>({
    defaultValues,
  });

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>("");
  const onSubmit = (data: CouponInput) => {
    setSubmitting(true);
    postCoupon(formatInput(data))
      .then(({ couponID }) => {
        history.push(`/coupons/${couponID}`);
      })
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          setError(
            "不正な入力です。クーポン名やクーポンIDがすでに使用されている可能性があります"
          );
        } else {
          console.error(e);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={clsx(classes.root, className)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              クーポン追加
            </Typography>
            {error && (
              <Box my={2}>
                <Typography component="span" color="error">
                  {error}
                </Typography>
              </Box>
            )}
            <Grid className={classes.container} container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    maxLength: maxLengthRule(NAME_MAX_LEN),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      label="クーポン名"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="stripeCouponID"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    maxLength: maxLengthRule(STRIPE_COUPON_ID_MAX_LEN),
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      label="Stripe クーポン ID"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="durationInMonths"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    pattern: numberPattern,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      label="期間"
                      inputMode="numeric"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">ヶ月</InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="amountOff"
                  control={control}
                  rules={{
                    required: requiredMessage,
                    pattern: numberPattern,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      label="割引額"
                      inputMode="numeric"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">¥</InputAdornment>
                        ),
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="expireTime"
                  control={control}
                  rules={{
                    required: requiredMessage,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      label="失効日時"
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={onClose} variant="contained">
              閉じる
            </Button>
            <Button
              className={classes.saveButton}
              // onClick={handleSave}
              disabled={submitting}
              type="submit"
              variant="contained"
            >
              新規作成
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
