import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Link as RouterLink } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
  title: {
    color: "white",
  },
}));

export default function Topbar() {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <Typography className={classes.title} variant="h3" color="initial">
            PHONIM ADMIN
          </Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}
