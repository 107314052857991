import * as colors from "@material-ui/core/colors";
import Label from "../Label/Label";
import { Fragment } from "react";

const red = colors.red[600];

export default function TagLabels({
  tag,
}: {
  tag: import("../../models/tag").Tag;
}) {
  return (
    <Fragment>
      {tag.categoryCode !== undefined && (
        <Label color={red} variant="outlined">
          {tag.categoryCode}
        </Label>
      )}
      {tag.lessonOrder !== undefined && (
        <Label color={red} variant="outlined">
          {`L${(tag.lessonOrder + 1).toString().padStart(2, "0")}`}
        </Label>
      )}
    </Fragment>
  );
}
