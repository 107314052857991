import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import Snackbar from "@material-ui/core/Snackbar";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteCoupon } from "../../../models/coupon";
import DeleteDialog from "./Dialog";

const useStyles = makeStyles((theme: Theme) => ({
  redButton: {
    color: "white",
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[900],
    },
  },
}));

export default function Delete({ id }: { id: number }) {
  const classes = useStyles();
  const [error, setError] = useState<string>();

  const history = useHistory();
  const handleDelete = () => {
    deleteCoupon(id).then(
      () => {
        history.replace("/coupons");
      },
      (e) => {
        setOpen(false);
        switch (e?.response?.status) {
          case 422:
            setError(
              "クーポンを削除できませんでした。このクーポンは使用されています。"
            );
            return;
          default:
            setError("エラーが発生し、削除に失敗しました。");
            break;
        }
      }
    );
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setError("");
  };

  return (
    <div>
      <Typography variant="subtitle1">クーポンの削除</Typography>
      <Box mt={1}>
        <Button
          className={classes.redButton}
          variant="contained"
          onClick={handleClick}
        >
          削除する
        </Button>
      </Box>
      <DeleteDialog open={open} onClose={handleClose} onDelete={handleDelete} />
      <Snackbar open={!!error} onClose={handleSnackbarClose}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
