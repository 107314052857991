export function typeFormatFromArray<T>(
  xs: unknown,
  formatter: (x: unknown) => T | null
): T[] | null {
  if (!Array.isArray(xs)) {
    return null;
  }
  const ret: T[] = [];
  for (const x of xs) {
    const y = formatter(x);
    if (y === null) {
      return null;
    }
    ret.push(y);
  }
  return ret;
}

export function isObject(x: unknown): x is object {
  return x !== null && typeof x === "object";
}
