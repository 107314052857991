import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getCategoryLessons } from "../../models/category";
import {
  buildQueryParams,
  getSubmissions,
  parseQueryParams,
} from "../../models/homework";
import Filter from "./Filter";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(3),
    },
    hasPrevNext: {
      justifyContent: "space-between",
    },
    hasNext: {
      justifyContent: "flex-end",
    },
  })
);

export default function SubmissionList() {
  const classes = useStyles();

  const { pathname, search } = useLocation();
  const params = parseQueryParams(search);
  const {
    categoryCode,
    courseCode,
    courseVer,
    lessonID,
    noCommentOnly,
    uid,
    sortID,
    disabledComment,
  } = params;
  const [submissions, setSubmissions] = useState<
    import("../../models/homework").Submission[]
  >([]);
  const [cursor, setCursor] =
    useState<import("../../models/homework").Cursor>();
  useEffect(() => {
    getSubmissions({
      categoryCode,
      courseCode,
      courseVer,
      lessonID,
      uid,
      sortID,
      noCommentOnly,
      disabledComment,
    }).then(({ submissions, cursor }) => {
      setSubmissions(submissions);
      setCursor(cursor);
    }, console.error);
  }, [
    categoryCode,
    courseCode,
    courseVer,
    lessonID,
    uid,
    sortID,
    noCommentOnly,
    disabledComment,
  ]);
  const reload = () =>
    getSubmissions({
      categoryCode,
      courseCode,
      courseVer,
      lessonID,
      uid,
      sortID,
      noCommentOnly,
      disabledComment,
    }).then(({ submissions, cursor }) => {
      setSubmissions(submissions);
      setCursor(cursor);
    }, console.error);

  const [lessonToCategory, setLessonToCategory] = useState<Map<number, string>>(
    new Map()
  );
  useEffect(() => {
    getCategoryLessons().then((xs) => {
      const m = new Map<number, string>();
      xs.forEach((x) => {
        m.set(x.lessonID, x.categoryCode);
      });
      setLessonToCategory(m);
    });
  }, []);

  const history = useHistory();
  const handleFilter = (
    params: import("../../models/homework").QueryParams
  ) => {
    const p = buildQueryParams(params);
    history.push(`${pathname}?${p.toString()}`);
    handleFilterClose();
  };

  const [open, setOpen] = useState(false);
  const handleFilterOpen = () => {
    setOpen(true);
  };
  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    if (cursor?.next) {
      handleFilter({
        categoryCode,
        courseCode,
        courseVer,
        lessonID,
        sortID: cursor.next,
      });
    }
  };
  const handlePrev = () => {
    if (cursor?.prev) {
      handleFilter({
        categoryCode,
        courseCode,
        courseVer,
        lessonID,
        sortID: cursor.prev,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            Homeworkフィードバック
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={handleFilterOpen}
            color="primary"
            variant="contained"
          >
            絞り込み
          </Button>
        </Grid>
      </Grid>
      <Table
        className={classes.results}
        submissions={submissions}
        lessonToCategory={lessonToCategory}
        reload={reload}
      />
      {cursor && (
        <div
          className={clsx(classes.buttonContainer, {
            [classes.hasPrevNext]: cursor.prev && cursor.next,
            [classes.hasNext]: !cursor.prev && cursor.next,
          })}
        >
          {cursor.prev && (
            <Button color="default" variant="contained" onClick={handlePrev}>
              前へ
            </Button>
          )}
          {cursor.next && (
            <Button color="default" variant="contained" onClick={handleNext}>
              次へ
            </Button>
          )}
        </div>
      )}
      <Filter
        onClose={handleFilterClose}
        onFilter={handleFilter}
        open={open}
        params={params}
      />
    </div>
  );
}
