import { client } from "../utils/axios";

export type Feature = {
  featureID: number;
  featureName: string;
  teacherComment: boolean;
};

export function isFeature(x: unknown): x is Feature {
  return (
    typeof x === "object" &&
    typeof (x as Feature).featureID === "number" &&
    typeof (x as Feature).featureName === "string" &&
    typeof (x as Feature).teacherComment === "boolean"
  );
}

export function isFeatureArray(xs: unknown): xs is Feature[] {
  return Array.isArray(xs) && xs.every(isFeature);
}

export const STANDARD_PLAN = "standard";
export const ENTRY_PLAN = "entry";

export function toJaName(x: Feature) {
  switch (x.featureName) {
    case STANDARD_PLAN:
      return "スタンダード";
    case ENTRY_PLAN:
      return "エントリー";
    default:
      console.warn(`Unknown feature name: ${x.featureName}`);
      return x.featureName;
  }
}

export async function getFeatures(): Promise<Feature[]> {
  const c = await client();
  const { data } = await c.get("/features");
  const { features } = data;
  if (isFeatureArray(features)) {
    return features;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}
