import { client } from "../utils/axios";
import * as media from "./media";
import type { Product } from "./product";
import { isProductArray } from "./product";

export type Curriculum = {
  currCode: string;
  currName: string;
};

export function isCurriculum(x: any): x is Curriculum {
  return x && typeof x.currCode === "string" && typeof x.currName === "string";
}

export function isCurriculumArray(xs: unknown): xs is Curriculum[] {
  return Array.isArray(xs) && xs.every(isCurriculum);
}

export async function getCurrs(): Promise<Curriculum[]> {
  const c = await client();
  const { data } = await c.get("/currs");
  const { currs } = data;
  if (isCurriculumArray(currs)) {
    return currs;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function getCurr(currCode: string) {
  const c = await client();
  const res = await c.get(`/currs/${currCode}`);
  const data = res.data;
  if (isCurriculum(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export type ImageAndDescription = {
  image: media.CloudinaryImage;
  description: string;
};

function isImageAndDescription(x: unknown): x is ImageAndDescription {
  return (
    typeof x === "object" &&
    media.isCloudinaryImage((x as ImageAndDescription).image) &&
    typeof (x as ImageAndDescription).description === "string"
  );
}

export type Description = {
  title: string;
  headerImage: media.CloudinaryImage;
  lead: string;
  subtitle: string;
  body: string;
  vimeoID?: number;
  whatToGet: ImageAndDescription[];
  whatToLearn: ImageAndDescription[];
};

function isDescription(x: unknown): x is Description {
  return (
    typeof x === "object" &&
    typeof (x as Description).title === "string" &&
    media.isCloudinaryImage((x as Description).headerImage) &&
    typeof (x as Description).lead === "string" &&
    typeof (x as Description).subtitle === "string" &&
    typeof (x as Description).body === "string" &&
    ((x as Description).vimeoID === undefined ||
      typeof (x as Description).vimeoID === "number") &&
    Array.isArray((x as Description).whatToGet) &&
    (x as Description).whatToGet.every((w) => isImageAndDescription(w)) &&
    Array.isArray((x as Description).whatToLearn) &&
    (x as Description).whatToLearn.every((w) => isImageAndDescription(w))
  );
}

export async function getDescription(currCode: string): Promise<Description> {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/descriptions`);
  const {
    data: { description },
  } = res;
  if (isDescription(description)) {
    return description;
  }
  throw Error(`invalid data: ${JSON.stringify(description)}`);
}

export async function updateDescription(currCode: string, data: Description) {
  const c = await client();
  return c.post(`/currs/${currCode}/descriptions`, data);
}

export type Header = {
  title: string;
  image: media.CloudinaryImage;
  thumbnail: media.CloudinaryImage;
  description: string;
  admissionTexts: string[];
  purchaseTopImage: media.CloudinaryImage;
};

function isHeader(x: unknown): x is Header {
  return (
    typeof x === "object" &&
    typeof (x as Header).title === "string" &&
    media.isCloudinaryImage((x as Header).image) &&
    media.isCloudinaryImage((x as Header).thumbnail) &&
    typeof (x as Header).description === "string" &&
    Array.isArray((x as Header).admissionTexts) &&
    (x as Header).admissionTexts.every((t) => typeof t === "string") &&
    media.isCloudinaryImage((x as Header).purchaseTopImage)
  );
}

export async function getHeader(currCode: string): Promise<Header> {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/headers`);
  const {
    data: { header },
  } = res;
  if (isHeader(header)) {
    return header;
  }
  throw Error(`invalid data: ${JSON.stringify(header)}`);
}

export async function updateHeader(currCode: string, data: Header) {
  const c = await client();
  return c.post(`/currs/${currCode}/headers`, data);
}

export type Published = {
  isPublished: boolean;
};

function isPublished(x: unknown): x is Published {
  return (
    typeof x === "object" && typeof (x as Published).isPublished === "boolean"
  );
}

export async function getCurrPublish(currCode: string): Promise<Published> {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/publish`);
  const data = res.data;
  if (isPublished(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function postCurrPublish(currCode: string) {
  const c = await client();
  return c.post(`/currs/${currCode}/publish`);
}

export async function getCurrCompleted(currCode: string) {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/completed`);
  const data = res.data;
  if (isCurriculumCompleted(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export type CurriculumCompleted = {
  completed: boolean;
};

function isCurriculumCompleted(x: unknown): x is CurriculumCompleted {
  return (
    typeof x === "object" &&
    typeof (x as CurriculumCompleted).completed === "boolean"
  );
}

export async function postCurrCompleted(currCode: string) {
  const c = await client();
  return c.post(`/currs/${currCode}/completed`);
}

export type Archived = {
  isArchived: boolean;
};

function isArchived(x: unknown): x is Archived {
  return (
    typeof x === "object" && typeof (x as Archived).isArchived === "boolean"
  );
}

export async function getCurrArchived(currCode: string): Promise<Archived> {
  const c = await client();
  const res = await c.get(`/currs/${currCode}/archived`);
  const data = res.data;
  if (isArchived(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function postCurrArchived(currCode: string) {
  const c = await client();
  return c.post(`/currs/${currCode}/archived`);
}

export const NAME_MAX_LEN = 30;
export const CODE_MAX_LEN = 30;
export const CODE_REGEXP = /^[a-z][a-z0-9-]+$/;

export const LEAD_MAX_LEN = 300;
export const SUBTITLE_MAX_LEN = 30;
export const BODY_MAX_LEN = 650;
export const IMG_DESC_MAX_LEN = 40;

export const HEADER_DESCRIPTION_MAX_LEN = 150;

export async function getCurrProducts(currCode: string): Promise<Product[]> {
  const c = await client();
  const { data } = await c.get(`/currs/${currCode}/products`);
  const { products } = data;
  if (isProductArray(products)) {
    return products;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}
