import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostCard from "../../components/PostCard";
import type { Post } from "../../models/post";
import { getPost } from "../../models/post";
import Comment from "./Comment";
import Delete from "./Delete";
import Hidden from "./Hidden";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    "& > *": {
      marginTop: theme.spacing(4),
    },
  },
}));

export default function PostView() {
  const classes = useStyles();

  const { id } = useParams<{
    id?: string;
  }>();

  const [post, setPost] = useState<Post>();
  useEffect(() => {
    if (!id) return;
    getPost(id).then(setPost, console.error);
  }, [id]);

  if (!post) return null;

  return (
    <Container className={classes.root}>
      <section>
        <Typography variant="h2" gutterBottom>
          投稿
        </Typography>
        <Paper>
          <Grid container>
            <Grid item xs={6}>
              <Box p={1}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        UID
                      </TableCell>
                      <TableCell align="right">{post.profile.uid}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        ユーザー名
                      </TableCell>
                      <TableCell align="right">
                        {post.profile.nickname}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        作成日時
                      </TableCell>
                      <TableCell align="right">
                        {format(post.createTime, "yyyy-MM-dd HH:mm:ss", {
                          locale: ja,
                        })}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <PostCard post={post} elevation={0} />
            </Grid>
          </Grid>
        </Paper>
      </section>
      <section>
        <Typography variant="h2" gutterBottom>
          設定
        </Typography>
        <Paper>
          <Box p={2}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                コミュニティ表示
              </Typography>
              <Hidden postID={post.postID} defaultValue={post.hidden} />
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" gutterBottom>
                投稿の削除
              </Typography>
              <Delete postID={post.postID} />
            </Box>
          </Box>
        </Paper>
      </section>
      <section>
        <Typography variant="h2" gutterBottom>
          コメント
        </Typography>
        <Comment postID={post.postID} />
      </section>
    </Container>
  );
}
