import { useState, useEffect } from "react";
import {
  getDescription,
  PlayDescriptionInput,
  postDescription,
} from "../../../models/play";
import Viewer from "./Viewer";
import Editor from "./Editor";

export default function Description({ id }: { id: number }) {
  const [edit, setEdit] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [description, setDescription] = useState<string>();

  const loadDescription = (id: number) =>
    getDescription(id).then(setDescription, console.error);

  useEffect(() => {
    loadDescription(id).finally(() => setLoading(false));
  }, [id]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const [saveError, setSaveError] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleOnSave = (data: PlayDescriptionInput) => {
    const { description } = data;
    setIsSaving(true);
    postDescription(id, description)
      .then(
        () => {
          toggleEdit();
          setDescription(description);
        },
        (e) => setSaveError(`${e}`)
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (loading) return null;

  return edit ? (
    <Editor
      defaultValues={{
        description: description || "",
      }}
      onSave={handleOnSave}
      onCancel={toggleEdit}
      isSaving={isSaving}
      saveError={saveError}
    />
  ) : (
    <Viewer description={description} onClick={toggleEdit} />
  );
}
