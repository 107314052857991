import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Control, Controller } from "react-hook-form";
import {
  numberPattern,
  requiredMessage,
  maxLengthRule,
} from "../../../../utils/form";
import {
  Description as CurrDesc,
  IMG_DESC_MAX_LEN,
} from "../../../../models/curriculum";

const defaultValues = {
  image: {
    cldnImageID: 0,
    cldnPublicID: "",
    cldnVersion: 0,
  },
  description: "",
};

export default function ImageAndDescription({
  name,
  index,
  control,
}: {
  name: "whatToLearn" | "whatToGet";
  index: number;
  control: Control<CurrDesc, object>;
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Controller
          name={`${name}.${index}.image.cldnPublicID`}
          control={control}
          defaultValue={defaultValues.image.cldnPublicID}
          rules={{ required: requiredMessage }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="Cloudinary Public ID"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={`${name}.${index}.image.cldnVersion`}
          control={control}
          defaultValue={defaultValues.image.cldnVersion}
          rules={{ required: requiredMessage, pattern: numberPattern }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="cldnVersion"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${name}.${index}.description`}
          control={control}
          defaultValue={defaultValues.description}
          rules={{
            required: requiredMessage,
            maxLength: maxLengthRule(IMG_DESC_MAX_LEN),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              fullWidth
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label="概要"
              {...field}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
