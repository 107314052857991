import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import type { MusicType } from "../../../models/play";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    name: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    buttons: {
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

export default function Table({
  className,
  musicTypes,
  onEdit,
  onDelete,
}: {
  className?: string;
  musicTypes: MusicType[];
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
}) {
  const classes = useStyles();

  const handleEdit =
    (id: number) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onEdit) {
        onEdit(id);
      }
    };

  const handleDelete =
    (id: number) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onDelete) {
        onDelete(id);
      }
    };

  return (
    <Card className={className}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell width="10%">ID</TableCell>
                <TableCell width="80%">名前</TableCell>
                <TableCell width="5%" />
                <TableCell width="5%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {musicTypes.map(({ musicTypeID, musicType }) => (
                <TableRow hover={true} key={musicTypeID}>
                  <TableCell>
                    <Typography className={classes.name}>
                      {musicTypeID}
                    </Typography>
                  </TableCell>
                  <TableCell>{musicType}</TableCell>
                  <TableCell className={classes.buttons}>
                    <IconButton onClick={handleEdit(musicTypeID)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={handleDelete(musicTypeID)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </div>
      </CardContent>
    </Card>
  );
}
