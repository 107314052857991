import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from "react";
import type { RedeemableUserCoupon } from "../../../models/coupon";
import { getRedeemableUserCoupon } from "../../../models/coupon";
import CopyButton from "./CopyButton";
import Delete from "./Delete";
import Editor from "./Editor";

export default function UserCoupon({ id }: { id: number }) {
  const [redeemable, setRedeemable] = useState<RedeemableUserCoupon>();

  const load = (id: number) => {
    getRedeemableUserCoupon(id).then(setRedeemable).catch(console.error);
  };

  useEffect(() => {
    load(id);
  }, [id]);

  const [url, setUrl] = useState<string>("");
  const handleSave = (url: string) => {
    setUrl(url);
    load(id);
  };

  const handleDelete = () => {
    setRedeemable(undefined);
    setUrl("");
  };

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        ユーザークーポン（招待時に自動適用されるクーポン）
      </Typography>
      {url && (
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Box>
              <Typography component="span">招待用のURL</Typography>
            </Box>
            <Box ml={2}>
              <Typography component="span">{url}</Typography>
            </Box>
            <Box ml={2}>
              <CopyButton text={url} label="URLをコピーする" />
            </Box>
          </Box>
          <Typography component="span">
            招待用のURLはユーザークーポン作成時のみ表示されます。
          </Typography>
        </Box>
      )}
      <div>
        {redeemable ? (
          <Box display="flex" alignItems="center">
            <Box p={2}>
              <Typography component="span">{redeemable.couponType}</Typography>
            </Box>

            <Delete couponID={id} onDelete={handleDelete} />
          </Box>
        ) : (
          <Editor couponID={id} onSave={handleSave} />
        )}
      </div>
    </div>
  );
}
