import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link as RouterLink } from "react-router-dom";
import CourseStatusLabel from "../../../components/CourseStatusLabel";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    content: {
      padding: 0,
    },
    inner: {
      minWidth: 700,
    },
    actions: {
      padding: theme.spacing(1),
      justifyContent: "flex-end",
    },
  })
);

export default function CourseVersionList({
  className,
  courses,
}: {
  className: string;
  courses: import("../../../models/course").AdminCourse[];
}) {
  const classes = useStyles();

  return (
    <div className={className}>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>バージョン</TableCell>
                  <TableCell>コース名</TableCell>
                  <TableCell>公開状態</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {courses
                  .sort((a, b) => b.courseVer - a.courseVer)
                  .map((x) => (
                    <TableRow hover={true} key={x.courseVer}>
                      <TableCell>{x.courseVer}</TableCell>
                      <TableCell>{x.courseName}</TableCell>
                      <TableCell>
                        <CourseStatusLabel published={x.published} />
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={`/courses/${x.courseCode}/versions/${x.courseVer}`}
                          variant="contained"
                        >
                          編集
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
