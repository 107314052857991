import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Progress from "../../components/Progress";
import useMyRole from "../../hooks/useMyRole";
import { getCategory } from "../../models/category";
import permissions from "../../models/permission";
import CategoryHeader from "./CategoryHeader";
import CurriculumList from "./CurriculumList";
import Edit from "./Edit";
import PlanList from "./PlanList";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

export default function Category() {
  const classes = useStyles();
  const { role } = useMyRole();

  const { code, tab = "currs" } = useParams<{
    code: string;
    tab?: string;
  }>();

  const [category, setCategory] =
    useState<import("../../models/category").Category>();
  useEffect(() => {
    getCategory(code).then(setCategory).catch(console.error);
  }, [code]);

  const history = useHistory();
  const handleTabsChange = (e: React.ChangeEvent<{}>, value: any) => {
    if (typeof value === "string") {
      history.push(`/categories/${code}/${value}`);
    }
  };

  const handleChange = (updated: import("../../models/category").Category) => {
    history.replace(`/categories/${updated.categoryCode}`);
  };

  if (!category) return <Progress />;

  const tabs = [
    {
      value: "currs",
      label: "カリキュラム",
      component: <CurriculumList categoryCode={category.categoryCode} />,
    },
    {
      value: "plans",
      label: "プラン",
      component: <PlanList categoryCode={category.categoryCode} />,
    },
  ];

  return (
    <div className={classes.root}>
      <CategoryHeader
        categoryName={category.categoryName}
        editComponent={
          permissions.categoriesPost.has(role) && (
            <Edit category={category} onChange={handleChange} />
          )
        }
      />

      <div>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Divider />
      </div>

      <div>{tabs.find((t) => t.value === tab)?.component}</div>
    </div>
  );
}
