import { client } from "../utils/axios";
import { isCurriculumArray } from "./curriculum";
import { Feature, isFeature } from "./feature";

export type Product = {
  productID: number;
  productName: string;
  productPrice: number;
  stripeProductID: string;
  stripePriceID: string;
};

function isProduct(x: unknown): x is Product {
  return (
    typeof x === "object" &&
    typeof (x as Product).productID === "number" &&
    typeof (x as Product).productName === "string" &&
    typeof (x as Product).productPrice === "number" &&
    typeof (x as Product).stripeProductID === "string" &&
    typeof (x as Product).stripePriceID === "string"
  );
}

export function isProductArray(xs: unknown): xs is Product[] {
  return Array.isArray(xs) && xs.every(isProduct);
}

export async function getProducts(): Promise<Product[]> {
  const c = await client();
  const { data } = await c.get("/products");
  const { products } = data;
  if (isProductArray(products)) {
    return products;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export type ProductInput = {
  name: string;
  price: number;
  stripeProductID: string;
  stripePriceID: string;
};

export const PRODUCT_NAME_MAX_LEN = 64;

export async function postProduct(input: ProductInput): Promise<Product> {
  const c = await client();
  const { data } = await c.post("/products", input);
  if (isProduct(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function getProduct(id: number): Promise<Product> {
  const c = await client();
  const { data } = await c.get(`/products/${id}`);
  if (isProduct(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function patchProduct(
  id: number,
  input: ProductInput
): Promise<Product> {
  const c = await client();
  const { data } = await c.patch(`/products/${id}`, input);
  if (isProduct(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export async function getProductCurrs(id: number) {
  const c = await client();
  const { data } = await c.get(`/products/${id}/currs`);
  const { currs } = data;
  if (isCurriculumArray(currs)) {
    return currs;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export type ProductCurriculumInput = {
  currCodes: string[];
};

export async function postProductCurr(
  id: number,
  input: ProductCurriculumInput
): Promise<void> {
  const c = await client();
  await c.post(`/products/${id}/currs`, input);
}

export async function getProductFeature(id: number): Promise<Feature> {
  const c = await client();
  const { data } = await c.get(`/products/${id}/features`);
  if (isFeature(data)) {
    return data;
  }
  throw Error(`invalid data: ${JSON.stringify(data)}`);
}

export type ProductFeatureInput = {
  featureID: number;
};

export async function postProductFeature(
  id: number,
  input: ProductFeatureInput
): Promise<void> {
  const c = await client();
  await c.post(`/products/${id}/features`, input);
}

export async function deleteProductFeature(id: number): Promise<void> {
  const c = await client();
  await c.delete(`/products/${id}/features`);
}
