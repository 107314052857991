import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import { ProductInput } from "../../../models/product";
import Form from "./Form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 400,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
  })
);

export default function Editor({
  open,
  onSave,
  onClose,
  isSaving,
  saveError,
}: {
  open: boolean;
  onSave: (data: ProductInput) => void;
  onClose: () => void;
  isSaving: boolean;
  saveError?: string;
}) {
  const classes = useStyles();

  return (
    <Modal onClose={onClose} open={open}>
      <Card className={classes.root}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h3">
            商品の追加
          </Typography>
          <Form
            onSave={onSave}
            onCancel={onClose}
            isSaving={isSaving}
            saveError={saveError}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
