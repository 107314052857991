import { client } from "../utils/axios";

export type CloudinaryImage = {
  cldnImageID: number;
  cldnPublicID: string;
  cldnVersion: number;
};

export function isCloudinaryImage(x: any): x is CloudinaryImage {
  return (
    x &&
    typeof x.cldnImageID === "number" &&
    typeof x.cldnPublicID === "string" &&
    typeof x.cldnVersion === "number"
  );
}

export function isCloudinaryImageArray(xs: unknown): xs is CloudinaryImage[] {
  return Array.isArray(xs) && xs.every((x) => isCloudinaryImage(x));
}

export type CloudinaryAudio = {
  cldnAudioID: number;
  cldnPublicID: string;
  cldnVersion: number;
};

export function isCloudinaryAudio(x: any): x is CloudinaryAudio {
  return (
    x &&
    typeof x.cldnAudioID === "number" &&
    typeof x.cldnPublicID === "string" &&
    typeof x.cldnVersion === "number"
  );
}

export const CLOUD_NAME = "phonim";
const maxWidth = 640;

// see https://cloudinary.com/documentation/image_transformations
export function getURL(image: CloudinaryImage) {
  const trans = `w_${maxWidth},f_auto`;
  return `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/${trans}/v${image.cldnVersion}/${image.cldnPublicID}.jpg`;
}

export async function uploadImage(file: File) {
  const c = await client();
  const form = new FormData();
  form.append("file", file);
  const res = await c.post("/media/image", form);
  const { data } = res;
  if (isCloudinaryImage(data)) {
    return data;
  }
  throw Error("invalid data: " + JSON.stringify(data));
}

export function getAudioURL(image: CloudinaryAudio, ext: string) {
  return `https://res.cloudinary.com/${CLOUD_NAME}/video/upload/v${image.cldnVersion}/${image.cldnPublicID}.${ext}`;
}

function videoURL(cldn: CloudinaryAudio, ext: string, transform?: string) {
  return `https://res.cloudinary.com/${CLOUD_NAME}/video/upload${
    transform ? `/${transform}` : ""
  }/v${cldn.cldnVersion}/${cldn.cldnPublicID}.${ext}`;
}

function videoImageURL(cldn: CloudinaryAudio, transform: string) {
  return `https://res.cloudinary.com/${CLOUD_NAME}/video/upload/${transform}/v${cldn.cldnVersion}/${cldn.cldnPublicID}.jpg`;
}

export function videoSources(cldn: CloudinaryAudio) {
  return {
    sources: [
      {
        src: videoURL(cldn, "m3u8", "sp_hd/b_black,c_pad,ar_16:9"),
        type: "application/x-mpegURL",
      },
    ],
    poster: videoImageURL(cldn, "b_black,c_pad,w_854,h_480"),
  };
}
