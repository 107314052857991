import Button from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getURL } from "../../../../models/media";

const useStyles = makeStyles((theme: import("@material-ui/core").Theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  text: {
    border: `0.5px solid ${colors.grey[300]}`,
    borderRadius: 8,
    height: "8rem",
    overflow: "auto",
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  singleLine: {
    height: "3rem",
  },
  multiline: {
    height: "8rem",
  },
  description: {
    height: "2.5rem",
  },
  media: {
    aspectRatio: "16/9",
  },
}));

export default function Viewer({
  desc,
  onClick,
  disableEdit,
}: {
  desc?: import("../../../../models/curriculum").Description;
  onClick: () => void;
  disableEdit?: boolean;
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={onClick}
            disabled={disableEdit}
          >
            編集
          </Button>
        </Grid>
      </Grid>
      <div>
        <Typography>タイトル</Typography>
        <Typography
          className={clsx(classes.text, classes.singleLine)}
          variant="subtitle1"
          component="pre"
        >
          {desc?.title}
        </Typography>
      </div>
      <div>
        <Typography>ヘッダー画像</Typography>
        {desc?.headerImage ? (
          <Grid container>
            <Grid item xs={4}>
              <Card>
                <CardMedia
                  className={classes.media}
                  image={getURL(desc?.headerImage)}
                />
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography>画像がありません</Typography>
        )}
      </div>
      <div>
        <Typography>リード</Typography>
        <Typography
          className={clsx(classes.text, classes.multiline)}
          variant="subtitle1"
          component="pre"
        >
          {desc?.lead}
        </Typography>
      </div>
      <div>
        <Typography>サブタイトル</Typography>
        <Typography
          className={clsx(classes.text, classes.singleLine)}
          variant="subtitle1"
          component="pre"
        >
          {desc?.subtitle}
        </Typography>
      </div>
      <div>
        <Typography>ボディ</Typography>
        <Typography
          className={clsx(classes.text, classes.multiline)}
          variant="subtitle1"
          component="pre"
        >
          {desc?.body}
        </Typography>
      </div>
      <div>
        <Typography>VimeoID</Typography>
        <Typography
          className={clsx(classes.text, classes.singleLine)}
          variant="subtitle1"
          component="pre"
        >
          {desc?.vimeoID}
        </Typography>
      </div>
      <div>
        <Typography>学べること</Typography>
        <Grid container spacing={2}>
          {desc?.whatToLearn && desc.whatToLearn.length > 0 ? (
            desc?.whatToLearn.map((x, i) => (
              <Grid item xs={4} key={i}>
                <Card>
                  <CardMedia
                    className={classes.media}
                    image={getURL(x.image)}
                    title={x.description}
                  />
                  <Typography className={classes.description}>
                    {x.description}
                  </Typography>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>設定されていません</Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <div>
        <Typography>このコースに含まれるもの</Typography>
        <Grid container spacing={2}>
          {desc?.whatToGet && desc.whatToGet.length > 0 ? (
            desc?.whatToGet.map((x, i) => (
              <Grid item xs={4} key={i}>
                <Card>
                  <CardMedia
                    className={classes.media}
                    image={getURL(x.image)}
                    title={x.description}
                  />
                  <Typography className={classes.description}>
                    {x.description}
                  </Typography>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item>
              <Typography>設定されていません</Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}
