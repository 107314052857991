import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import AvatarEditor from "../AvatarEditor";
import { capitalize } from "../../../utils/format";

type Teacher = import("../../../models/teacher").Teacher;

export default function Profile({
  teacher,
  setTeacher,
}: {
  teacher: Teacher;
  setTeacher: (x: Teacher) => void;
}) {
  const setImage = (
    avatar: import("../../../models/media").CloudinaryImage
  ) => {
    setTeacher({ ...teacher, avatar });
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>講師ID</TableCell>
                  <TableCell>{teacher.teacherID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>氏名</TableCell>
                  <TableCell>{`${teacher.familyName} ${teacher.firstName}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>{`${capitalize(teacher.firstNameEn)} ${capitalize(
                    teacher.familyNameEn
                  )}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AvatarEditor teacher={teacher} onChange={setImage} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
