import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function Header({
  title,
  className,
}: {
  title: string;
  className?: string;
}) {
  return (
    <Grid
      className={className}
      alignItems="flex-end"
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography component="h1" variant="h3">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
