import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    space: {
      flexGrow: 1,
    },
  })
);

export default function Header({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            演奏形態
          </Typography>
        </Grid>
        <Grid item className={classes.space} />
        <Grid item>
          <Button color="primary" variant="contained" onClick={onClick}>
            演奏形態を追加
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
