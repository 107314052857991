import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import type { ReactNode } from "react";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    space: {
      flexGrow: 1,
    },
  })
);

export default function CategoryHeader({
  categoryName,
  editComponent,
  className,
}: {
  categoryName: string;
  editComponent: ReactNode;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Typography component="h2" gutterBottom variant="overline">
            カテゴリ
          </Typography>
          <Typography component="h1" variant="h3">
            {categoryName}
          </Typography>
        </Grid>
        <Grid item>{editComponent}</Grid>
        <Grid item className={classes.space} />
      </Grid>
    </div>
  );
}
