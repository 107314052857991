import { useEffect, useRef } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import ja from "video.js/dist/lang/ja.json";
import "video.js/dist/video-js.min.css";

const languages = {
  ja: {
    ...ja,
    Replay: "リプレイ",
    Unloop: "ループ再生解除",
    Loop: "ループ再生",
    "Play Video": "再生",
    Fullscreen: "全画面表示",
    "Non-Fullscreen": "全画面表示を終了",
  },
};

export default function VideoPlayer({
  className,
  options,
}: {
  className?: string;
  options: VideoJsPlayerOptions;
}) {
  const ref = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    if (playerRef.current) return;

    const player = (playerRef.current = videojs(ref.current, {
      preload: "auto",
      controls: true,
      fluid: true,
      controlBar: {
        pictureInPictureToggle: false,
      },
      userActions: {
        hotkeys: true,
      },
      languages,
      ...options,
    }));

    // Disable the context menu
    player.on("contextmenu", (e) => {
      e.preventDefault();
    });
  }, [ref, options]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className={className} data-vjs-player>
      <video ref={ref} className="video-js vjs-big-play-centered" />
    </div>
  );
}
