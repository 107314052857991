import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import { Feature, toJaName } from "../../../../models/feature";
import type { ProductFeatureInput } from "../../../../models/product";
import { requiredMessage } from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  buttons: {
    textAlign: "right",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export type FromProps = {
  features: Feature[];
  onSave: (feature: ProductFeatureInput) => void;
  onCancel: () => void;
  isSaving: boolean;
  saveError?: string;
};

const defaultValues = {
  featureID: 0,
};

function formatInput(input: ProductFeatureInput): ProductFeatureInput {
  const toInt = (value: number): number => parseInt(`${value}`, 10);
  return {
    ...input,
    featureID: toInt(input.featureID),
  };
}

export default function Form({
  features,
  onSave,
  onCancel,
  isSaving,
  saveError,
}: FromProps) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<ProductFeatureInput>({
    defaultValues,
  });

  const handleSave = (data: ProductFeatureInput) => {
    onSave(formatInput(data));
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} className={classes.content}>
      {saveError && <Typography color="error">{saveError}</Typography>}

      <Controller
        name="featureID"
        control={control}
        rules={{
          required: requiredMessage,
          min: 1,
        }}
        render={({ field, fieldState: { error } }) => (
          <FormControl variant="outlined" fullWidth error={!!error}>
            <InputLabel id="featureID-label">機能</InputLabel>
            <Select
              labelId="featureID-label"
              id="featureID"
              label="機能"
              {...field}
            >
              <MenuItem value={0}>
                <em>機能を選択してください</em>
              </MenuItem>
              {features.map((feature) => (
                <MenuItem key={feature.featureID} value={feature.featureID}>
                  {toJaName(feature)}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText color="error">{error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <div className={classes.buttons}>
        <Button
          size="small"
          variant="contained"
          disabled={isSaving}
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSaving}
        >
          保存
        </Button>
      </div>
    </form>
  );
}
