import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import * as colors from "@material-ui/core/colors";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createCategoryCurriculum } from "../../../models/category";
import {
  CODE_MAX_LEN,
  CODE_REGEXP,
  Curriculum,
  NAME_MAX_LEN,
} from "../../../models/curriculum";
import { maxLengthRule, requiredMessage } from "../../../utils/form";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      outline: "none",
      boxShadow: theme.shadows[20],
      width: 400,
      maxHeight: "100%",
      overflowY: "auto",
      maxWidth: "100%",
    },
    container: {
      marginTop: theme.spacing(3),
    },
    actions: {
      justifyContent: "flex-end",
    },
    saveButton: {
      color: "white",
      backgroundColor: colors.green[600],
      "&:hover": {
        backgroundColor: colors.green[900],
      },
    },
  })
);

const defaultValues = {
  currCode: "",
  currName: "",
};

export default function Editor({
  open,
  onClose,
  categoryCode,
}: {
  categoryCode: string;
  open: boolean;
  onClose: (curriculum?: Curriculum) => void;
}) {
  const classes = useStyles();

  const [uploadError, setUploadError] = useState("");
  const [uploading, setUploading] = useState(false);
  const { handleSubmit, control, reset } = useForm<Curriculum>({
    defaultValues,
  });

  const onSubmit = (data: Curriculum) => {
    const { currCode, currName } = data;
    setUploading(true);
    createCategoryCurriculum(categoryCode, currCode, currName)
      .then(
        () => {
          onClose({ currCode, currName });
          reset();
        },
        (e: any) => {
          switch (e?.response?.status) {
            case 400:
              setUploadError("カリキュラムコードが既に存在します。");
              break;
            default:
              setUploadError("エラーが発生し、保存に失敗しました。");
              console.error(e);
              break;
          }
        }
      )
      .finally(() => {
        setUploading(false);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Typography align="center" gutterBottom variant="h3">
              カリキュラム作成
            </Typography>
            {uploadError && (
              <div className={classes.container}>
                <Typography align="center" color="error">
                  {uploadError}
                </Typography>
              </div>
            )}
            <div className={classes.container}>
              <Controller
                name="currName"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(NAME_MAX_LEN),
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="カリキュラム名"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
            <div className={classes.container}>
              <Controller
                name="currCode"
                control={control}
                rules={{
                  required: requiredMessage,
                  maxLength: maxLengthRule(CODE_MAX_LEN),
                  pattern: {
                    value: CODE_REGEXP,
                    message: "英小文字, 数字, ハイフンのみ使えます",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="カリキュラムコード"
                    error={!!error}
                    helperText={error?.message}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained">
              閉じる
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              type="submit"
              disabled={uploading}
            >
              保存
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
