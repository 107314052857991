import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useMyRole from "../../hooks/useMyRole";
import {
  Category,
  CategoryInput,
  createCategory,
  getCategories,
} from "../../models/category";
import permissions from "../../models/permission";
import Form from "./Form";
import Header from "./Header";
import Table from "./Table";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      marginTop: theme.spacing(3),
    },
  })
);

export default function Posts() {
  const classes = useStyles();
  const { role } = useMyRole();

  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const histroy = useHistory();
  const [error, setError] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleSubmit = (input: CategoryInput) => {
    setIsSaving(true);
    setError("");
    createCategory(input)
      .then(() => {
        histroy.push(`/categories/${input.code}/plans`);
      })
      .catch((e) => {
        setIsSaving(false);
        switch (e.response?.status) {
          case 400:
            setError(
              "すでに同じ名前のカテゴリコードが存在します。別のコードを入力してください。"
            );
            return;
          default:
            setError("エラーが発生し、カテゴリの作成に失敗しました。");
            return;
        }
      });
  };

  return (
    <div className={classes.root}>
      <Header
        onClick={handleOpen}
        showAddButton={permissions.categoriesPost.has(role)}
      />
      <Table className={classes.results} categories={categories} />
      <Form
        open={open}
        onSave={handleSubmit}
        onClose={handleClose}
        isSaving={isSaving}
        saveError={error}
      />
    </div>
  );
}
