import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Modal from "@material-ui/core/Modal";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import type { FormProps } from "./Form";
import From from "./Form";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
  },
}));

type EditorProps = FormProps & {
  open: boolean;
  onCancel: () => void;
};

export default function Editor({
  open,
  onCancel,
  curriculums,
  onSave,
  isSaving,
  saveError,
}: EditorProps) {
  const classes = useStyles();
  return (
    <Modal onClose={onCancel} open={open}>
      <Card className={classes.root}>
        <CardHeader title="カリキュラムの追加" />
        <CardContent>
          <From
            curriculums={curriculums}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={isSaving}
            saveError={saveError}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
