import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function DeleteDialog({
  open,
  onClose,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="error">投稿削除の確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          投稿を削除してもよろしいですか？ この操作は取り消せません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={onDelete} color="default">
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
