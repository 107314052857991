import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  from: {
    display: "flex",
    alignItems: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    flex: "none",
  },
}));

export type FormValues = {
  comment: string;
};

export function Form({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: Partial<FormValues>;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = useForm<FormValues>({ defaultValues });
  const [submittedData, setSubmittedData] = useState<FormValues>();

  const submit = (data: FormValues) => {
    setSubmittedData(data);
    return onSubmit(data);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ comment: "" });
    }
  }, [submittedData, isSubmitSuccessful, reset]);

  return (
    <form className={classes.from} onSubmit={handleSubmit(submit)}>
      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextField
            variant="outlined"
            label="コメント"
            multiline
            fullWidth
            maxRows={5}
            {...field}
          />
        )}
      />
      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
        disabled={!isDirty}
      >
        送信
      </Button>
    </form>
  );
}
