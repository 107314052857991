import { client } from "../utils/axios";
import { Curriculum, isCurriculumArray } from "./curriculum";
import type { Plan } from "./plan";
import { isPlanArray } from "./plan";

export type Category = {
  categoryCode: string;
  categoryName: string;
};

export function isCategory(x: any): x is Category {
  return (
    x &&
    typeof x.categoryCode === "string" &&
    typeof x.categoryName === "string"
  );
}

function isCategoryArray(xs: any): xs is Category[] {
  return xs && Array.isArray(xs) && xs.every(isCategory);
}

export type CategoryLesson = {
  categoryCode: string;
  lessonID: number;
};

function isCategoryLesson(x: any): x is CategoryLesson {
  return typeof x?.categoryCode === "string" && typeof x?.lessonID === "number";
}

export async function getCategories() {
  const c = await client();
  const { data } = await c.get("/categories");
  const { categories } = data;
  if (isCategoryArray(categories)) {
    return categories;
  }
  throw Error("invalid response: " + JSON.stringify(data));
}

export async function getCategory(code: string) {
  const c = await client();
  const { data } = await c.get(`/categories/${code}`);
  if (isCategory(data)) {
    return data;
  }
  throw Error("invalid response: " + JSON.stringify(data));
}

export type CategoryInput = {
  code: string;
  name: string;
};

export const CATEGORY_CODE_MAX_LEN = 64;
export const CATEGORY_NAME_MAX_LEN = 64;

export async function createCategory(input: CategoryInput): Promise<void> {
  const c = await client();
  await c.post("/categories", input);
}

export type CategoryPatchInput = {
  code?: string;
  name?: string;
};

export async function patchCategory(
  code: string,
  input: CategoryPatchInput
): Promise<void> {
  const c = await client();
  await c.patch(`/categories/${code}`, input);
}

export async function getCategoryCurriculums(
  code: string
): Promise<Curriculum[]> {
  const c = await client();
  const { data } = await c.get(`/categories/${code}/currs`);
  const { currs } = data;
  if (isCurriculumArray(currs)) {
    return currs;
  }
  throw Error("invalid response: " + JSON.stringify(data));
}

export async function createCategoryCurriculum(
  categoryCode: string,
  currCode: string,
  currName: string
) {
  const c = await client();
  return c.post(`/categories/${categoryCode}/currs`, { currCode, currName });
}

export async function getCategoryPlans(categoryCode: string): Promise<Plan[]> {
  const c = await client();
  const { data } = await c.get(`/categories/${categoryCode}/plans`);
  const { plans } = data;
  if (isPlanArray(plans)) {
    return plans;
  }
  throw Error(`invalid response: ${JSON.stringify(data)}`);
}

export async function postCategoryPlans(
  categoryCode: string,
  plans: number[]
): Promise<void> {
  const c = await client();
  await c.post(`/categories/${categoryCode}/plans`, { plans });
}

export async function deleteCategoryPlan(
  categoryCode: string,
  planID: number
): Promise<void> {
  const c = await client();
  await c.delete(`/categories/${categoryCode}/plans/${planID}`);
}

export async function getCategoryLessons() {
  const c = await client();
  const { data } = await c.get("/categoryLessons");
  const { categoryLessons } = data;
  if (
    Array.isArray(categoryLessons) &&
    categoryLessons.every(isCategoryLesson)
  ) {
    return categoryLessons as CategoryLesson[];
  }
  throw Error("failed to GET /categoryLessons");
}
