import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  space: {
    flexGrow: 1,
  },
}));

export default function Header({
  showAddButton,
  onClick,
  className,
}: {
  showAddButton?: boolean;
  onClick: () => void;
  className?: string;
}) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography component="h1" variant="h3">
            カテゴリ
          </Typography>
        </Grid>
        <Grid item className={classes.space} />
        {showAddButton && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={onClick}>
              追加する
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
