import { parseISO } from "date-fns";
import { client } from "../utils/axios";
import { isCloudinaryAudio } from "./media";
import { formatPost, Post } from "./post";
import { isProfile } from "./profile";
import { isTeacher } from "./teacher";

export type Submission = {
  uid: string;
  note: string;
  createTime: Date;
  audio: import("./media").CloudinaryAudio;
  profile: import("./profile").Profile | null;
  lesson: Lesson;
  comments: TeacherComment[];
  disabledComment: boolean;
  hidden: boolean;
  contentType: string;
};

function isSubmission(x: any): x is Submission {
  return (
    x &&
    typeof x.uid === "string" &&
    typeof x.note === "string" &&
    x.createTime instanceof Date &&
    isCloudinaryAudio(x.audio) &&
    (x.profile === null || isProfile(x.profile)) &&
    isLesson(x.lesson) &&
    Array.isArray(x.comments) &&
    x.comments.every(isTeacherComment) &&
    typeof x.disabledComment === "boolean" &&
    typeof x.hidden === "boolean" &&
    typeof x.contentType === "string"
  );
}

type Lesson = {
  lessonID: number;
  lessonName: string;
};

function isLesson(x: any): x is Lesson {
  return (
    x && typeof x.lessonID === "number" && typeof x.lessonName === "string"
  );
}

export type TeacherComment = {
  teacherCommentID: number;
  comment: string;
  createTime: Date;
  teacher: import("./teacher").Teacher;
};

function isTeacherComment(x: any): x is TeacherComment {
  return (
    x &&
    typeof x.teacherCommentID === "number" &&
    typeof x.comment === "string" &&
    x.createTime instanceof Date &&
    isTeacher(x.teacher)
  );
}

const CATEGORY_CODE = "categoryCode";
const NO_COMMENT_ONLY = "noCommentOnly";
const COURSE_CODE = "courseCode";
const COURSE_VER = "courseVer";
const LESSON_ID = "lessonID";
const UID = "uid";
const SORT_ID = "sortID";
const DISABLED_COMMENT = "disabledComment";

export type QueryParams = {
  [CATEGORY_CODE]?: string;
  [NO_COMMENT_ONLY]?: boolean;
  [COURSE_CODE]?: string;
  [COURSE_VER]?: number;
  [LESSON_ID]?: number;
  [UID]?: string;
  [SORT_ID]?: string;
  [DISABLED_COMMENT]?: string;
};

export function parseQueryParams(search: string) {
  const params = new URLSearchParams(search);
  const result: QueryParams = {};
  if (params.get(NO_COMMENT_ONLY)) {
    result[NO_COMMENT_ONLY] = true;
  }

  const code = params.get(COURSE_CODE);
  if (code) {
    result[COURSE_CODE] = code;
  }

  const courseVer = params.get(COURSE_VER);
  if (courseVer) {
    const ver = parseInt(courseVer, 10);
    if (!isNaN(ver)) {
      result[COURSE_VER] = ver;
    }
  }

  const lid = params.get(LESSON_ID);
  if (lid) {
    const id = parseInt(lid, 10);
    if (!isNaN(id)) {
      result[LESSON_ID] = id;
    }
  }

  const sortID = params.get(SORT_ID);
  if (sortID) {
    result[SORT_ID] = sortID;
  }

  const categoryCode = params.get(CATEGORY_CODE);
  if (categoryCode) {
    result[CATEGORY_CODE] = categoryCode;
  }

  const uid = params.get(UID);
  if (uid) {
    result[UID] = uid;
  }

  const disabledComment = params.get(DISABLED_COMMENT);
  if (disabledComment) {
    result[DISABLED_COMMENT] = disabledComment;
  }

  return result;
}

export function buildQueryParams(params: QueryParams) {
  const p = new URLSearchParams();
  const {
    categoryCode,
    courseCode,
    courseVer,
    lessonID,
    noCommentOnly,
    uid,
    sortID,
    disabledComment,
  } = params;
  categoryCode && p.set(CATEGORY_CODE, categoryCode);
  courseCode && p.set(COURSE_CODE, courseCode);
  courseVer !== undefined && p.set(COURSE_VER, courseVer.toString());
  lessonID !== undefined && p.set(LESSON_ID, lessonID.toString());
  noCommentOnly && p.set(NO_COMMENT_ONLY, "1");
  uid && p.set(UID, uid);
  sortID && p.set(SORT_ID, sortID);
  disabledComment && p.set(DISABLED_COMMENT, disabledComment);
  return p;
}

export function submissionKey(x: Submission) {
  return `${x.uid}_${x.lesson.lessonID}`;
}

function transformSubmissions(xs: any) {
  if (xs === null) {
    return [];
  }
  if (!Array.isArray(xs)) {
    return null;
  }
  const ret: Submission[] = [];
  for (let i = 0; i < xs.length; i++) {
    const x = transformSubmission(xs[i]);
    if (!x) {
      return null;
    }
    ret.push(x);
  }
  return ret;
}

function transformSubmission(x: any) {
  if (!x || typeof x.createTime !== "string") {
    return null;
  }
  const ret = { ...x };
  ret.createTime = parseISO(ret.createTime);
  const comments = transformTeacherCommentArray(ret.comments);
  if (!comments) {
    return null;
  }
  ret.comments = comments;
  if (!isSubmission(ret)) {
    return null;
  }
  return ret;
}

function transformTeacherComment(x: any) {
  if (!x || typeof x.createTime !== "string") {
    return null;
  }
  const ret = { ...x };
  ret.createTime = parseISO(ret.createTime);
  if (!isTeacherComment(ret)) {
    return null;
  }
  return ret;
}

function transformTeacherCommentArray(xs: any) {
  const ret: TeacherComment[] = [];
  if (xs === null) {
    return ret;
  }
  if (!Array.isArray(xs)) {
    return null;
  }
  for (let i = 0; i < xs.length; i++) {
    const x = transformTeacherComment(xs[i]);
    if (!x) {
      return null;
    }
    ret.push(x);
  }
  return ret;
}

export type Cursor = {
  prev?: string;
  next?: string;
};

function isCursor(x: any): x is Cursor {
  return (
    x &&
    (x.prev === undefined || typeof x.prev === "string") &&
    (x.next === undefined || typeof x.next === "string")
  );
}

//
// API calls
//
export async function getSubmissions(params: QueryParams) {
  const c = await client();
  const { data } = await c.get(
    `/submissions?${buildQueryParams(params).toString()}`
  );
  const { submissions, cursor } = data;
  const subs = transformSubmissions(submissions);
  if (!subs) {
    throw Error("failed to get submissions");
  }
  const ret: { submissions: Submission[]; cursor?: Cursor } = {
    submissions: subs,
  };
  if (isCursor(cursor)) {
    ret.cursor = cursor;
  }
  return ret;
}

export const COMMENT_MAX_LEN = 1024;

export function checkComment(comment: string) {
  if (!comment) {
    return "入力してください";
  }
  if (Array.from(comment).length > COMMENT_MAX_LEN) {
    return `文字数は${COMMENT_MAX_LEN}文字までです`;
  }
  return "";
}

export async function createComment(
  uid: string,
  lessonID: number,
  teacherID: number,
  comment: string
) {
  const c = await client();
  await c.post(`/comments`, { uid, lessonID, teacherID, comment });
}

export async function updateComment(
  teacherCommentID: number,
  teacherID: number,
  comment: string
) {
  const c = await client();
  await c.post(`/comments/${teacherCommentID}`, { teacherID, comment });
}

export async function disableComment(uid: string, lessonID: number) {
  const c = await client();
  await c.post("/disabled_comments", { uid, lessonID });
}

export async function enableComment(uid: string, lessonID: number) {
  const c = await client();
  await c.delete("/disabled_comments", { data: { uid, lessonID } });
}

export async function resetSubmission(
  uid: string,
  lessonID: number
): Promise<void> {
  const c = await client();
  await c.post("/submissions/reset", { uid, lessonID });
}

export async function deleteSubmission(
  uid: string,
  lessonID: number
): Promise<void> {
  const c = await client();
  await c.delete("/submissions", { data: { uid, lessonID } });
}

export async function putHidden(uid: string, lessonID: number) {
  const c = await client();
  await c.put("/submissions/hidden", { uid, lessonID });
}

export async function deleteHidden(uid: string, lessonID: number) {
  const c = await client();
  await c.delete("/submissions/hidden", { data: { uid, lessonID } });
}

export async function getPost(uid: string, lessonID: number): Promise<Post> {
  const c = await client();
  const { data } = await c.get("/submissions/posts", {
    params: { uid, lessonID },
  });
  const post = formatPost(data);
  if (post) {
    return post;
  }
  throw Error("failed to get post");
}
