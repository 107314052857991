import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import * as colors from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import type { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Controller, useForm } from "react-hook-form";
import type { Plan } from "../../../../models/plan";
import { requiredMessage } from "../../../../utils/form";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  chip: {
    margin: 2,
  },
  actions: {
    justifyContent: "flex-end",
  },
  saveButton: {
    color: "white",
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type FormInput = {
  plans: string[];
};

const defaultValues: FormInput = {
  plans: [],
};

export default function Form({
  plans,
  onSubmit,
  onClose,
  uploading,
  uploadError,
}: {
  plans: Plan[];
  onSubmit: (plans: number[]) => void;
  onClose: () => void;
  uploading?: boolean;
  uploadError?: string;
}) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm<FormInput>({
    defaultValues,
  });

  const formatSubmit = (input: FormInput) => {
    onSubmit(input.plans.map((code) => parseInt(code, 10)));
  };

  return (
    <form onSubmit={handleSubmit(formatSubmit)}>
      <CardContent>
        <Typography align="center" gutterBottom variant="h3">
          プランの追加
        </Typography>
        {uploadError && (
          <div className={classes.container}>
            <Typography color="error">{uploadError}</Typography>
          </div>
        )}
        <div className={classes.container}>
          {plans.length > 0 ? (
            <Controller
              name="plans"
              control={control}
              rules={{
                required: requiredMessage,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth>
                  <InputLabel id="plan-label">プラン</InputLabel>
                  <Select
                    labelId="plan-label"
                    multiple
                    fullWidth
                    input={<Input id="plan" />}
                    renderValue={(selected) => (
                      <div>
                        {(selected as string[]).map((value) => (
                          <Chip
                            className={classes.chip}
                            key={value}
                            label={
                              plans.find((p) => p.planID.toString() === value)
                                ?.planName
                            }
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    error={!!error}
                    {...field}
                  >
                    {plans.map((plan) => (
                      <MenuItem
                        key={plan.planID}
                        value={plan.planID.toString()}
                      >
                        {plan.planName}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && (
                    <FormHelperText error>{requiredMessage}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          ) : (
            <Typography color="textSecondary">
              追加可能なプランがありません
            </Typography>
          )}
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={onClose} variant="contained" disabled={uploading}>
          閉じる
        </Button>
        <Button
          className={classes.saveButton}
          variant="contained"
          type="submit"
          disabled={uploading || plans.length === 0}
        >
          追加
        </Button>
      </CardActions>
    </form>
  );
}
