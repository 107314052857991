import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import AccountEmail from "./AccountEmail";
import CourseAssignment from "./CourseAssignment";
import Profile from "./Profile";
import { useParams } from "react-router-dom";
import { getTeacher } from "../../models/teacher";
import VideoLesson from "./VideoLesson";

const useStyles = makeStyles(
  (theme: import("@material-ui/core/styles").Theme) => ({
    root: {
      width: theme.breakpoints.values.lg,
      maxWidth: "100%",
      margin: "0 auto",
      padding: theme.spacing(3),
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

export default function Teacher() {
  const classes = useStyles();

  const { id } = useParams<{
    id?: string;
  }>();
  const [teacher, setTeacher] =
    useState<import("../../models/teacher").Teacher>();
  useEffect(() => {
    if (!id) {
      return;
    }
    const teacherID = parseInt(id, 10);
    getTeacher(teacherID).then(setTeacher, console.error);
  }, [id]);

  if (!teacher) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Profile teacher={teacher} setTeacher={setTeacher} />
      <AccountEmail teacherID={teacher.teacherID} />
      <CourseAssignment teacherID={teacher.teacherID} />
      <VideoLesson teacherID={teacher.teacherID} />
    </div>
  );
}
