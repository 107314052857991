import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { useState } from "react";

export default function CopyButton({
  text,
  label,
}: {
  text: string;
  label: string;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setOpen(true);
  };

  return (
    <>
      <Button onClick={handleCopy}>{label}</Button>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <SnackbarContent message="クリップボードにコピーしました" />
      </Snackbar>
    </>
  );
}
