import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Category,
  getCategories,
  getCategoryCurriculums,
} from "../../../models/category";
import {
  getCourses,
  getCourseLessons,
  getCourseKey,
  parseCourseKey,
  getCurrCourses,
} from "../../../models/course";

type QueryParams = import("../../../models/homework").QueryParams;
type Course = import("../../../models/course").Course;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    width: 420,
    maxWidth: "100%",
  },
  header: {
    padding: theme.spacing(2, 1),
    display: "flex",
    justifyContent: "space-between",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1,
  },
  contentSection: {
    padding: theme.spacing(2, 0),
  },
  formGroup: {
    padding: theme.spacing(2, 0),
  },
  fieldGroup: {
    display: "flex",
    alignItems: "center",
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
  },
  actions: {
    padding: theme.spacing(3),
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Filter({
  open,
  onClose,
  onFilter,
  params,
  className,
}: {
  open: boolean;
  onClose: () => void;
  onFilter: (params: QueryParams) => void;
  params: QueryParams;
  className?: string;
}) {
  const classes = useStyles();

  const [categories, setCategories] = useState<Category[]>([]);
  useEffect(() => {
    getCategories().then(setCategories, console.error);
  }, []);
  const [category, setCategory] = useState<Category>();
  useEffect(() => {
    setCategory(categories.find((x) => x.categoryCode === params.categoryCode));
  }, [categories, params]);
  const handleChangeCategory = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;
    if (typeof value === "string") {
      setCategory(categories.find((c) => c.categoryCode === value));
    }
  };

  const [courses, setCourses] = useState<Course[]>([]);
  useEffect(() => {
    getCourses().then(
      (xs) => setCourses(xs.sort((a, b) => a.courseID - b.courseID)),
      console.error
    );
  }, []);

  const [categoryCourses, setCategoryCourses] = useState<string[]>([]);
  useEffect(() => {
    if (category) {
      getCategoryCurriculums(category.categoryCode).then((currs) => {
        currs.forEach((curr) => {
          getCurrCourses(curr.currCode).then((courses) => {
            setCategoryCourses((x) => [...x, ...courses]);
          }, console.error);
        });
      }, console.error);
    }

    // カテゴリ変更時にコースとレッスンの値をクリアする
    setCategoryCourses([]);
    setCourse(undefined);
    setLessons([]);
    setLessonID(undefined);
  }, [category]);

  const [course, setCourse] = useState<Course>();
  useEffect(() => {
    setCourse(
      courses.find(
        (x) =>
          x.courseCode === params.courseCode && x.courseVer === params.courseVer
      )
    );
  }, [courses, params]);
  const handleChangeCourse = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value } = e.target;
    if (typeof value === "string") {
      const key = parseCourseKey(value);
      if (key) {
        setCourse(
          courses.find(
            (x) =>
              x.courseCode === key.courseCode && x.courseVer === key.courseVer
          )
        );
      } else {
        setCourse(undefined);
      }
    }
  };

  const [lessons, setLessons] = useState<
    import("../../../models/lesson").LessonSummary[]
  >([]);
  useEffect(() => {
    if (course) {
      getCourseLessons(course.courseCode, course.courseVer).then((xs) => {
        setLessons(xs.filter((x) => x.homework));
      }, console.error);
    }
    // コース変更時にレッスンの値をクリアする
    setLessons([]);
    setLessonID(undefined);
  }, [course]);

  const [lessonID, setLessonID] = useState<number>();
  useEffect(() => {
    setLessonID(params.lessonID);
  }, [params.lessonID]);
  const handleChangeLessonID = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value } = e.target;
    if (typeof value === "string") {
      const id = parseInt(value, 10);
      setLessonID(isNaN(id) ? undefined : id);
    }
  };

  const [noCommentOnly, setNoCommentOnly] = useState(false);
  useEffect(() => {
    setNoCommentOnly(!!params.noCommentOnly);
  }, [params.noCommentOnly]);
  const handleChangeNoCommentOnly = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === "true") {
      setNoCommentOnly(true);
    } else {
      setNoCommentOnly(false);
    }
  };

  const [uid, setUID] = useState<string>("");
  useEffect(() => {
    if (params.uid) {
      setUID(params.uid);
    }
  }, [params]);
  const handleChangeUID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUID(e.target.value);
  };

  const [disabledComment, setDisabledComment] = useState<string>("");
  useEffect(() => {
    if (params.disabledComment !== undefined) {
      setDisabledComment(params.disabledComment);
    }
  }, [params]);
  const handleChangeDisabledComment = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDisabledComment(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFilter({
      categoryCode: category?.categoryCode,
      courseCode: course?.courseCode,
      courseVer: course?.courseVer,
      lessonID,
      noCommentOnly,
      uid,
      disabledComment,
    });
  };

  const filterdCourses =
    category && categoryCourses.length > 0
      ? courses.filter((c) => categoryCourses.includes(c.courseCode))
      : courses;

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Button onClick={onClose} size="small">
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="カテゴリ"
                margin="dense"
                onChange={handleChangeCategory}
                select
                SelectProps={{ native: true }}
                value={category?.categoryCode}
                variant="outlined"
              >
                <option value="" />
                {categories.map((x) => (
                  <option key={x.categoryCode} value={x.categoryCode}>
                    {x.categoryName}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="コース"
                margin="dense"
                onChange={handleChangeCourse}
                select
                SelectProps={{ native: true }}
                value={course ? getCourseKey(course) : ""}
                variant="outlined"
              >
                <option value="" />
                {filterdCourses.map((x) => (
                  <option key={getCourseKey(x)} value={getCourseKey(x)}>
                    {`${x.courseName} (ver ${x.courseVer})`}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="レッスン"
                margin="dense"
                onChange={handleChangeLessonID}
                select
                SelectProps={{ native: true }}
                value={lessonID}
                variant="outlined"
              >
                <option value="" />
                {lessons.map((x) => (
                  <option key={x.lessonID} value={x.lessonID}>
                    {`第${x.lessonOrder !== undefined && x.lessonOrder + 1}回 ${
                      x.lessonName
                    }`}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <Typography component="p" gutterBottom variant="overline">
                コメント状況
              </Typography>
              <div className={classes.fieldGroup}>
                <RadioGroup
                  onChange={handleChangeNoCommentOnly}
                  value={noCommentOnly.toString()}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="全て"
                    value="false"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="コメント未完了のみ"
                    value="true"
                  />
                </RadioGroup>
              </div>
            </div>
            <div className={classes.formGroup}>
              <Typography component="p" gutterBottom variant="overline">
                講師によるコメント
              </Typography>
              <div className={classes.fieldGroup}>
                <RadioGroup
                  onChange={handleChangeDisabledComment}
                  value={disabledComment}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="全て"
                    value=""
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="コメント必要のみ"
                    value="0"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="コメント不要のみ"
                    value="1"
                  />
                </RadioGroup>
              </div>
            </div>
            <div className={classes.formGroup}>
              <Typography component="p" gutterBottom variant="overline">
                UID
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={uid}
                onChange={handleChangeUID}
              />
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="primary" fullWidth type="submit" variant="contained">
            絞り込む
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
